import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Factory,
  LocalDrink,
  Science,
  Agriculture,
  Business,
  Construction,
  LocalHospital,
  Restaurant,
  EmojiObjects,
  CheckCircle,
} from '@mui/icons-material';

const industries = [
  {
    title: "Municipal Water Treatment",
    icon: <LocalDrink sx={{ fontSize: 40 }} />,
    color: "#2196F3",
    description: "Advanced solutions for municipal water and wastewater treatment facilities",
    applications: [
      "Drinking water treatment",
      "Wastewater treatment",
      "Stormwater management",
      "Sludge handling"
    ],
    technologies: [
      "SCADA integration",
      "Smart metering",
      "Process optimization",
      "Quality monitoring"
    ],
    metrics: {
      capacity: "100,000+ m³/day",
      efficiency: "Up to 99.9%",
      compliance: "EPA standards",
      automation: "Full SCADA"
    },
    benefits: [
      "Reduced operational costs",
      "Improved water quality",
      "Enhanced system reliability",
      "Real-time monitoring"
    ]
  },
  {
    title: "Industrial Manufacturing",
    icon: <Factory sx={{ fontSize: 40 }} />,
    color: "#FF5722",
    description: "Specialized water treatment solutions for manufacturing processes",
    applications: [
      "Process water treatment",
      "Cooling tower management",
      "Boiler feed water",
      "Wastewater recycling"
    ],
    technologies: [
      "Reverse osmosis",
      "Ion exchange",
      "Membrane filtration",
      "Chemical treatment"
    ],
    metrics: {
      recovery: "Up to 95%",
      quality: "Ultra-pure",
      savings: "30-50%",
      uptime: "99.9%"
    },
    benefits: [
      "Increased production efficiency",
      "Reduced water consumption",
      "Environmental compliance",
      "Cost savings"
    ]
  },
  {
    title: "Pharmaceutical",
    icon: <Science sx={{ fontSize: 40 }} />,
    color: "#9C27B0",
    description: "Ultra-pure water systems for pharmaceutical manufacturing",
    applications: [
      "WFI production",
      "Pure steam generation",
      "CIP systems",
      "Waste handling"
    ],
    technologies: [
      "Multiple-effect distillation",
      "Ultra-filtration",
      "EDI systems",
      "Sanitization"
    ],
    metrics: {
      purity: "USP standards",
      conductivity: "<1.3 µS/cm",
      bacteria: "<10 CFU/100ml",
      validation: "21 CFR Part 11"
    },
    benefits: [
      "GMP compliance",
      "Process reliability",
      "Quality assurance",
      "Documentation support"
    ]
  },
  {
    title: "Agriculture & Irrigation",
    icon: <Agriculture sx={{ fontSize: 40 }} />,
    color: "#4CAF50",
    description: "Smart irrigation and water management solutions for agriculture",
    applications: [
      "Precision irrigation",
      "Fertigation systems",
      "Water recycling",
      "Runoff management"
    ],
    technologies: [
      "Smart sensors",
      "Weather integration",
      "Automated control",
      "Mobile monitoring"
    ],
    metrics: {
      waterSaving: "Up to 40%",
      coverage: "1000+ hectares",
      efficiency: "95%",
      roi: "12-18 months"
    },
    benefits: [
      "Reduced water usage",
      "Improved crop yield",
      "Labor cost savings",
      "Sustainable farming"
    ]
  },
  {
    title: "Commercial Buildings",
    icon: <Business sx={{ fontSize: 40 }} />,
    color: "#3F51B5",
    description: "Water management solutions for commercial and office buildings",
    applications: [
      "Water conservation",
      "HVAC systems",
      "Rainwater harvesting",
      "Grey water recycling"
    ],
    technologies: [
      "Building automation",
      "Smart metering",
      "Leak detection",
      "Treatment systems"
    ],
    metrics: {
      savings: "25-40%",
      monitoring: "24/7",
      maintenance: "Predictive",
      certification: "LEED points"
    },
    benefits: [
      "Lower operating costs",
      "Sustainability goals",
      "Asset protection",
      "Green building certification"
    ]
  },
  {
    title: "Construction",
    icon: <Construction sx={{ fontSize: 40 }} />,
    color: "#FFA000",
    description: "Water treatment solutions for construction projects",
    applications: [
      "Dewatering systems",
      "Sediment control",
      "Dust suppression",
      "Concrete production"
    ],
    technologies: [
      "Mobile treatment units",
      "Filtration systems",
      "pH control",
      "Monitoring equipment"
    ],
    metrics: {
      capacity: "500+ m³/day",
      turbidity: "<5 NTU",
      reuse: "Up to 80%",
      mobility: "100%"
    },
    benefits: [
      "Environmental compliance",
      "Cost reduction",
      "Project efficiency",
      "Sustainable practices"
    ]
  },
  {
    title: "Healthcare Facilities",
    icon: <LocalHospital sx={{ fontSize: 40 }} />,
    color: "#E91E63",
    description: "Specialized water treatment for healthcare institutions",
    applications: [
      "Sterilization",
      "Dialysis water",
      "Laboratory use",
      "General sanitization"
    ],
    technologies: [
      "UV disinfection",
      "Reverse osmosis",
      "Deionization",
      "Chlorine control"
    ],
    metrics: {
      purity: "Medical grade",
      bacteria: "0 CFU/ml",
      monitoring: "Continuous",
      compliance: "ISO 13485"
    },
    benefits: [
      "Patient safety",
      "Regulatory compliance",
      "Equipment protection",
      "Infection control"
    ]
  },
  {
    title: "Food & Beverage",
    icon: <Restaurant sx={{ fontSize: 40 }} />,
    color: "#8BC34A",
    description: "Process water solutions for food and beverage production",
    applications: [
      "Product water",
      "CIP systems",
      "Bottle washing",
      "Waste treatment"
    ],
    technologies: [
      "Micro-filtration",
      "UV treatment",
      "Ozonation",
      "Softening"
    ],
    metrics: {
      quality: "FDA/USDA",
      capacity: "200+ m³/day",
      recovery: "85-95%",
      sanitation: "SOP compliant"
    },
    benefits: [
      "Product quality",
      "Operating efficiency",
      "Regulatory compliance",
      "Resource conservation"
    ]
  },
  {
    title: "Research & Development",
    icon: <EmojiObjects sx={{ fontSize: 40 }} />,
    color: "#607D8B",
    description: "Ultra-pure water systems for research facilities",
    applications: [
      "Laboratory grade water",
      "Analytical testing",
      "Equipment cleaning",
      "Special processes"
    ],
    technologies: [
      "Multi-stage filtration",
      "Polishing systems",
      "TOC reduction",
      "Quality monitoring"
    ],
    metrics: {
      purity: "Type 1-4",
      resistivity: "18.2 MΩ·cm",
      toc: "<5 ppb",
      particles: "<1/ml"
    },
    benefits: [
      "Research accuracy",
      "Equipment longevity",
      "Flexible applications",
      "Quality assurance"
    ]
  }
];

const Industries = () => {
  return (
    <Box sx={{ py: 8, bgcolor: '#f8f9fa' }}>
      <Container maxWidth="xl">
        {/* Header */}
        <Box sx={{ mb: 8, textAlign: 'center' }}>
          <Typography variant="h2" gutterBottom>
            Industries We Serve
          </Typography>
          <Typography variant="h5" color="text.secondary" sx={{ mb: 4 }}>
            Specialized water treatment solutions across diverse sectors
          </Typography>
        </Box>

        {/* Industry Cards */}
        <Grid container spacing={4}>
          {industries.map((industry, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Card 
                sx={{ 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: `0 20px 40px ${industry.color}15`
                  }
                }}
              >
                <CardContent sx={{ p: 4, flexGrow: 1 }}>
                  {/* Header */}
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <Box sx={{ 
                      mr: 2,
                      p: 1,
                      borderRadius: 2,
                      bgcolor: `${industry.color}15`,
                      color: industry.color
                    }}>
                      {industry.icon}
                    </Box>
                    <Typography variant="h5" component="h2">
                      {industry.title}
                    </Typography>
                  </Box>

                  <Typography color="text.secondary" paragraph>
                    {industry.description}
                  </Typography>

                  {/* Applications */}
                  <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                    Key Applications
                  </Typography>
                  <Grid container spacing={1} sx={{ mb: 3 }}>
                    {industry.applications.map((app, idx) => (
                      <Grid item xs={6} key={idx}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <CheckCircle sx={{ fontSize: 16, mr: 1, color: industry.color }} />
                          <Typography variant="body2">{app}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>

                  {/* Technologies */}
                  <Typography variant="h6" gutterBottom>
                    Technologies
                  </Typography>
                  <Box sx={{ mb: 3 }}>
                    {industry.technologies.map((tech, idx) => (
                      <Chip
                        key={idx}
                        label={tech}
                        sx={{ 
                          m: 0.5,
                          bgcolor: `${industry.color}15`,
                          color: industry.color,
                          '&:hover': {
                            bgcolor: `${industry.color}25`,
                          }
                        }}
                      />
                    ))}
                  </Box>

                  {/* Metrics */}
                  <Typography variant="h6" gutterBottom>
                    Performance Metrics
                  </Typography>
                  <Grid container spacing={2} sx={{ mb: 3 }}>
                    {Object.entries(industry.metrics).map(([key, value], idx) => (
                      <Grid item xs={6} key={idx}>
                        <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
                          {key}:
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                          {value}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>

                  {/* Benefits */}
                  <Typography variant="h6" gutterBottom>
                    Key Benefits
                  </Typography>
                  <List dense>
                    {industry.benefits.map((benefit, idx) => (
                      <ListItem key={idx} sx={{ px: 0 }}>
                        <ListItemIcon sx={{ minWidth: 30 }}>
                          <CheckCircle sx={{ fontSize: 16, color: industry.color }} />
                        </ListItemIcon>
                        <ListItemText primary={benefit} />
                      </ListItem>
                    ))}
                  </List>

                  <Button 
                    variant="contained" 
                    fullWidth
                    sx={{ 
                      mt: 3,
                      bgcolor: industry.color,
                      '&:hover': {
                        bgcolor: industry.color,
                        opacity: 0.9
                      }
                    }}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Industries;
