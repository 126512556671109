import React from 'react';
import { Box, Container, Grid, Typography, Card, Button } from '@mui/material';
import { WaterDrop, Science, Engineering, Speed, Timeline, Warning, FilterAlt, Waves, Loop } from '@mui/icons-material';

const treatmentProcesses = [
  {
    title: "Pre Treatment",
    description: "Initial water treatment phase to remove large particles and debris",
    icon: <FilterAlt />,
    color: "#2196F3",
    parameters: {
      turbidity: "< 5 NTU",
      tss: "< 50 mg/L",
      flowRate: "100-500 m³/h",
      pressure: "2-4 bar"
    },
    controls: [
      "Automatic backwash",
      "Pressure monitoring",
      "Flow adjustment",
      "Chemical dosing"
    ]
  },
  {
    title: "Biological Treatment",
    description: "Biological processes for organic matter removal",
    icon: <Science />,
    color: "#4CAF50",
    parameters: {
      bod: "< 10 mg/L",
      cod: "< 50 mg/L",
      mlss: "3000-5000 mg/L",
      do: "2-4 mg/L"
    },
    controls: [
      "Aeration control",
      "Sludge recycling",
      "Nutrient dosing",
      "pH control"
    ]
  },
  {
    title: "DAF (Dissolved Air Flotation)",
    description: "Separation of suspended solids using air bubbles",
    icon: <Waves />,
    color: "#00BCD4",
    parameters: {
      pressure: "4-6 bar",
      recycle: "10-15%",
      solids: "95% removal",
      flowRate: "50-200 m³/h"
    },
    controls: [
      "Air flow control",
      "Recycle rate",
      "Chemical dosing",
      "Skimmer speed"
    ]
  },
  {
    title: "SRS (Sludge Recycling System)",
    description: "Management and recycling of treatment sludge",
    icon: <Loop />,
    color: "#795548",
    parameters: {
      concentration: "0.5-2%",
      flowRate: "10-50 m³/h",
      solids: "95-99%",
      pressure: "2-3 bar"
    },
    controls: [
      "Pump speed control",
      "Solids monitoring",
      "Level control",
      "Dewatering control"
    ]
  },
  {
    title: "MBR (Membrane Bio-Reactor)",
    description: "Combined biological treatment and membrane filtration",
    icon: <Engineering />,
    color: "#9C27B0",
    parameters: {
      flux: "15-25 LMH",
      tmp: "< 200 mbar",
      mlss: "8000-12000 mg/L",
      permeability: "> 200 LMH/bar"
    },
    controls: [
      "Membrane aeration",
      "Permeate control",
      "Backwash cycles",
      "Chemical cleaning"
    ]
  },
  {
    title: "SUF (Sand/Ultra Filtration)",
    description: "Dual media filtration for particle removal",
    icon: <FilterAlt />,
    color: "#FF9800",
    parameters: {
      turbidity: "< 0.1 NTU",
      pressure: "1-2 bar",
      flowRate: "50-150 m³/h",
      backwash: "Every 24h"
    },
    controls: [
      "Filter backwash",
      "Pressure monitoring",
      "Flow control",
      "Media level"
    ]
  },
  {
    title: "MF (Micro Filtration)",
    description: "Removal of fine particles and bacteria",
    icon: <FilterAlt />,
    color: "#3F51B5",
    parameters: {
      poreSize: "0.1-0.5 µm",
      flux: "40-80 LMH",
      pressure: "< 1 bar",
      recovery: "95-98%"
    },
    controls: [
      "TMP control",
      "Flow regulation",
      "Backwash frequency",
      "CIP scheduling"
    ]
  },
  {
    title: "RO (Reverse Osmosis)",
    description: "High-pressure membrane filtration for dissolved solids removal",
    icon: <WaterDrop />,
    color: "#E91E63",
    parameters: {
      recovery: "75-85%",
      rejection: "> 99%",
      pressure: "10-15 bar",
      conductivity: "< 10 µS/cm"
    },
    controls: [
      "Pressure control",
      "Anti-scalant dosing",
      "Permeate quality",
      "CIP automation"
    ]
  },
  {
    title: "RO Reject Treatment",
    description: "Treatment and management of RO concentrate",
    icon: <Warning />,
    color: "#FF5722",
    parameters: {
      recovery: "40-60%",
      concentration: "< 70,000 mg/L",
      pressure: "15-20 bar",
      pH: "6.5-7.5"
    },
    controls: [
      "Concentrate recovery",
      "Scale prevention",
      "Discharge control",
      "pH adjustment"
    ]
  }
];

const TreatmentProcessControl = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        {/* Header */}
        <Box sx={{ mb: 8, textAlign: 'center' }}>
          <Engineering sx={{ fontSize: 60, color: '#00C6CF', mb: 2 }} />
          <Typography variant="h2" gutterBottom>
            Treatment Process Control
          </Typography>
          <Typography variant="h5" color="text.secondary" sx={{ mb: 4 }}>
            Advanced control systems for water treatment processes
          </Typography>
        </Box>

        {/* Process Cards */}
        <Grid container spacing={4}>
          {treatmentProcesses.map((process, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{ 
                p: 3,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: `0 20px 40px ${process.color}15`
                }
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  {React.cloneElement(process.icon, { 
                    sx: { fontSize: 40, color: process.color, mr: 2 } 
                  })}
                  <Typography variant="h5">{process.title}</Typography>
                </Box>
                <Typography color="text.secondary" paragraph>
                  {process.description}
                </Typography>

                {/* Parameters */}
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Control Parameters
                  </Typography>
                  <Grid container spacing={2}>
                    {Object.entries(process.parameters).map(([key, value], idx) => (
                      <Grid item xs={6} key={idx}>
                        <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
                          {key.replace(/([A-Z])/g, ' $1').trim()}:
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                          {value}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                {/* Control Points */}
                <Box sx={{ mt: 'auto' }}>
                  <Typography variant="h6" gutterBottom>
                    Control Points
                  </Typography>
                  <Grid container spacing={1}>
                    {process.controls.map((control, idx) => (
                      <Grid item xs={6} key={idx}>
                        <Typography variant="body2" color="text.secondary">
                          • {control}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                <Button 
                  variant="contained"
                  sx={{ 
                    mt: 3,
                    bgcolor: process.color,
                    '&:hover': {
                      bgcolor: process.color,
                      opacity: 0.9
                    }
                  }}
                >
                  View Details
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default TreatmentProcessControl;
