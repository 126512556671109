import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Paper,
  Tab,
  Tabs,
  Stack,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { motion } from 'framer-motion';
import {
  Check,
  ThreeDRotation,
  ViewInAr,
  Settings,
  Speed,
  Construction,
  Architecture
} from '@mui/icons-material';

const productDetails = {
  name: "Industrial Cooling Tower",
  description: "High-performance cooling tower designed for industrial applications with advanced heat exchange technology and optimal water distribution system.",
  features: [
    "Advanced drift eliminators for minimal water loss",
    "High-efficiency fill media for maximum heat transfer",
    "Corrosion-resistant materials for long service life",
    "Low maintenance design with easy access panels",
    "Energy-efficient fan system",
    "Optimized water distribution"
  ],
  specifications: {
    "Cooling Capacity": "500-2000 TR",
    "Flow Rate": "200-800 m³/hr",
    "Heat Rejection": "Up to 12000 kW",
    "Fan Power": "15-75 kW",
    "Construction": "FRP/SS/Hybrid",
    "Fill Type": "PVC Film Type"
  },
  applications: [
    "Power Plants",
    "Chemical Industries",
    "HVAC Systems",
    "Process Cooling",
    "Manufacturing Facilities",
    "Petrochemical Plants"
  ]
};

function ModelPlaceholder() {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: '#ffffff',
        p: 4,
      }}
    >
      <Box 
        component="img"
        src="/3d-cube-icon.svg"
        alt="3D Model"
        sx={{ 
          width: 120,
          height: 120,
          mb: 3,
          filter: 'invert(70%) sepia(54%) saturate(654%) hue-rotate(140deg) brightness(95%) contrast(98%)'
        }}
      />
      <Typography 
        variant="h4" 
        sx={{ 
          color: '#00bcd4',
          fontWeight: 500,
          mb: 2 
        }}
      >
        3D Model Viewer
      </Typography>
      <Typography 
        variant="body1" 
        color="text.secondary" 
        align="center" 
        sx={{ 
          maxWidth: 400,
          mb: 4,
          color: '#637381'
        }}
      >
        We're working on bringing you an interactive 3D
        view of our cooling tower. Soon you'll be able to
        explore every detail in full 3D.
      </Typography>
      <Stack 
        direction="row" 
        spacing={2}
      >
        <Button
          variant="outlined"
          sx={{
            borderColor: '#00bcd4',
            color: '#00bcd4',
            '&:hover': {
              borderColor: '#00acc1',
              backgroundColor: 'rgba(0, 188, 212, 0.04)'
            },
            px: 3,
            py: 1,
            borderRadius: 2,
            textTransform: 'none'
          }}
          startIcon={
            <ThreeDRotation sx={{ color: '#00bcd4' }} />
          }
        >
          Rotate View
        </Button>
        <Button
          variant="outlined"
          sx={{
            borderColor: '#00bcd4',
            color: '#00bcd4',
            '&:hover': {
              borderColor: '#00acc1',
              backgroundColor: 'rgba(0, 188, 212, 0.04)'
            },
            px: 3,
            py: 1,
            borderRadius: 2,
            textTransform: 'none'
          }}
          startIcon={
            <ViewInAr sx={{ color: '#00bcd4' }} />
          }
        >
          Zoom
        </Button>
      </Stack>
    </Box>
  );
}

export default function Products() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
      <Container maxWidth="xl" sx={{ pt: 12, pb: 8 }}>
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            variant="h1"
            align="center"
            sx={{
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              fontWeight: 700,
              mb: 2,
              background: 'linear-gradient(45deg, #1a237e 30%, #0288d1 90%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            {productDetails.name}
          </Typography>
          <Typography
            variant="h6"
            align="center"
            color="text.secondary"
            sx={{ maxWidth: 800, mx: 'auto', mb: 8 }}
          >
            {productDetails.description}
          </Typography>
        </motion.div>

        <Grid container spacing={4}>
          {/* 3D Viewer Placeholder */}
          <Grid item xs={12} md={7}>
            <Paper
              elevation={0}
              sx={{
                height: 600,
                borderRadius: 4,
                overflow: 'hidden',
                position: 'relative',
                border: '1px solid',
                borderColor: 'grey.200'
              }}
            >
              <ModelPlaceholder />
            </Paper>
          </Grid>

          {/* Product Details */}
          <Grid item xs={12} md={5}>
            <Paper
              elevation={0}
              sx={{
                height: '100%',
                p: 4,
                borderRadius: 4,
                border: '1px solid',
                borderColor: 'grey.200'
              }}
            >
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}
              >
                <Tab 
                  icon={<Settings sx={{ fontSize: 20 }} />}
                  label="Features"
                  sx={{ textTransform: 'none' }}
                />
                <Tab 
                  icon={<Architecture sx={{ fontSize: 20 }} />}
                  label="Specifications"
                  sx={{ textTransform: 'none' }}
                />
                <Tab 
                  icon={<Construction sx={{ fontSize: 20 }} />}
                  label="Applications"
                  sx={{ textTransform: 'none' }}
                />
              </Tabs>

              <Box sx={{ mt: 2 }}>
                {activeTab === 0 && (
                  <List>
                    {productDetails.features.map((feature, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <Check color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={feature} />
                      </ListItem>
                    ))}
                  </List>
                )}

                {activeTab === 1 && (
                  <Stack spacing={2}>
                    {Object.entries(productDetails.specifications).map(([key, value]) => (
                      <Box
                        key={key}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          p: 2,
                          bgcolor: 'grey.50',
                          borderRadius: 2
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: 'text.secondary'
                          }}
                        >
                          {key}
                        </Typography>
                        <Typography>{value}</Typography>
                      </Box>
                    ))}
                  </Stack>
                )}

                {activeTab === 2 && (
                  <List>
                    {productDetails.applications.map((application, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <Speed color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={application} />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
