import React from 'react';
import { Box, Container, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import WaterIcon from '@mui/icons-material/Water';
import ScienceIcon from '@mui/icons-material/Science';
import SettingsIcon from '@mui/icons-material/Settings';
import BuildIcon from '@mui/icons-material/Build';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import SchoolIcon from '@mui/icons-material/School';
import BalanceIcon from '@mui/icons-material/Balance';
import { useNavigate } from 'react-router-dom';

const PageContainer = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%)',
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(8),
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(45deg, #00C6CF, #4FACFE)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 700,
}));

const SolutionCard = styled(Card)(({ theme }) => ({
  height: '100%',
  background: 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  borderRadius: '20px',
  border: '1px solid rgba(0, 198, 207, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 20px 40px rgba(0, 198, 207, 0.15)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #00C6CF 30%, #4FACFE 90%)',
  color: 'white',
  padding: '8px 24px',
  borderRadius: '25px',
  textTransform: 'none',
  fontSize: '0.95rem',
  fontWeight: 600,
  marginTop: theme.spacing(2),
  boxShadow: '0 3px 15px rgba(0, 198, 207, 0.2)',
  '&:hover': {
    background: 'linear-gradient(45deg, #4FACFE 30%, #00C6CF 90%)',
    transform: 'translateY(-2px)',
    boxShadow: '0 5px 20px rgba(0, 198, 207, 0.3)',
  },
}));

const solutions = [
  {
    icon: <WaterIcon sx={{ fontSize: 40, color: '#00C6CF' }} />,
    title: 'Water Treatment Systems',
    description: 'Complete water treatment solutions including RO plants, DM plants, and softening systems.',
    features: [
      'Reverse Osmosis Systems',
      'Demineralization Plants',
      'Water Softening Systems',
      'Ultrafiltration Systems',
    ],
  },
  {
    icon: <BalanceIcon sx={{ fontSize: 40, color: '#00C6CF' }} />,
    title: 'Equalization System',
    description: 'Advanced wastewater equalization system for efficient flow management and treatment.',
    features: [
      'Flow Stabilization',
      'Load Balancing',
      'Process Optimization',
      'Automated Control',
    ],
    link: '/solutions/equalization'
  },
  {
    icon: <ScienceIcon sx={{ fontSize: 40, color: '#00C6CF' }} />,
    title: 'Process Water Solutions',
    description: 'Specialized water treatment for various industrial processes and applications.',
    features: [
      'Cooling Tower Treatment',
      'Boiler Water Treatment',
      'Process Water Purification',
      'Wastewater Treatment',
    ],
  },
  {
    icon: <SettingsIcon sx={{ fontSize: 40, color: '#00C6CF' }} />,
    title: 'Operation & Maintenance',
    description: 'Comprehensive operation and maintenance services for water treatment plants.',
    features: [
      'Preventive Maintenance',
      '24/7 Technical Support',
      'Performance Optimization',
      'Emergency Services',
    ],
  },
  {
    icon: <BuildIcon sx={{ fontSize: 40, color: '#00C6CF' }} />,
    title: 'Equipment & Spares',
    description: 'High-quality equipment and spare parts for water treatment systems.',
    features: [
      'Pumps & Motors',
      'Filters & Membranes',
      'Control Systems',
      'Chemical Dosing Equipment',
    ],
  },
  {
    icon: <MonitorHeartIcon sx={{ fontSize: 40, color: '#00C6CF' }} />,
    title: 'Monitoring & Analysis',
    description: 'Advanced monitoring and analysis services for water quality management.',
    features: [
      'Water Quality Testing',
      'Online Monitoring Systems',
      'Performance Analysis',
      'Compliance Reporting',
    ],
  },
  {
    icon: <SchoolIcon sx={{ fontSize: 40, color: '#00C6CF' }} />,
    title: 'Consulting & Training',
    description: 'Expert consulting and training services for water treatment operations.',
    features: [
      'Technical Consulting',
      'Operator Training',
      'Process Optimization',
      'Compliance Support',
    ],
  },
];

const Solutions = () => {
  const navigate = useNavigate();
  
  return (
    <PageContainer>
      <Container maxWidth="lg">
        {/* Hero Section */}
        <Box mb={12}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <GradientText variant="h2" align="center" gutterBottom>
              Our Solutions
            </GradientText>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              sx={{ maxWidth: 800, mx: 'auto', mb: 6 }}
            >
              Comprehensive water treatment solutions tailored to your specific needs
            </Typography>
          </motion.div>
        </Box>

        {/* Solutions Grid */}
        <Grid container spacing={4}>
          {solutions.map((solution, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
              >
                <SolutionCard>
                  <CardContent sx={{ p: 4 }}>
                    {solution.icon}
                    <Typography variant="h5" sx={{ mt: 2, mb: 1, fontWeight: 600 }}>
                      {solution.title}
                    </Typography>
                    <Typography color="text.secondary" paragraph>
                      {solution.description}
                    </Typography>
                    <Box component="ul" sx={{ pl: 2 }}>
                      {solution.features.map((feature, idx) => (
                        <Typography
                          component="li"
                          key={idx}
                          color="text.secondary"
                          sx={{ mb: 0.5 }}
                        >
                          {feature}
                        </Typography>
                      ))}
                    </Box>
                    {solution.link && (
                      <StyledButton onClick={() => navigate(solution.link)}>
                        Learn More
                      </StyledButton>
                    )}
                  </CardContent>
                </SolutionCard>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </PageContainer>
  );
};

export default Solutions;
