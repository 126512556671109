import React, { useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Snackbar,
  Alert,
  Box,
  Chip,
  Stack,
  Tabs,
  Tab,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { useCareer } from '../../context/CareerContext';
import JobDialog from '../../components/JobDialog';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const CareerDashboard = () => {
  const { jobs, applications, addJob, updateJob, deleteJob, updateApplicationStatus, loading, error } = useCareer();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState('add');
  const [selectedJob, setSelectedJob] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleOpenDialog = (mode = 'add', job = null) => {
    setDialogMode(mode);
    setSelectedJob(job);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedJob(null);
    setDialogMode('add');
  };

  const handleSaveJob = async (jobData) => {
    try {
      if (dialogMode === 'add') {
        await addJob(jobData);
        setSnackbar({
          open: true,
          message: 'Job added successfully!',
          severity: 'success',
        });
      } else {
        await updateJob({ ...jobData, id: selectedJob.id });
        setSnackbar({
          open: true,
          message: 'Job updated successfully!',
          severity: 'success',
        });
      }
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving job:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Failed to save job. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleDeleteJob = async (jobId) => {
    try {
      await deleteJob(jobId);
      setSnackbar({
        open: true,
        message: 'Job deleted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting job:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Failed to delete job. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleUpdateApplicationStatus = async (applicationId, newStatus) => {
    try {
      await updateApplicationStatus(applicationId, newStatus);
      setSnackbar({
        open: true,
        message: 'Application status updated successfully!',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update application status',
        severity: 'error',
      });
    }
  };

  if (loading) {
    return (
      <Container sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Job Postings" />
          <Tab label="Applications" />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Typography variant="h5" component="h1">
            Manage Job Postings
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog('add')}
          >
            Add New Job
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Posted Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs.map((job) => (
                <TableRow key={job.id}>
                  <TableCell>{job.title}</TableCell>
                  <TableCell>{job.department}</TableCell>
                  <TableCell>{job.location}</TableCell>
                  <TableCell>{job.postedDate}</TableCell>
                  <TableCell>
                    <Chip
                      label={job.status}
                      color={job.status === 'Active' ? 'success' : 'default'}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                      <IconButton
                        onClick={() => handleOpenDialog('edit', job)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteJob(job.id)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Typography variant="h5" component="h1" sx={{ mb: 3 }}>
          Job Applications
        </Typography>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Job Title</TableCell>
                <TableCell>Applied Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {applications.map((application) => {
                const job = jobs.find(j => j.id === application.jobId);
                return (
                  <TableRow key={application.id}>
                    <TableCell>{application.name}</TableCell>
                    <TableCell>{application.email}</TableCell>
                    <TableCell>{job ? job.title : 'N/A'}</TableCell>
                    <TableCell>{application.appliedDate}</TableCell>
                    <TableCell>
                      <Chip
                        label={application.status}
                        color={
                          application.status === 'New'
                            ? 'info'
                            : application.status === 'Reviewing'
                            ? 'warning'
                            : application.status === 'Accepted'
                            ? 'success'
                            : 'error'
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => handleUpdateApplicationStatus(application.id, 'Reviewing')}
                          disabled={application.status !== 'New'}
                        >
                          Review
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          color="success"
                          onClick={() => handleUpdateApplicationStatus(application.id, 'Accepted')}
                          disabled={application.status !== 'Reviewing'}
                        >
                          Accept
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          color="error"
                          onClick={() => handleUpdateApplicationStatus(application.id, 'Rejected')}
                          disabled={application.status !== 'Reviewing'}
                        >
                          Reject
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              {applications.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No applications found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <JobDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onSave={handleSaveJob}
        mode={dialogMode}
        job={selectedJob}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CareerDashboard;
