import React from 'react';
import { Box, Container, Typography, Grid, Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import BiotechIcon from '@mui/icons-material/Biotech';
import MemoryIcon from '@mui/icons-material/Memory';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import PsychologyIcon from '@mui/icons-material/Psychology';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const PageContainer = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%)',
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(8),
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(45deg, #00C6CF, #4FACFE)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 700,
}));

const TechnologyCard = ({ tech, index }) => (
  <motion.div
    initial={{ opacity: 0, y: 50 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.8, delay: index * 0.2 }}
  >
    <Card
      sx={{
        height: '100%',
        background: 'rgba(255, 255, 255, 0.9)',
        backdropFilter: 'blur(10px)',
        borderRadius: '20px',
        border: '1px solid rgba(0, 198, 207, 0.1)',
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 20px 40px rgba(0, 198, 207, 0.15)',
        },
      }}
    >
      <CardContent sx={{ p: 4 }}>
        <Box sx={{ mb: 3 }}>{tech.icon}</Box>
        <Typography variant="h5" gutterBottom>
          {tech.title}
        </Typography>
        <Typography color="text.secondary" paragraph>
          {tech.description}
        </Typography>
        <Box component="ul" sx={{ pl: 2 }}>
          {tech.features.map((feature, idx) => (
            <Typography
              key={idx}
              component="li"
              color="text.secondary"
              sx={{ mb: 1 }}
            >
              {feature}
            </Typography>
          ))}
        </Box>
      </CardContent>
    </Card>
  </motion.div>
);

const ProcessStep = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  borderRadius: '20px',
  background: 'rgba(255, 255, 255, 0.9)',
  border: '1px solid rgba(0, 198, 207, 0.1)',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    right: '-30px',
    width: '30px',
    height: '2px',
    background: 'linear-gradient(90deg, #00C6CF, #4FACFE)',
  },
  '&:last-child::after': {
    display: 'none',
  },
}));

const technologies = [
  {
    icon: <PsychologyIcon sx={{ fontSize: 40, color: '#00C6CF' }} />,
    title: 'AI-Powered Features',
    description: 'Advanced artificial intelligence capabilities enhancing water treatment efficiency.',
    features: [
      'Real-time decision making',
      'Automated system optimization',
      'Intelligent resource allocation',
      'Pattern recognition'
    ]
  },
  {
    icon: <DataThresholdingIcon sx={{ fontSize: 40, color: '#00C6CF' }} />,
    title: 'Neural Networks',
    description: 'Deep learning networks for sophisticated water quality analysis.',
    features: [
      'Deep learning algorithms',
      'Multi-parameter analysis',
      'Adaptive learning systems',
      'Complex pattern detection'
    ]
  },
  {
    icon: <MonitorHeartIcon sx={{ fontSize: 40, color: '#00C6CF' }} />,
    title: 'Smart Monitoring',
    description: 'Intelligent monitoring systems for comprehensive water quality control.',
    features: [
      '24/7 real-time monitoring',
      'Automated alert systems',
      'Performance tracking',
      'Quality assurance'
    ]
  },
  {
    icon: <QueryStatsIcon sx={{ fontSize: 40, color: '#00C6CF' }} />,
    title: 'Predictive Analysis',
    description: 'Advanced analytics for forecasting and optimization of treatment processes.',
    features: [
      'Trend forecasting',
      'Preventive maintenance',
      'Resource optimization',
      'Risk assessment'
    ]
  },
  {
    icon: <MemoryIcon sx={{ fontSize: 40, color: '#00C6CF' }} />,
    title: 'Smart Integration',
    description: 'Seamless integration of AI systems with existing infrastructure.',
    features: [
      'IoT connectivity',
      'Data synchronization',
      'Cloud integration',
      'Real-time processing'
    ]
  },
  {
    icon: <CloudSyncIcon sx={{ fontSize: 40, color: '#00C6CF' }} />,
    title: 'Cloud Processing',
    description: 'Cloud-based processing for enhanced performance and accessibility.',
    features: [
      'Scalable computing',
      'Remote access',
      'Data backup',
      'System redundancy'
    ]
  }
];

const processSteps = [
  {
    title: 'Initial Analysis',
    description: 'Comprehensive water quality analysis and requirement assessment.',
  },
  {
    title: 'System Design',
    description: 'Custom solution design based on specific requirements and constraints.',
  },
  {
    title: 'Implementation',
    description: 'Professional installation and commissioning of treatment systems.',
  },
  {
    title: 'Monitoring',
    description: 'Continuous monitoring and optimization of system performance.',
  },
];

const Technology = () => {
  return (
    <PageContainer>
      <Container maxWidth="lg">
        {/* Hero Section */}
        <Box mb={12}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <GradientText variant="h2" align="center" gutterBottom>
              AI-Powered Technologies
            </GradientText>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              sx={{ maxWidth: 800, mx: 'auto', mb: 6 }}
            >
              Next-generation water treatment powered by artificial intelligence and machine learning
            </Typography>
          </motion.div>
        </Box>

        {/* Technologies Grid */}
        <Box mb={12}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <Typography variant="h3" align="center" gutterBottom>
              Core Technologies
            </Typography>
          </motion.div>
          <Grid container spacing={4} mt={4}>
            {technologies.map((tech, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <TechnologyCard tech={tech} index={index} />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Process Section */}
        <Box>
          <Typography variant="h3" align="center" gutterBottom>
            Our Process
          </Typography>
          <Grid container spacing={4} mt={4}>
            {processSteps.map((step, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6, delay: index * 0.2 }}
                >
                  <ProcessStep>
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: '3rem',
                        fontWeight: 700,
                        color: 'rgba(0, 198, 207, 0.2)',
                        mb: 2,
                      }}
                    >
                      {index + 1}
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      {step.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {step.description}
                    </Typography>
                  </ProcessStep>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </PageContainer>
  );
};

export default Technology;
