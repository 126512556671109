import React from 'react';
import { Box, Container, Grid, Typography, Card, Button } from '@mui/material';
import { WaterDrop, Science, Engineering, Assessment } from '@mui/icons-material';

const demoLinks = [
  {
    title: 'Membrane Filtration',
    description: 'AI-powered membrane filtration process',
    url: 'https://demo.aiwater.example/membrane',
    icon: <WaterDrop />,
    color: '#00BCD4',
    metrics: {
      efficiency: '85% fouling reduction',
      lifetime: '40% increased membrane life',
      quality: '99.9% contaminant removal'
    }
  },
  {
    title: 'Chemical Dosing',
    description: 'Intelligent chemical dosing system',
    url: 'https://demo.aiwater.example/chemical',
    icon: <Science />,
    color: '#F44336',
    metrics: {
      reduction: '25% chemical usage reduction',
      accuracy: '99.5% dosing accuracy',
      optimization: '30% cost savings'
    }
  },
  {
    title: 'Biological Treatment',
    description: 'Advanced biological treatment control',
    url: 'https://demo.aiwater.example/biological',
    icon: <Engineering />,
    color: '#4CAF50',
    metrics: {
      efficiency: '35% increased removal rate',
      energy: '40% energy savings',
      stability: '99% process stability'
    }
  },
  {
    title: 'Quality Analysis',
    description: 'Real-time water quality monitoring',
    url: 'https://demo.aiwater.example/quality',
    icon: <Assessment />,
    color: '#9C27B0',
    metrics: {
      accuracy: '99.9% measurement accuracy',
      response: '<1s response time',
      parameters: '20+ parameters monitored'
    }
  }
];

const WaterTreatment = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        {/* Header */}
        <Box sx={{ mb: 8, textAlign: 'center' }}>
          <WaterDrop sx={{ fontSize: 60, color: '#00BCD4', mb: 2 }} />
          <Typography variant="h2" gutterBottom>
            Water Treatment Technologies
          </Typography>
          <Typography variant="h5" color="text.secondary" sx={{ mb: 4 }}>
            Experience our advanced water treatment solutions
          </Typography>
        </Box>

        {/* Demo Links */}
        <Grid container spacing={4}>
          {demoLinks.map((demo, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{ 
                p: 3,
                height: '100%',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: `0 20px 40px ${demo.color}15`
                }
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  {React.cloneElement(demo.icon, { sx: { fontSize: 40, color: demo.color, mr: 2 } })}
                  <Typography variant="h5">{demo.title}</Typography>
                </Box>
                <Typography color="text.secondary" paragraph>
                  {demo.description}
                </Typography>
                
                {/* Metrics */}
                <Box sx={{ mb: 3 }}>
                  {Object.entries(demo.metrics).map(([key, value], idx) => (
                    <Box key={idx} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
                        {key}:
                      </Typography>
                      <Typography variant="body2" fontWeight="medium">
                        {value}
                      </Typography>
                    </Box>
                  ))}
                </Box>

                <Button 
                  variant="contained" 
                  href={demo.url}
                  target="_blank"
                  sx={{ 
                    bgcolor: demo.color,
                    '&:hover': {
                      bgcolor: demo.color,
                      opacity: 0.9
                    }
                  }}
                >
                  View Demo
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default WaterTreatment;
