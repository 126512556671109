import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Paper, CircularProgress, Divider, AppBar, Toolbar, IconButton, Avatar, Menu, MenuItem, Alert, Button, Tabs, Tab, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Card, CardContent, LinearProgress } from '@mui/material';
import { 
  WaterDrop, Speed, Science, Opacity, Thermostat, WaterOutlined, FilterAlt, Memory, Warning, 
  AccountCircle, Logout, CloudUpload, Analytics, Security, Notifications, Info, Assessment, 
  PictureAsPdf, Download, Build, VerifiedUser, BoltOutlined, HealthAndSafety, DeviceHub,
  PowerSettingsNew, Settings, CheckCircle, Timeline, Storage, ArrowForward, DataUsage, Refresh
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, AreaChart, Area, PieChart, Pie, Cell, BarChart, Bar, Legend } from 'recharts';
import { useNavigate } from 'react-router-dom';

// Simulated cloud connection status
const cloudStatus = {
  connected: true,
  lastSync: '2025-02-28T14:39:04+05:30',
  storageUsed: '78%'
};

// Analytics data structure
const analyticsData = {
  efficiency: 92.5,
  anomalies: 3,
  predictions: [
    { parameter: 'pH Level', prediction: '7.2', confidence: 95 },
    { parameter: 'Turbidity', prediction: '0.8 NTU', confidence: 88 },
  ]
};

// Security metrics
const securityMetrics = {
  accessAttempts: 247,
  blockedThreats: 12,
  lastAudit: '2025-02-28',
  securityScore: 94
};

// Mock user data
const mockUser = {
  name: "John Smith",
  role: "Plant Operator",
  facility: "Water Treatment Plant 1",
  lastLogin: "2025-02-28T14:46:47+05:30"
};

// Generate random values within a range
const getRandomValue = (min, max, decimals = 1) => {
  return (Math.random() * (max - min) + min).toFixed(decimals);
};

// Generate random trend data with timestamps
const generateTrendData = (min, max) => {
  const now = new Date('2025-02-28T14:40:28+05:30');
  return Array.from({ length: 10 }, (_, i) => {
    const time = new Date(now.getTime() - i * 5 * 60000);
    return {
      time: time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
      value: getRandomValue(min, max),
      prediction: getRandomValue(min, max)
    };
  }).reverse();
};

// Enhanced ProcessParameter component with analytics
const ProcessParameter = ({ label, value, unit, icon, color = "#00519E", trend, alert }) => (
  <Paper
    elevation={0}
    sx={{
      p: 2,
      height: '100%',
      bgcolor: alert ? 'rgba(255,0,0,0.05)' : 'rgba(0,81,158,0.02)',
      border: `1px solid ${alert ? 'rgba(255,0,0,0.2)' : 'rgba(0,81,158,0.1)'}`,
      borderRadius: 2,
      transition: 'all 0.3s ease',
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 8px 24px rgba(0,81,158,0.12)',
        bgcolor: alert ? 'rgba(255,0,0,0.08)' : 'rgba(0,81,158,0.04)'
      }
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <Box sx={{ p: 1, borderRadius: 1.5, bgcolor: `${color}15`, mr: 1.5 }}>
        {React.cloneElement(icon, { sx: { fontSize: 20, color } })}
      </Box>
      <Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: 500 }}>
        {label}
      </Typography>
      {alert && (
        <Warning sx={{ ml: 'auto', color: 'warning.main' }} />
      )}
    </Box>
    <Typography variant="h6" sx={{ color, fontWeight: 600, pl: 0.5 }}>
      {value} {unit}
    </Typography>
    {trend && (
      <Box sx={{ mt: 2, height: 60 }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={trend}>
            <Area type="monotone" dataKey="value" stroke={color} fill={`${color}15`} />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    )}
  </Paper>
);

const ProcessSection = ({ title, parameters, color = "#00519E" }) => (
  <Paper
    elevation={0}
    sx={{
      p: 3,
      mb: 3,
      borderRadius: 3,
      border: '1px solid rgba(0,81,158,0.1)',
      position: 'relative',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 4,
        background: `linear-gradient(90deg, ${color}, ${color}88)`
      }
    }}
  >
    <Typography
      variant="h6"
      sx={{
        mb: 3,
        color,
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        '&::before': {
          content: '""',
          width: 4,
          height: 24,
          bgcolor: color,
          mr: 2,
          borderRadius: 1
        }
      }}
    >
      {title}
    </Typography>
    <Grid container spacing={2}>
      {parameters.map((param, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <ProcessParameter {...param} color={color} />
        </Grid>
      ))}
    </Grid>
  </Paper>
);

// Analytics Dashboard Component
const AnalyticsDashboard = ({ data }) => (
  <Paper sx={{ p: 3, mb: 3, borderRadius: 3 }}>
    <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
      <Analytics sx={{ mr: 1 }} color="primary" />
      Smart Analytics
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2, bgcolor: 'primary.light', color: 'white' }}>
          <Typography variant="body2">System Efficiency</Typography>
          <Typography variant="h4">{data.efficiency}%</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="body2">Anomalies Detected</Typography>
          <Typography variant="h4" color="error">{data.anomalies}</Typography>
        </Paper>
      </Grid>
    </Grid>
  </Paper>
);

// Cloud Status Component
const CloudStatus = ({ status }) => (
  <Paper sx={{ p: 3, mb: 3, borderRadius: 3 }}>
    <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
      <CloudUpload sx={{ mr: 1 }} color="primary" />
      Cloud Integration
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Alert severity={status.connected ? "success" : "error"}>
          {status.connected ? "Connected to Cloud" : "Cloud Connection Lost"}
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Last Sync: {new Date(status.lastSync).toLocaleString()}</Typography>
        <Typography variant="body2">Storage Used: {status.storageUsed}</Typography>
      </Grid>
    </Grid>
  </Paper>
);

// Security Dashboard Component
const SecurityDashboard = ({ metrics }) => (
  <Paper sx={{ p: 3, mb: 3, borderRadius: 3 }}>
    <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
      <Security sx={{ mr: 1 }} color="primary" />
      Security Status
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Typography variant="body2">Security Score</Typography>
        <Typography variant="h4" color="success.main">{metrics.securityScore}%</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body2">Blocked Threats</Typography>
        <Typography variant="h4" color="error.main">{metrics.blockedThreats}</Typography>
      </Grid>
    </Grid>
  </Paper>
);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const SystemCard = ({ system, data, color }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
  >
    <Paper
      elevation={3}
      sx={{
        p: 3,
        borderRadius: 2,
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '4px',
          background: `linear-gradient(90deg, ${color}80, ${color})`
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Box
          sx={{
            p: 1.5,
            borderRadius: 2,
            bgcolor: `${color}20`,
            mr: 2
          }}
        >
          {system.icon}
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" gutterBottom>
            {system.name}
          </Typography>
          <motion.div
            animate={{ 
              scale: data.status === 'Warning' ? [1, 1.1, 1] : 1,
              opacity: data.status === 'Warning' ? [1, 0.7, 1] : 1
            }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            <Chip
              label={data.status}
              color={data.status === 'Running' ? 'success' : 'warning'}
              sx={{ mr: 1 }}
            />
          </motion.div>
        </Box>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle2" color="textSecondary">
          System Efficiency
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flex: 1, mr: 1 }}>
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: `${data.efficiency}%` }}
              transition={{ duration: 1 }}
            >
              <LinearProgress
                variant="determinate"
                value={100}
                sx={{
                  height: 10,
                  borderRadius: 5,
                  bgcolor: `${color}20`,
                  '& .MuiLinearProgress-bar': {
                    bgcolor: color,
                    borderRadius: 5
                  }
                }}
              />
            </motion.div>
          </Box>
          <Typography variant="body1" fontWeight="medium">
            {data.efficiency}%
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={2}>
        {Object.entries(data)
          .filter(([key]) => !['status', 'efficiency', 'alerts'].includes(key))
          .map(([key, value]) => (
            <Grid item xs={6} key={key}>
              <Paper
                sx={{
                  p: 2,
                  bgcolor: 'background.default',
                  borderRadius: 2
                }}
              >
                <Typography variant="caption" color="textSecondary" display="block">
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </Typography>
                <Typography variant="body1" fontWeight="medium">
                  {typeof value === 'number' ? value.toFixed(2) : value} {getUnitForParameter(key)}
                </Typography>
              </Paper>
            </Grid>
          ))}
      </Grid>

      {data.alerts && data.alerts.length > 0 && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Alert 
            severity="warning"
            icon={<Warning />}
            sx={{ mt: 2 }}
          >
            {data.alerts[0]}
          </Alert>
        </motion.div>
      )}
    </Paper>
  </motion.div>
);

const FlowIndicator = ({ color }) => (
  <Box
    sx={{
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .arrow': {
        width: 20,
        height: 20,
        animation: 'flowMove 1.5s infinite linear',
        color: color
      },
      '@keyframes flowMove': {
        '0%': { transform: 'translateX(-100%)' },
        '100%': { transform: 'translateX(100%)' }
      }
    }}
  >
    <ArrowForward className="arrow" />
  </Box>
);

const ScadaViewPanel = () => (
  <Box sx={{ p: 3 }}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <Typography variant="h4" color="primary">
            SCADA Overview
          </Typography>
          <Button
            variant="contained"
            startIcon={<Refresh />}
            onClick={() => console.log('Refresh Data')}
          >
            Refresh Data
          </Button>
        </Box>
      </Grid>

      {/* Treatment Train Layout */}
      <Grid item xs={12}>
        <Box sx={{ position: 'relative' }}>
          <Grid container spacing={3}>
            {/* First Row */}
            <Grid item xs={12} md={4}>
              <SystemCard
                system={{ name: 'Biological Treatment', icon: <Science sx={{ fontSize: 32, color: '#4CAF50' }} /> }}
                data={{
                  status: 'Running',
                  efficiency: 92,
                  alerts: [],
                  flowRate: 150,
                  pressure: 2.5,
                  temperature: 28
                }}
                color="#4CAF50"
              />
              <FlowIndicator color="#4CAF50" />
            </Grid>

            <Grid item xs={12} md={4}>
              <SystemCard
                system={{ name: 'Cooling Tower', icon: <WaterDrop sx={{ fontSize: 32, color: '#2196F3' }} /> }}
                data={{
                  status: 'Running',
                  efficiency: 88,
                  alerts: ['Maintenance due in 5 days'],
                  flowRate: 200,
                  pressure: 3.0,
                  temperature: 32
                }}
                color="#2196F3"
              />
              <FlowIndicator color="#2196F3" />
            </Grid>

            <Grid item xs={12} md={4}>
              <SystemCard
                system={{ name: 'Blower System', icon: <Speed sx={{ fontSize: 32, color: '#FF9800' }} /> }}
                data={{
                  status: 'Running',
                  efficiency: 95,
                  alerts: [],
                  speed: 2800,
                  power: 85,
                  temperature: 45
                }}
                color="#FF9800"
              />
            </Grid>

            {/* Second Row */}
            <Grid item xs={12} md={4}>
              <SystemCard
                system={{ name: 'Microfiltration', icon: <FilterAlt sx={{ fontSize: 32, color: '#9C27B0' }} /> }}
                data={{
                  status: 'Running',
                  efficiency: 90,
                  alerts: ['Check filter condition'],
                  flowRate: 120,
                  pressure: 1.8,
                  turbidity: 0.5
                }}
                color="#9C27B0"
              />
              <FlowIndicator color="#9C27B0" />
            </Grid>

            <Grid item xs={12} md={4}>
              <SystemCard
                system={{ name: 'MBR', icon: <Memory sx={{ fontSize: 32, color: '#00BCD4' }} /> }}
                data={{
                  status: 'Running',
                  efficiency: 87,
                  alerts: ['Cleaning cycle scheduled'],
                  flux: 25,
                  pressure: 1.5,
                  permeability: 200
                }}
                color="#00BCD4"
              />
              <FlowIndicator color="#00BCD4" />
            </Grid>

            <Grid item xs={12} md={4}>
              <SystemCard
                system={{ name: 'Reverse Osmosis', icon: <WaterOutlined sx={{ fontSize: 32, color: '#F44336' }} /> }}
                data={{
                  status: 'Running',
                  efficiency: 91,
                  alerts: [],
                  flux: 20,
                  pressure: 12,
                  recovery: 80
                }}
                color="#F44336"
              />
            </Grid>
          </Grid>

          {/* Flow Lines */}
          <svg
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none'
            }}
          >
            <defs>
              <marker
                id="arrowhead"
                markerWidth="10"
                markerHeight="7"
                refX="9"
                refY="3.5"
                orient="auto"
              >
                <polygon points="0 0, 10 3.5, 0 7" fill="#666" />
              </marker>
            </defs>
            <path
              d="M 100 50 L 300 50"
              stroke="#666"
              strokeWidth="2"
              fill="none"
              markerEnd="url(#arrowhead)"
              strokeDasharray="4"
            />
          </svg>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

const WaterTreatmentHMI = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [userData, setUserData] = useState(null);
  const [processData, setProcessData] = useState({
    biological: {
      dissolvedOxygen: generateTrendData(2, 6),
      mlss: generateTrendData(2000, 4000),
      pH: generateTrendData(6.5, 7.5),
      temperature: generateTrendData(25, 35),
      svi: generateTrendData(80, 120)
    },
    coolingTower: {
      temperature: generateTrendData(25, 35),
      pH: generateTrendData(7, 8.5),
      conductivity: generateTrendData(800, 1200),
      makeupFlow: generateTrendData(10, 20),
      blowdown: generateTrendData(2, 5)
    },
    blower: {
      pressure: generateTrendData(0.5, 2),
      temperature: generateTrendData(40, 60),
      speed: generateTrendData(2000, 3000),
      power: generateTrendData(75, 90),
      airflow: generateTrendData(1000, 2000)
    },
    mf: {
      flux: generateTrendData(40, 60),
      tmp: generateTrendData(-0.2, -0.1),
      pressure: generateTrendData(1, 2),
      turbidity: generateTrendData(0.1, 1),
      recovery: generateTrendData(85, 95)
    },
    mbr: {
      flux: generateTrendData(20, 30),
      tmp: generateTrendData(-0.3, -0.1),
      mlss: generateTrendData(8000, 12000),
      permeability: generateTrendData(150, 250),
      aeration: generateTrendData(30, 50)
    },
    ro: {
      flux: generateTrendData(15, 25),
      pressure: generateTrendData(10, 15),
      recovery: generateTrendData(75, 85),
      conductivity: generateTrendData(50, 200),
      permeate: generateTrendData(80, 95)
    },
    reject: {
      flow: generateTrendData(5, 10),
      conductivity: generateTrendData(2000, 4000),
      pressure: generateTrendData(1, 3),
      temperature: generateTrendData(25, 35),
      pH: generateTrendData(6.5, 7.5)
    }
  });

  const [selectedSystem, setSelectedSystem] = useState(null);
  const [scadaData, setScadaData] = useState({
    biological: {
      status: 'Running',
      efficiency: 92,
      alerts: [],
      flowRate: 150,
      pressure: 2.5,
      temperature: 28
    },
    coolingTower: {
      status: 'Running',
      efficiency: 88,
      alerts: ['Maintenance due in 5 days'],
      flowRate: 200,
      pressure: 3.0,
      temperature: 32
    },
    blower: {
      status: 'Running',
      efficiency: 95,
      alerts: [],
      speed: 2800,
      power: 85,
      temperature: 45
    },
    mf: {
      status: 'Running',
      efficiency: 90,
      alerts: ['Check filter condition'],
      flowRate: 120,
      pressure: 1.8,
      turbidity: 0.5
    },
    mbr: {
      status: 'Running',
      efficiency: 87,
      alerts: ['Cleaning cycle scheduled'],
      flux: 25,
      pressure: 1.5,
      permeability: 200
    },
    ro: {
      status: 'Running',
      efficiency: 91,
      alerts: [],
      flux: 20,
      pressure: 12,
      recovery: 80
    }
  });

  const [alerts, setAlerts] = useState([
    { id: 1, message: 'High MLSS in MBR', severity: 'warning' },
    { id: 2, message: 'Low permeate flux in RO', severity: 'warning' },
    { id: 3, message: 'Blower temperature high', severity: 'error' }
  ]);

  // Define alert thresholds
  const alertThresholds = {
    mbr: {
      mlss: { high: 12000, low: 8000, unit: 'mg/L' },
      flux: { high: 30, low: 15, unit: 'LMH' }
    },
    ro: {
      flux: { high: 25, low: 15, unit: 'LMH' },
      pressure: { high: 15, low: 8, unit: 'bar' }
    },
    blower: {
      temperature: { high: 50, low: 30, unit: '°C' },
      pressure: { high: 2.5, low: 1.0, unit: 'bar' }
    }
  };

  // Function to generate random alerts
  const generateRandomAlerts = () => {
    const newScadaData = { ...scadaData };
    
    // MBR MLSS Alert (8000-12000 mg/L)
    const mbrMlss = Math.random() * 5000 + 8000;
    newScadaData.mbr.mlss = mbrMlss;
    if (mbrMlss > 12000) {
      newScadaData.mbr.alerts = [`High MLSS: ${Math.round(mbrMlss)} mg/L (Max: 12000 mg/L)`];
      newScadaData.mbr.status = 'Warning';
    } else {
      newScadaData.mbr.status = 'Running';
      newScadaData.mbr.alerts = [];
    }

    // RO Flux Alert (15-25 LMH)
    const roFlux = Math.random() * 20 + 10;
    newScadaData.ro.flux = roFlux;
    if (roFlux < 15) {
      newScadaData.ro.alerts = [`Low permeate flux: ${roFlux.toFixed(1)} LMH (Min: 15 LMH)`];
      newScadaData.ro.status = 'Warning';
    } else {
      newScadaData.ro.status = 'Running';
      newScadaData.ro.alerts = [];
    }

    // Blower Temperature Alert (30-50°C)
    const blowerTemp = Math.random() * 30 + 30;
    newScadaData.blower.temperature = blowerTemp;
    if (blowerTemp > 50) {
      newScadaData.blower.alerts = [`High temperature: ${Math.round(blowerTemp)}°C (Max: 50°C)`];
      newScadaData.blower.status = 'Warning';
    } else {
      newScadaData.blower.status = 'Running';
      newScadaData.blower.alerts = [];
    }

    setScadaData(newScadaData);
  };

  useEffect(() => {
    const alertInterval = setInterval(generateRandomAlerts, 5000); // Generate new alerts every 5 seconds
    return () => clearInterval(alertInterval);
  }, []);

  useEffect(() => {
    const user = sessionStorage.getItem('waterTreatmentUser');
    if (!user) {
      navigate('/water-treatment-login');
      return;
    }
    setUserData(JSON.parse(user));
  }, [navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setProcessData(prevData => ({
        biological: {
          dissolvedOxygen: updateTrendData(prevData.biological.dissolvedOxygen, 2, 6),
          mlss: updateTrendData(prevData.biological.mlss, 2000, 4000),
          pH: updateTrendData(prevData.biological.pH, 6.5, 7.5),
          temperature: updateTrendData(prevData.biological.temperature, 25, 35),
          svi: updateTrendData(prevData.biological.svi, 80, 120)
        },
        coolingTower: {
          temperature: updateTrendData(prevData.coolingTower.temperature, 25, 35),
          pH: updateTrendData(prevData.coolingTower.pH, 7, 8.5),
          conductivity: updateTrendData(prevData.coolingTower.conductivity, 800, 1200),
          makeupFlow: updateTrendData(prevData.coolingTower.makeupFlow, 10, 20),
          blowdown: updateTrendData(prevData.coolingTower.blowdown, 2, 5)
        },
        blower: {
          pressure: updateTrendData(prevData.blower.pressure, 0.5, 2),
          temperature: updateTrendData(prevData.blower.temperature, 40, 60),
          speed: updateTrendData(prevData.blower.speed, 2000, 3000),
          power: updateTrendData(prevData.blower.power, 75, 90),
          airflow: updateTrendData(prevData.blower.airflow, 1000, 2000)
        },
        mf: {
          flux: updateTrendData(prevData.mf.flux, 40, 60),
          tmp: updateTrendData(prevData.mf.tmp, -0.2, -0.1),
          pressure: updateTrendData(prevData.mf.pressure, 1, 2),
          turbidity: updateTrendData(prevData.mf.turbidity, 0.1, 1),
          recovery: updateTrendData(prevData.mf.recovery, 85, 95)
        },
        mbr: {
          flux: updateTrendData(prevData.mbr.flux, 20, 30),
          tmp: updateTrendData(prevData.mbr.tmp, -0.3, -0.1),
          mlss: updateTrendData(prevData.mbr.mlss, 8000, 12000),
          permeability: updateTrendData(prevData.mbr.permeability, 150, 250),
          aeration: updateTrendData(prevData.mbr.aeration, 30, 50)
        },
        ro: {
          flux: updateTrendData(prevData.ro.flux, 15, 25),
          pressure: updateTrendData(prevData.ro.pressure, 10, 15),
          recovery: updateTrendData(prevData.ro.recovery, 75, 85),
          conductivity: updateTrendData(prevData.ro.conductivity, 50, 200),
          permeate: updateTrendData(prevData.ro.permeate, 80, 95)
        },
        reject: {
          flow: updateTrendData(prevData.reject.flow, 5, 10),
          conductivity: updateTrendData(prevData.reject.conductivity, 2000, 4000),
          pressure: updateTrendData(prevData.reject.pressure, 1, 3),
          temperature: updateTrendData(prevData.reject.temperature, 25, 35),
          pH: updateTrendData(prevData.reject.pH, 6.5, 7.5)
        }
      }));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  // Helper function to update trend data
  const updateTrendData = (data, min, max) => {
    const newTime = new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    return [...data.slice(1), { time: newTime, value: getRandomValue(min, max) }];
  };

  const processParameters = {
    biological: [
      { label: 'Dissolved Oxygen', value: processData.biological.dissolvedOxygen[9].value, unit: 'mg/L', icon: <WaterOutlined />, trend: processData.biological.dissolvedOxygen },
      { label: 'MLSS', value: processData.biological.mlss[9].value, unit: 'mg/L', icon: <Science />, trend: processData.biological.mlss },
      { label: 'pH', value: processData.biological.pH[9].value, unit: '', icon: <Science />, trend: processData.biological.pH },
      { label: 'Temperature', value: processData.biological.temperature[9].value, unit: '°C', icon: <Thermostat />, trend: processData.biological.temperature },
      { label: 'SVI', value: processData.biological.svi[9].value, unit: 'mL/g', icon: <Speed />, trend: processData.biological.svi }
    ],
    coolingTower: [
      { label: 'Temperature', value: processData.coolingTower.temperature[9].value, unit: '°C', icon: <Thermostat />, trend: processData.coolingTower.temperature },
      { label: 'pH', value: processData.coolingTower.pH[9].value, unit: '', icon: <Science />, trend: processData.coolingTower.pH },
      { label: 'Conductivity', value: processData.coolingTower.conductivity[9].value, unit: 'µS/cm', icon: <Memory />, trend: processData.coolingTower.conductivity },
      { label: 'Makeup Flow', value: processData.coolingTower.makeupFlow[9].value, unit: 'm³/h', icon: <WaterDrop />, trend: processData.coolingTower.makeupFlow },
      { label: 'Blowdown', value: processData.coolingTower.blowdown[9].value, unit: 'm³/h', icon: <WaterDrop />, trend: processData.coolingTower.blowdown }
    ],
    blower: [
      { label: 'Pressure', value: processData.blower.pressure[9].value, unit: 'bar', icon: <Speed />, trend: processData.blower.pressure },
      { label: 'Temperature', value: processData.blower.temperature[9].value, unit: '°C', icon: <Thermostat />, trend: processData.blower.temperature, alert: true },
      { label: 'Speed', value: processData.blower.speed[9].value, unit: 'rpm', icon: <Speed />, trend: processData.blower.speed },
      { label: 'Power', value: processData.blower.power[9].value, unit: '%', icon: <Memory />, trend: processData.blower.power },
      { label: 'Air Flow', value: processData.blower.airflow[9].value, unit: 'Nm³/h', icon: <Speed />, trend: processData.blower.airflow }
    ],
    mf: [
      { label: 'Flux', value: processData.mf.flux[9].value, unit: 'LMH', icon: <Speed />, trend: processData.mf.flux },
      { label: 'TMP', value: processData.mf.tmp[9].value, unit: 'bar', icon: <Speed />, trend: processData.mf.tmp },
      { label: 'Pressure', value: processData.mf.pressure[9].value, unit: 'bar', icon: <Speed />, trend: processData.mf.pressure },
      { label: 'Turbidity', value: processData.mf.turbidity[9].value, unit: 'NTU', icon: <WaterDrop />, trend: processData.mf.turbidity },
      { label: 'Recovery', value: processData.mf.recovery[9].value, unit: '%', icon: <FilterAlt />, trend: processData.mf.recovery }
    ],
    mbr: [
      { label: 'Flux', value: processData.mbr.flux[9].value, unit: 'LMH', icon: <Speed />, trend: processData.mbr.flux },
      { label: 'TMP', value: processData.mbr.tmp[9].value, unit: 'bar', icon: <Speed />, trend: processData.mbr.tmp },
      { label: 'MLSS', value: processData.mbr.mlss[9].value, unit: 'mg/L', icon: <Science />, trend: processData.mbr.mlss, alert: true },
      { label: 'Permeability', value: processData.mbr.permeability[9].value, unit: 'LMH/bar', icon: <FilterAlt />, trend: processData.mbr.permeability },
      { label: 'Aeration', value: processData.mbr.aeration[9].value, unit: 'Nm³/h', icon: <Speed />, trend: processData.mbr.aeration }
    ],
    ro: [
      { label: 'Flux', value: processData.ro.flux[9].value, unit: 'LMH', icon: <Speed />, trend: processData.ro.flux, alert: true },
      { label: 'Pressure', value: processData.ro.pressure[9].value, unit: 'bar', icon: <Speed />, trend: processData.ro.pressure },
      { label: 'Recovery', value: processData.ro.recovery[9].value, unit: '%', icon: <FilterAlt />, trend: processData.ro.recovery },
      { label: 'Conductivity', value: processData.ro.conductivity[9].value, unit: 'µS/cm', icon: <Memory />, trend: processData.ro.conductivity },
      { label: 'Permeate Quality', value: processData.ro.permeate[9].value, unit: '%', icon: <WaterDrop />, trend: processData.ro.permeate }
    ],
    reject: [
      { label: 'Flow Rate', value: processData.reject.flow[9].value, unit: 'm³/h', icon: <WaterDrop />, trend: processData.reject.flow },
      { label: 'Conductivity', value: processData.reject.conductivity[9].value, unit: 'µS/cm', icon: <Memory />, trend: processData.reject.conductivity },
      { label: 'Pressure', value: processData.reject.pressure[9].value, unit: 'bar', icon: <Speed />, trend: processData.reject.pressure },
      { label: 'Temperature', value: processData.reject.temperature[9].value, unit: '°C', icon: <Thermostat />, trend: processData.reject.temperature },
      { label: 'pH', value: processData.reject.pH[9].value, unit: '', icon: <Science />, trend: processData.reject.pH }
    ]
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('waterTreatmentUser');
    navigate('/water-treatment-login');
  };

  const generateReport = () => {
    // Simulated report generation
    console.log('Generating report...');
  };

  const reportTypes = [
    { name: 'Daily Performance Report', icon: <Assessment />, period: 'Last 24 hours' },
    { name: 'Weekly Efficiency Analysis', icon: <Analytics />, period: 'Last 7 days' },
    { name: 'Monthly Maintenance Log', icon: <Build />, period: 'Last 30 days' },
    { name: 'Quality Compliance Report', icon: <VerifiedUser />, period: 'Current Month' },
    { name: 'Energy Consumption Analysis', icon: <BoltOutlined />, period: 'Last Month' },
    { name: 'System Health Overview', icon: <HealthAndSafety />, period: 'Current Status' }
  ];

  const systemComponents = [
    {
      id: 'biological',
      name: 'Biological Treatment',
      icon: <Science />,
      color: '#2E7D32'
    },
    {
      id: 'coolingTower',
      name: 'Cooling Tower',
      icon: <Opacity />,
      color: '#1976D2'
    },
    {
      id: 'blower',
      name: 'Blower System',
      icon: <Speed />,
      color: '#ED6C02'
    },
    {
      id: 'mf',
      name: 'Microfiltration',
      icon: <FilterAlt />,
      color: '#9C27B0'
    },
    {
      id: 'mbr',
      name: 'MBR',
      icon: <WaterOutlined />,
      color: '#0288D1'
    },
    {
      id: 'ro',
      name: 'Reverse Osmosis',
      icon: <WaterDrop />,
      color: '#D32F2F'
    }
  ];

  const generateReportData = (type) => {
    switch(type) {
      case 'performance':
        return Array.from({ length: 24 }, (_, i) => ({
          hour: `${String(i).padStart(2, '0')}:00`,
          efficiency: Math.floor(Math.random() * 20) + 80,
          flow: Math.floor(Math.random() * 100) + 100,
          pressure: (Math.random() * 2 + 1).toFixed(1)
        }));
      case 'quality':
        return Array.from({ length: 30 }, (_, i) => ({
          day: i + 1,
          ph: (Math.random() * 1 + 7).toFixed(1),
          turbidity: (Math.random() * 0.5).toFixed(2),
          conductivity: Math.floor(Math.random() * 200) + 800
        }));
      case 'energy':
        return Array.from({ length: 12 }, (_, i) => ({
          month: new Date(0, i).toLocaleString('default', { month: 'short' }),
          consumption: Math.floor(Math.random() * 5000) + 15000,
          cost: Math.floor(Math.random() * 2000) + 5000
        }));
      default:
        return [];
    }
  };

  const systemParameters = {
    mbr: {
      mlss: { min: 8000, max: 12000, unit: 'mg/L' },
      flux: { min: 15, max: 30, unit: 'LMH' },
      pressure: { min: 0.1, max: 0.5, unit: 'bar' },
      temperature: { min: 15, max: 35, unit: '°C' },
      dissolvedOxygen: { min: 2, max: 6, unit: 'mg/L' }
    },
    ro: {
      flux: { min: 15, max: 25, unit: 'LMH' },
      pressure: { min: 8, max: 15, unit: 'bar' },
      recovery: { min: 75, max: 85, unit: '%' },
      conductivity: { min: 100, max: 2000, unit: 'µS/cm' }
    },
    biological: {
      dissolvedOxygen: { min: 1.5, max: 4, unit: 'mg/L' },
      mlss: { min: 3000, max: 5000, unit: 'mg/L' },
      temperature: { min: 20, max: 35, unit: '°C' },
      ph: { min: 6.5, max: 7.5, unit: '' }
    },
    blower: {
      pressure: { min: 1.0, max: 2.5, unit: 'bar' },
      temperature: { min: 30, max: 50, unit: '°C' },
      power: { min: 50, max: 150, unit: 'kW' },
      efficiency: { min: 80, max: 95, unit: '%' }
    }
  };

  const [predictiveData, setPredictiveData] = useState({
    mbr: {
      membraneLife: {
        current: 85,
        prediction: '3 months remaining',
        trend: 'decreasing',
        maintenance: '2024-05-15'
      },
      performance: {
        current: 92,
        nextWeek: 88,
        recommendation: 'Schedule cleaning within 2 weeks'
      }
    },
    ro: {
      membraneLife: {
        current: 78,
        prediction: '5 months remaining',
        trend: 'stable',
        maintenance: '2024-07-20'
      },
      performance: {
        current: 90,
        nextWeek: 89,
        recommendation: 'Monitor scaling potential'
      }
    },
    blower: {
      efficiency: {
        current: 95,
        prediction: 'Optimal',
        trend: 'stable',
        maintenance: '2024-06-01'
      },
      powerUsage: {
        current: 85,
        nextWeek: 87,
        recommendation: 'Consider peak load optimization'
      }
    }
  });

  if (!userData) {
    return (
      <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="transparent" elevation={1}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Water Treatment HMI
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Chip
              icon={<AccountCircle />}
              label={userData?.name}
              variant="outlined"
              color="primary"
              sx={{ 
                '& .MuiChip-label': { 
                  fontWeight: 500 
                }
              }}
            />
            <IconButton 
              onClick={handleMenu}
              sx={{ 
                '&:hover': { 
                  bgcolor: 'primary.light',
                  '& .MuiSvgIcon-root': {
                    color: 'white'
                  }
                }
              }}
            >
              <AccountCircle color="primary" />
            </IconButton>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              sx: {
                mt: 1,
                minWidth: 200,
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                '& .MuiList-root': {
                  p: 1
                }
              }
            }}
          >
            <Box sx={{ px: 2, py: 1, borderBottom: '1px solid #eee' }}>
              <Typography variant="subtitle1" fontWeight="bold">
                {userData?.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {userData?.designation}
              </Typography>
            </Box>
            <MenuItem sx={{ py: 1 }}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="caption" color="textSecondary">
                  Company
                </Typography>
                <Typography variant="body2">
                  {userData?.companyName}
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem sx={{ py: 1 }}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="caption" color="textSecondary">
                  Email
                </Typography>
                <Typography variant="body2">
                  {userData?.email}
                </Typography>
              </Box>
            </MenuItem>
            <Divider />
            <MenuItem 
              onClick={handleLogout}
              sx={{ 
                mt: 1,
                color: 'error.main',
                '&:hover': {
                  bgcolor: 'error.light',
                  color: 'error.dark'
                }
              }}
            >
              <Logout sx={{ mr: 1 }} fontSize="small" />
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Alert 
        severity="info" 
        variant="filled"
        sx={{ 
          borderRadius: 0,
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}
      >
        <Info />
        <Typography variant="body2">
          Disclaimer: All data shown are simulated values for demonstration purposes only and do not represent actual process measurements.
        </Typography>
      </Alert>

      <Container maxWidth="xl" sx={{ mt: 4 }}>
        {alerts.map((alert) => (
          <Alert severity={alert.severity} sx={{ mb: 2 }} key={alert.id}>
            {alert.message}
          </Alert>
        ))}

        <Paper sx={{ mb: 3 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <Tab label="Overview" />
            <Tab label="SCADA View" />
            <Tab label="Biological" />
            <Tab label="Cooling Tower" />
            <Tab label="Blower" />
            <Tab label="MF" />
            <Tab label="MBR" />
            <Tab label="RO" />
            <Tab label="Analytics" />
            <Tab label="Predictive" />
            <Tab label="Reports" />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Alert severity="success" sx={{ mb: 2 }}>
                  System Status: Operational
                </Alert>
                <ProcessSection title="Key Parameters" parameters={[
                  { label: 'MLSS', value: processData.biological.mlss[9].value, unit: 'mg/L', icon: <Science />, trend: processData.biological.mlss },
                  { label: 'RO Flux', value: processData.ro.flux[9].value, unit: 'LMH', icon: <Speed />, trend: processData.ro.flux },
                  { label: 'Power Usage', value: processData.blower.power[9].value, unit: '%', icon: <Memory />, trend: processData.blower.power }
                ]} />
              </Grid>
              <Grid item xs={12} md={4}>
                <CloudStatus status={cloudStatus} />
                <SecurityDashboard metrics={securityMetrics} />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <ScadaViewPanel />
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <ProcessSection title="Biological Treatment" parameters={processParameters.biological} color="#2E7D32" />
          </TabPanel>

          <TabPanel value={tabValue} index={3}>
            <ProcessSection title="Cooling Tower" parameters={processParameters.coolingTower} color="#1976D2" />
          </TabPanel>

          <TabPanel value={tabValue} index={4}>
            <ProcessSection title="Blower System" parameters={processParameters.blower} color="#ED6C02" />
          </TabPanel>

          <TabPanel value={tabValue} index={5}>
            <ProcessSection title="Microfiltration (MF)" parameters={processParameters.mf} color="#9C27B0" />
          </TabPanel>

          <TabPanel value={tabValue} index={6}>
            <ProcessSection title="Membrane Bioreactor (MBR)" parameters={processParameters.mbr} color="#0288D1" />
          </TabPanel>

          <TabPanel value={tabValue} index={7}>
            <ProcessSection title="Reverse Osmosis (RO)" parameters={processParameters.ro} color="#D32F2F" />
            <ProcessSection title="RO Reject" parameters={processParameters.reject} color="#F57C00" />
          </TabPanel>

          <TabPanel value={tabValue} index={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <AnalyticsDashboard data={analyticsData} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper sx={{ p: 3, mb: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Real-time Insights
                  </Typography>
                  <Alert severity="info" sx={{ mb: 2 }}>
                    System efficiency trending upward
                  </Alert>
                  <Alert severity="warning" sx={{ mb: 2 }}>
                    Maintenance recommended for MBR
                  </Alert>
                </Paper>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={tabValue} index={9}>
            <Box sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h4" gutterBottom color="primary">
                      Predictive Analytics
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      AI-powered predictions and maintenance recommendations
                    </Typography>
                  </Box>
                </Grid>

                {/* Membrane Systems Predictions */}
                <Grid item xs={12} md={6}>
                  <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      MBR System Predictions
                    </Typography>
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Membrane Life Prediction
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={predictiveData.mbr.membraneLife.current}
                        sx={{ 
                          height: 10, 
                          borderRadius: 5,
                          mb: 1,
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: predictiveData.mbr.membraneLife.current > 50 ? 'success.main' : 'warning.main'
                          }
                        }}
                      />
                      <Typography variant="body2" color="textSecondary">
                        {predictiveData.mbr.membraneLife.prediction} | Next maintenance: {predictiveData.mbr.membraneLife.maintenance}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      RO System Predictions
                    </Typography>
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Membrane Life Prediction
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={predictiveData.ro.membraneLife.current}
                        sx={{ 
                          height: 10, 
                          borderRadius: 5,
                          mb: 1,
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: predictiveData.ro.membraneLife.current > 50 ? 'success.main' : 'warning.main'
                          }
                        }}
                      />
                      <Typography variant="body2" color="textSecondary">
                        {predictiveData.ro.membraneLife.prediction} | Next maintenance: {predictiveData.ro.membraneLife.maintenance}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>

                {/* Maintenance Recommendations */}
                <Grid item xs={12}>
                  <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      System Parameters
                    </Typography>
                    <Grid container spacing={2}>
                      {Object.entries(systemParameters).map(([system, params]) => (
                        <Grid item xs={12} lg={3} md={6} key={system}>
                          <Card>
                            <CardContent>
                              <Typography variant="h6" color="primary" gutterBottom>
                                {system.toUpperCase()} Parameters
                              </Typography>
                              {Object.entries(params).map(([param, values]) => (
                                <Box key={param} sx={{ mb: 2 }}>
                                  <Typography variant="subtitle2">
                                    {param.charAt(0).toUpperCase() + param.slice(1)}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    Range: {values.min} - {values.max} {values.unit}
                                  </Typography>
                                </Box>
                              ))}
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>

          <TabPanel value={tabValue} index={10}>
            <Box sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h4" gutterBottom color="primary">
                      Performance Reports
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      Comprehensive analysis of system performance, quality metrics, and resource utilization
                    </Typography>
                  </Box>
                </Grid>

                {/* Performance Metrics */}
                <Grid item xs={12}>
                  <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      System Performance Trends
                    </Typography>
                    <ResponsiveContainer width="100%" height={400}>
                      <LineChart data={generateReportData('performance')}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="hour" />
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" orientation="right" />
                        <Tooltip />
                        <Legend />
                        <Line yAxisId="left" type="monotone" dataKey="efficiency" stroke="#8884d8" name="Efficiency (%)" />
                        <Line yAxisId="right" type="monotone" dataKey="flow" stroke="#82ca9d" name="Flow Rate (m³/h)" />
                      </LineChart>
                    </ResponsiveContainer>
                  </Paper>
                </Grid>

                {/* Quality Metrics */}
                <Grid item xs={12} md={6}>
                  <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Water Quality Analysis
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <AreaChart data={generateReportData('quality')}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Area type="monotone" dataKey="ph" stackId="1" stroke="#8884d8" fill="#8884d8" name="pH" />
                        <Area type="monotone" dataKey="turbidity" stackId="2" stroke="#82ca9d" fill="#82ca9d" name="Turbidity" />
                      </AreaChart>
                    </ResponsiveContainer>
                  </Paper>
                </Grid>

                {/* Energy Consumption */}
                <Grid item xs={12} md={6}>
                  <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Energy Consumption
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart data={generateReportData('energy')}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="consumption" fill="#8884d8" name="Energy (kWh)" />
                        <Bar dataKey="cost" fill="#82ca9d" name="Cost ($)" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Paper>
                </Grid>

                {/* Report Generation Section */}
                <Grid item xs={12}>
                  <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Generate Custom Report
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <Card>
                          <CardContent>
                            <Typography variant="h6" color="primary" gutterBottom>
                              Daily Report
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                              Detailed 24-hour performance analysis
                            </Typography>
                            <Button
                              variant="contained"
                              startIcon={<Assessment />}
                              fullWidth
                            >
                              Generate
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Card>
                          <CardContent>
                            <Typography variant="h6" color="primary" gutterBottom>
                              Weekly Summary
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                              Week-over-week performance comparison
                            </Typography>
                            <Button
                              variant="contained"
                              startIcon={<Timeline />}
                              fullWidth
                            >
                              Generate
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Card>
                          <CardContent>
                            <Typography variant="h6" color="primary" gutterBottom>
                              Monthly Analysis
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                              Comprehensive monthly performance report
                            </Typography>
                            <Button
                              variant="contained"
                              startIcon={<DataUsage />}
                              fullWidth
                            >
                              Generate
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
        </Paper>
      </Container>
    </Box>
  );
};

const getUnitForParameter = (parameter) => {
  const units = {
    flowRate: 'm³/h',
    pressure: 'bar',
    temperature: '°C',
    speed: 'rpm',
    power: '%',
    turbidity: 'NTU',
    flux: 'LMH',
    permeability: 'LMH/bar',
    recovery: '%'
  };
  return units[parameter] || '';
};

export default WaterTreatmentHMI;
