import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
}));

const Equalization = () => {
  return (
    <>
      <Box sx={{ 
        py: 6, 
        backgroundColor: '#f5f5f5',
        minHeight: '100vh'
      }}>
        <Container>
          <Typography variant="h2" component="h1" gutterBottom align="center" 
            sx={{ 
              color: '#1a237e',
              fontWeight: 'bold',
              mb: 4
            }}>
            Equalization System
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <StyledPaper elevation={3}>
                <Typography variant="h5" gutterBottom color="primary">
                  System Overview
                </Typography>
                <Typography paragraph>
                  Our advanced Equalization System is designed to efficiently manage and balance wastewater flow rates and compositions. The system consists of key components including:
                </Typography>
                <Box component="ul" sx={{ pl: 2 }}>
                  <Typography component="li" sx={{ mb: 1 }}>
                    ETP Inlet with Screener - For initial filtration and solid removal
                  </Typography>
                  <Typography component="li" sx={{ mb: 1 }}>
                    Lifting Sump - Efficient water transfer system with automated controls
                  </Typography>
                  <Typography component="li" sx={{ mb: 1 }}>
                    Large Equalization Tank - For optimal flow balancing and retention
                  </Typography>
                </Box>
              </StyledPaper>
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledPaper elevation={3}>
                <Box sx={{
                  width: '100%',
                  height: '300px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#f8f9fa',
                  borderRadius: 1
                }}>
                  <Typography color="text.secondary">
                    Equalization System Diagram
                  </Typography>
                </Box>
              </StyledPaper>
            </Grid>

            <Grid item xs={12}>
              <StyledPaper elevation={3}>
                <Typography variant="h5" gutterBottom color="primary">
                  Key Benefits
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="h6" gutterBottom>
                      Flow Stabilization
                    </Typography>
                    <Typography>
                      Ensures consistent flow rates to downstream treatment processes, optimizing overall system performance.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="h6" gutterBottom>
                      Load Balancing
                    </Typography>
                    <Typography>
                      Evenly distributes organic and hydraulic loads, preventing system overload and improving treatment efficiency.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="h6" gutterBottom>
                      Process Optimization
                    </Typography>
                    <Typography>
                      Enhances biological treatment effectiveness through consistent influent characteristics.
                    </Typography>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>

            <Grid item xs={12}>
              <StyledPaper elevation={3}>
                <Typography variant="h5" gutterBottom color="primary">
                  Technical Specifications
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Design Features
                    </Typography>
                    <Box component="ul" sx={{ pl: 2 }}>
                      <Typography component="li" sx={{ mb: 1 }}>
                        Advanced screening mechanism at ETP inlet for solid removal
                      </Typography>
                      <Typography component="li" sx={{ mb: 1 }}>
                        Efficient lifting sump with automated pump control
                      </Typography>
                      <Typography component="li" sx={{ mb: 1 }}>
                        Large equalization tank with proper retention time
                      </Typography>
                      <Typography component="li" sx={{ mb: 1 }}>
                        Automated level control and monitoring systems
                      </Typography>
                      <Typography component="li" sx={{ mb: 1 }}>
                        Robust construction materials for long service life
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Equalization;
