import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Divider,
  useTheme,
  alpha,
} from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SpeedIcon from '@mui/icons-material/Speed';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WaterDropIcon from '@mui/icons-material/WaterDrop';

const ProjectCard = styled(Card)(({ theme }) => ({
  background: '#ffffff',
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: 'none',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 12px 24px rgba(0, 0, 0, 0.06)',
  },
}));

const StatusBadge = styled(Box)(({ theme }) => ({
  background: alpha(theme.palette.primary.main, 0.1),
  color: theme.palette.primary.main,
  padding: '6px 12px',
  borderRadius: '4px',
  fontSize: '0.75rem',
  fontWeight: 500,
  display: 'inline-block',
}));

const DetailItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '& svg': {
    color: theme.palette.primary.main,
    fontSize: '1.25rem',
  },
}));

const projects = [
  {
    id: 1,
    title: "Kanchan Pet Bottle",
    location: "Rajasthan",
    capacity: "700 KLD RO",
    status: "Completed",
    timeline: "2024",
    description: "State-of-the-art RO system implementation with dual RO setup including main RO (700 KLD) and reject RO treatment.",
    specifications: [
      "700 KLD Main RO System",
      "Reject RO Treatment",
      "Advanced Water Recovery",
      "Automated Control System"
    ],
    image: "/images/kanchan-pet.jpg"
  },
  {
    id: 2,
    title: "Kanchan 1.3 MLD",
    location: "Rajasthan",
    capacity: "1.3 MLD",
    status: "Completed",
    timeline: "2024",
    description: "Large-scale RO system with reject RO treatment, achieving high recovery rates and optimal water quality.",
    specifications: [
      "1.3 MLD RO Capacity",
      "Reject RO System",
      "High Recovery Rate",
      "Advanced Monitoring"
    ],
    image: "/images/kanchan-1.3.jpg"
  },
  {
    id: 3,
    title: "RSL Ahmedabad",
    location: "Ahmedabad",
    capacity: "Custom",
    status: "Completed",
    timeline: "2024",
    description: "Comprehensive treatment system combining biological treatment, MBR, and RO technologies for optimal water treatment.",
    specifications: [
      "Biological Treatment",
      "MBR Technology",
      "RO System",
      "Integrated Controls"
    ],
    image: "/images/rsl.jpg"
  },
  {
    id: 4,
    title: "Siddhi Knitfab",
    location: "Ahmedabad",
    capacity: "Custom",
    status: "Completed",
    timeline: "2024",
    description: "Multi-stage treatment system incorporating biological treatment, UF, and RO technologies.",
    specifications: [
      "Biological Treatment",
      "Ultra Filtration (UF)",
      "RO System",
      "Advanced Controls"
    ],
    image: "/images/siddhi.jpg"
  },
  {
    id: 5,
    title: "Kanchan 3 MLD",
    location: "Rajasthan",
    capacity: "3 MLD",
    status: "Completed",
    timeline: "2024",
    description: "Large-scale MBR plant installation with advanced membrane technology and automated controls.",
    specifications: [
      "3 MLD Capacity",
      "MBR Technology",
      "Automated System",
      "High Efficiency"
    ],
    image: "/images/kanchan-3mld.jpg"
  },
  {
    id: 6,
    title: "LB Tex",
    location: "Ahmedabad",
    capacity: "Custom",
    status: "Completed",
    timeline: "2024",
    description: "Integrated treatment solution combining biological treatment, MBR, and RO technologies for textile industry.",
    specifications: [
      "Biological Treatment",
      "MBR System",
      "RO Technology",
      "Process Controls"
    ],
    image: "/images/lb-tex.jpg"
  },
  {
    id: 7,
    title: "Laxmi Vishal",
    location: "Rajasthan",
    capacity: "Custom",
    status: "Completed",
    timeline: "2024",
    description: "Complete water treatment solution with biological treatment, MBR, and RO systems.",
    specifications: [
      "Biological Treatment",
      "MBR Technology",
      "RO System",
      "Integrated Controls"
    ],
    image: "/images/laxmi-vishal.jpg"
  },
  {
    id: 8,
    title: "Momin Tex",
    location: "Bangladesh",
    capacity: "Custom",
    status: "Completed",
    timeline: "2024",
    description: "International project featuring advanced biological treatment system for textile industry.",
    specifications: [
      "Biological Treatment",
      "Advanced Process Control",
      "Custom Design",
      "International Standards"
    ],
    image: "/images/momin-tex.jpg"
  },
  {
    id: 9,
    title: "Sachin Infra Private Limited",
    location: "Surat",
    capacity: "2 MLD",
    status: "Completed",
    timeline: "2024",
    description: "Large-scale treatment facility with 2 MLD RO system and ceramic MBR technology.",
    specifications: [
      "2 MLD RO System",
      "Ceramic MBR",
      "Advanced Controls",
      "High Efficiency"
    ],
    image: "/images/sachin-infra.jpg"
  },
  {
    id: 10,
    title: "Sona Process 1",
    location: "Rajasthan",
    capacity: "300 KLD Reject RO",
    status: "Completed",
    timeline: "2024",
    description: "Integrated treatment system with 300 KLD reject RO system, combined with CTS, MBR, and RO technologies for optimal water recovery.",
    specifications: [
      "300 KLD Reject RO System",
      "CTS Technology",
      "MBR System",
      "Advanced Process Controls"
    ],
    image: "/images/sona-1.jpg"
  },
  {
    id: 11,
    title: "Sona Process 2",
    location: "Rajasthan",
    capacity: "300 KLD Reject RO",
    status: "Completed",
    timeline: "2024",
    description: "Second phase implementation featuring 300 KLD reject RO system along with CTS, MBR, and RO technologies for complete water treatment.",
    specifications: [
      "300 KLD Reject RO System",
      "CTS Technology",
      "MBR System",
      "Advanced Process Controls"
    ],
    image: "/images/sona-2.jpg"
  },
  {
    id: 12,
    title: "NRL",
    location: "Assam",
    capacity: "Cooling Tower",
    status: "Completed",
    timeline: "2022",
    description: "Specialized cooling tower implementation at NRL , focusing on efficient heat exchange and water conservation.",
    specifications: [
      "Industrial Cooling Tower",
      "Advanced Heat Exchange System",
      "Water Conservation Features",
      "Custom Design for Site Requirements"
    ],
    image: "/images/nrl-site.jpg"
  }
];

const Projects = () => {
  const theme = useTheme();

  const getSectionBackground = (index) => {
    const colors = [
      'linear-gradient(135deg, #ffffff 0%, #f8f9ff 100%)',
      'linear-gradient(135deg, #f5f7ff 0%, #ffffff 100%)',
      'linear-gradient(135deg, #ffffff 0%, #f7f9ff 100%)',
    ];
    return colors[index % colors.length];
  };

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {/* Hero Section */}
      <Box
        sx={{
          background: 'linear-gradient(135deg, #f0f4ff 0%, #ffffff 100%)',
          pt: { xs: 8, md: 12 },
          pb: { xs: 6, md: 8 },
        }}
      >
        <Container maxWidth="xl">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Box sx={{ maxWidth: 800, mx: 'auto', textAlign: 'center' }}>
              <WaterDropIcon 
                sx={{ 
                  fontSize: '3rem', 
                  color: theme.palette.primary.main,
                  mb: 2
                }} 
              />
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 700,
                  color: '#1a1a1a',
                  mb: 2,
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                }}
              >
                Our Projects
              </Typography>
              <Typography
                variant="h5"
                color="text.secondary"
                sx={{ 
                  mb: 4,
                  lineHeight: 1.6,
                  fontSize: { xs: '1.1rem', md: '1.25rem' },
                }}
              >
                Innovative water treatment solutions delivered with excellence and precision
              </Typography>
            </Box>
          </motion.div>
        </Container>
      </Box>

      {/* Projects Section */}
      <Box>
        {projects.map((project, index) => (
          <Box
            key={project.id}
            sx={{
              background: getSectionBackground(index),
              py: { xs: 4, md: 6 },
            }}
          >
            <Container maxWidth="xl">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4, delay: 0.1 }}
              >
                <ProjectCard>
                  <Grid container direction={index % 2 === 0 ? 'row' : 'row-reverse'}>
                    <Grid item xs={12} md={5}>
                      <Box 
                        sx={{ 
                          position: 'relative', 
                          height: '100%', 
                          minHeight: { xs: 300, md: 400 },
                          overflow: 'hidden',
                          '& img': {
                            transition: 'transform 0.6s ease-in-out',
                          },
                          '&:hover img': {
                            transform: 'scale(1.05)',
                          }
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={project.image}
                          alt={project.title}
                          sx={{
                            height: '100%',
                            objectFit: 'cover'
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <CardContent sx={{ p: { xs: 3, md: 5 }, height: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 3 }}>
                          <Box>
                            <Typography
                              variant="h3"
                              sx={{
                                fontWeight: 700,
                                color: '#1a1a1a',
                                mb: 2,
                                fontSize: { xs: '1.75rem', md: '2.25rem' },
                              }}
                            >
                              {project.title}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }}>
                              <DetailItem>
                                <LocationOnIcon />
                                <Typography variant="body1">
                                  {project.location}
                                </Typography>
                              </DetailItem>
                              <DetailItem>
                                <SpeedIcon />
                                <Typography variant="body1">
                                  {project.capacity}
                                </Typography>
                              </DetailItem>
                            </Box>
                          </Box>
                          <StatusBadge>
                            {project.status} • {project.timeline}
                          </StatusBadge>
                        </Box>

                        <Typography 
                          sx={{ 
                            color: 'text.secondary',
                            mb: 4,
                            lineHeight: 1.8,
                            fontSize: '1rem',
                          }}
                        >
                          {project.description}
                        </Typography>

                        <Divider sx={{ my: 4, borderColor: 'rgba(0, 0, 0, 0.08)' }} />

                        <Box sx={{ mb: 4 }}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 600,
                              mb: 3,
                              color: '#1a1a1a',
                              display: 'flex',
                              alignItems: 'center',
                              '&::before': {
                                content: '""',
                                width: '4px',
                                height: '24px',
                                backgroundColor: theme.palette.primary.main,
                                marginRight: '12px',
                                borderRadius: '2px'
                              }
                            }}
                          >
                            Technical Specifications
                          </Typography>
                          <Grid container spacing={3}>
                            {project.specifications.map((spec, idx) => (
                              <Grid item xs={12} md={6} key={idx}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: 2,
                                    borderRadius: '8px',
                                    backgroundColor: alpha(theme.palette.primary.main, 0.04),
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                                      transform: 'translateX(4px)'
                                    }
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: '8px',
                                      height: '8px',
                                      borderRadius: '50%',
                                      backgroundColor: theme.palette.primary.main,
                                      mr: 2,
                                      flexShrink: 0
                                    }}
                                  />
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: 'text.primary',
                                      fontWeight: 500,
                                      fontSize: '0.95rem'
                                    }}
                                  >
                                    {spec}
                                  </Typography>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>

                        <Button
                          variant="outlined"
                          color="primary"
                          endIcon={<ArrowForwardIcon />}
                          sx={{ 
                            py: 1.5,
                            px: 3,
                            textTransform: 'none',
                            borderWidth: '1.5px',
                            fontSize: '0.95rem',
                            '&:hover': {
                              borderWidth: '1.5px',
                              backgroundColor: theme.palette.primary.main,
                              color: '#fff'
                            }
                          }}
                        >
                          View Project Details
                        </Button>
                      </CardContent>
                    </Grid>
                  </Grid>
                </ProjectCard>
              </motion.div>
            </Container>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Projects;
