import React from 'react';
import { Box, Container, Grid, Typography, Card, Button } from '@mui/material';
import { Storage, CloudQueue, Analytics, BarChart } from '@mui/icons-material';

const demoLinks = [
  {
    title: 'Data Analytics Platform',
    description: 'Big data analytics and visualization tools',
    url: 'https://demo.cloud.example/analytics',
    icon: <Analytics />,
  },
  {
    title: 'ML Pipeline Demo',
    description: 'Machine learning workflow demonstration',
    url: 'https://demo.cloud.example/ml-pipeline',
    icon: <BarChart />,
  },
  {
    title: 'Cloud Infrastructure',
    description: 'Cloud resource management dashboard',
    url: 'https://demo.cloud.example/infrastructure',
    icon: <CloudQueue />,
  }
];

const technicalSpecs = [
  {
    category: 'Platform Performance',
    specs: [
      { label: 'Storage Capacity', value: 'Petabyte Scale' },
      { label: 'Processing Rate', value: '1M events/sec' },
      { label: 'System Availability', value: '99.99%' },
      { label: 'Backup Frequency', value: 'Real-time' }
    ]
  },
  {
    category: 'Computing Resources',
    specs: [
      { label: 'CPU Cores', value: 'Up to 256 cores' },
      { label: 'GPU Support', value: 'NVIDIA Tesla V100' },
      { label: 'Memory', value: 'Up to 2TB RAM' },
      { label: 'Network', value: '100 Gbps' }
    ]
  }
];

const features = [
  {
    title: 'Big Data Processing',
    description: 'Distributed processing for large-scale data',
    details: [
      'Stream processing',
      'Batch processing',
      'Data warehousing',
      'Data lakes'
    ]
  },
  {
    title: 'Machine Learning',
    description: 'End-to-end ML pipeline management',
    details: [
      'Model training',
      'Model deployment',
      'Version control',
      'Performance monitoring'
    ]
  },
  {
    title: 'Data Management',
    description: 'Comprehensive data lifecycle management',
    details: [
      'Data ingestion',
      'Data transformation',
      'Data governance',
      'Data security'
    ]
  }
];

const CloudDemo = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        {/* Header Section */}
        <Box sx={{ mb: 8, textAlign: 'center' }}>
          <Storage sx={{ fontSize: 60, color: '#673AB7', mb: 2 }} />
          <Typography variant="h2" gutterBottom>
            Cloud Platform Demo
          </Typography>
          <Typography variant="h5" color="text.secondary" sx={{ mb: 4 }}>
            Scalable cloud infrastructure for data processing and analytics
          </Typography>
        </Box>

        {/* Demo Links Section */}
        <Typography variant="h3" gutterBottom sx={{ mb: 4 }}>
          Interactive Demos
        </Typography>
        <Grid container spacing={4} sx={{ mb: 8 }}>
          {demoLinks.map((demo, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ 
                p: 3, 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'translateY(-8px)'
                }
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  {React.cloneElement(demo.icon, { sx: { fontSize: 40, color: '#673AB7', mr: 2 } })}
                  <Typography variant="h6">{demo.title}</Typography>
                </Box>
                <Typography color="text.secondary" sx={{ mb: 3, flex: 1 }}>
                  {demo.description}
                </Typography>
                <Button 
                  variant="contained" 
                  href={demo.url}
                  target="_blank"
                  sx={{ 
                    bgcolor: '#673AB7',
                    '&:hover': {
                      bgcolor: '#512DA8'
                    }
                  }}
                >
                  View Demo
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Technical Specifications */}
        <Typography variant="h3" gutterBottom sx={{ mb: 4 }}>
          Technical Specifications
        </Typography>
        <Grid container spacing={4} sx={{ mb: 8 }}>
          {technicalSpecs.map((category, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{ p: 3 }}>
                <Typography variant="h5" gutterBottom color="primary">
                  {category.category}
                </Typography>
                <Grid container spacing={2}>
                  {category.specs.map((spec, idx) => (
                    <Grid item xs={12} key={idx}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
                        <Typography variant="body1" color="text.secondary">
                          {spec.label}
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                          {spec.value}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Features Section */}
        <Typography variant="h3" gutterBottom sx={{ mb: 4 }}>
          Key Features
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ p: 3, height: '100%' }}>
                <Typography variant="h5" gutterBottom color="primary">
                  {feature.title}
                </Typography>
                <Typography color="text.secondary" paragraph>
                  {feature.description}
                </Typography>
                <Box component="ul" sx={{ pl: 2 }}>
                  {feature.details.map((detail, idx) => (
                    <Typography component="li" key={idx} sx={{ mb: 1 }}>
                      {detail}
                    </Typography>
                  ))}
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default CloudDemo;
