import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import {
  Close as CloseIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

const JobDialog = ({
  open,
  onClose,
  job,
  onSave,
  mode = 'add'
}) => {
  const [formData, setFormData] = React.useState({
    title: '',
    department: '',
    location: '',
    type: '',
    experience: '',
    education: '',
    responsibilities: [''],
    requirements: [''],
    ...job
  });

  React.useEffect(() => {
    if (job) {
      setFormData({
        title: '',
        department: '',
        location: '',
        type: '',
        experience: '',
        education: '',
        responsibilities: [''],
        requirements: [''],
        ...job
      });
    }
  }, [job]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddItem = (field) => {
    setFormData(prev => ({
      ...prev,
      [field]: [...prev[field], '']
    }));
  };

  const handleRemoveItem = (field, index) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index)
    }));
  };

  const handleItemChange = (field, index, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].map((item, i) => i === index ? value : item)
    }));
  };

  const handleSubmit = () => {
    // Filter out empty items
    const cleanedData = {
      ...formData,
      responsibilities: formData.responsibilities.filter(r => r.trim() !== ''),
      requirements: formData.requirements.filter(r => r.trim() !== '')
    };
    onSave(cleanedData);
  };

  const renderListItems = (field, title) => (
    <>
      <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
        {title}
      </Typography>
      <List dense>
        {formData[field].map((item, index) => (
          <ListItem key={index} sx={{ py: 1 }}>
            <ListItemText>
              <TextField
                fullWidth
                value={item}
                onChange={(e) => handleItemChange(field, index, e.target.value)}
                placeholder={`Enter ${title.toLowerCase()}`}
                size="small"
              />
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={() => handleRemoveItem(field, index)}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        <ListItem>
          <Button
            startIcon={<AddIcon />}
            onClick={() => handleAddItem(field)}
            size="small"
          >
            Add {title}
          </Button>
        </ListItem>
      </List>
    </>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {mode === 'add' ? 'Add New Job' : 'Edit Job'}
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              name="title"
              label="Job Title"
              value={formData.title}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              name="department"
              label="Department"
              value={formData.department}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              name="location"
              label="Location"
              value={formData.location}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              name="type"
              label="Job Type"
              value={formData.type}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              name="experience"
              label="Experience Required"
              value={formData.experience}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              name="education"
              label="Education Required"
              value={formData.education}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            {renderListItems('responsibilities', 'Responsibilities')}
          </Grid>
          <Grid item xs={12}>
            {renderListItems('requirements', 'Requirements')}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained"
          disabled={
            !formData.title ||
            !formData.department ||
            !formData.location ||
            !formData.type ||
            !formData.experience ||
            !formData.education
          }
        >
          {mode === 'add' ? 'Add Job' : 'Save Changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JobDialog;
