import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Solutions from './pages/Solutions';
import Technology from './pages/Technology';
import Contact from './pages/Contact';
import Industries from './pages/Industries';
import Careers from './pages/Careers';
import CareerDashboard from './pages/admin/CareerDashboard';
import Products from './pages/Products';
import Projects from './pages/Projects';
import Gallery from './pages/Gallery';

// Solutions Pages
import ScadaDemo from './pages/system/ScadaDemo';
import IoTDemo from './pages/system/IoTDemo';
import CloudDemo from './pages/system/CloudDemo';
import Equalization from './pages/system/Equalization';
import RoTreatment from './pages/system/RoTreatment';
import WaterTreatmentHMI from './pages/system/WaterTreatmentHMI';
import WaterTreatmentLogin from './pages/system/WaterTreatmentLogin';

// Demo Pages
import AIFeatures from './pages/demos/AIFeatures';
import WaterTreatment from './pages/demos/WaterTreatment';
import ProcessControl from './pages/demos/ProcessControl';
import NeuralNetworks from './pages/demos/NeuralNetworks';
import SmartMonitoring from './pages/demos/SmartMonitoring';
import PredictiveAnalytics from './pages/demos/PredictiveAnalytics';

// Treatment Pages
import TreatmentProcessControl from './pages/treatment/ProcessControl';
import WaterTreatmentPage from './pages/demos/WaterTreatment';
import ControlSystemsPage from './pages/demos/ProcessControl';
import { CareerProvider } from './context/CareerContext';
import CookieConsent from './components/CookieConsent';

const theme = createTheme({
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    primary: {
      main: '#00C6CF',
      light: '#4FACFE',
      dark: '#0099A1',
    },
    text: {
      primary: '#1a1a1a',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CareerProvider>
        <Router>
          <Box sx={{ 
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Navbar />
            <Box component="main" sx={{ flex: 1 }}>
              <Routes>
                {/* Main Routes */}
                <Route path="/" element={<Home />} />
                
                {/* Career Routes */}
                <Route path="/careers" element={<Careers />} />
                <Route path="/admin/careers" element={<CareerDashboard />} />
                
                {/* Technology Routes */}
                <Route path="/technologies" element={<Technology />} />
                <Route path="/technologies/ai" element={<AIFeatures />} />
                <Route path="/technologies/neural-networks" element={<NeuralNetworks />} />
                <Route path="/technologies/smart-monitoring" element={<SmartMonitoring />} />
                <Route path="/technologies/predictive-analytics" element={<PredictiveAnalytics />} />

                {/* Solutions Routes */}
                <Route path="/solutions" element={<Solutions />} />
                <Route path="/solutions/scada" element={<ScadaDemo />} />
                <Route path="/solutions/iot" element={<IoTDemo />} />
                <Route path="/solutions/cloud" element={<CloudDemo />} />
                <Route path="/solutions/equalization" element={<Equalization />} />
                <Route path="/solutions/ro-treatment" element={<RoTreatment />} />
                <Route path="/equalization" element={<Equalization />} />
                <Route path="/water-treatment-login" element={<WaterTreatmentLogin />} />
                <Route path="/water-treatment-hmi" element={<WaterTreatmentHMI />} />

                {/* Treatment Routes */}
                <Route path="/treatment/water" element={<WaterTreatmentPage />} />
                <Route path="/treatment/control-systems" element={<ControlSystemsPage />} />
                <Route path="/treatment/process-control" element={<TreatmentProcessControl />} />

                {/* New Routes */}
                <Route path="/contact" element={<Contact />} />
                <Route path="/industries" element={<Industries />} />
                <Route path="/products" element={<Products />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/gallery" element={<Gallery />} />
              </Routes>
            </Box>
            <Footer />
          </Box>
        </Router>
      </CareerProvider>
      <CookieConsent />
    </ThemeProvider>
  );
}

export default App;
