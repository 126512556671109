import React, { useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  Box,
  Tabs,
  Tab,
  Dialog,
  IconButton,
  useTheme,
  useMediaQuery,
  Fade,
} from '@mui/material';
import {
  Close,
  NavigateBefore,
  NavigateNext,
} from '@mui/icons-material';

const galleryItems = {
  'Projects': [
    {
      id: 1,
      title: "Water Treatment Plant - Chennai",
      image: "/images/gallery/project1.jpg",
      description: "State-of-the-art water treatment facility",
      category: "Projects",
    },
    {
      id: 2,
      title: "Municipal Project - Bangalore",
      image: "/images/gallery/project2.jpg",
      description: "Municipal water purification system",
      category: "Projects",
    },
  ],
  'Installations': [
    {
      id: 3,
      title: "SmartPure Pro Installation",
      image: "/images/gallery/installation1.jpg",
      description: "Industrial installation of SmartPure Pro",
      category: "Installations",
    },
    {
      id: 4,
      title: "EcoPure X1 Setup",
      image: "/images/gallery/installation2.jpg",
      description: "Commercial installation of EcoPure X1",
      category: "Installations",
    },
  ],
  'Events': [
    {
      id: 5,
      title: "Technology Summit 2024",
      image: "/images/gallery/event1.jpg",
      description: "Annual water technology summit",
      category: "Events",
    },
    {
      id: 6,
      title: "Product Launch",
      image: "/images/gallery/event2.jpg",
      description: "Launch of new water treatment systems",
      category: "Events",
    },
  ],
};

const Gallery = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const categories = Object.keys(galleryItems);

  const styles = {
    pageTitle: {
      textAlign: 'center',
      fontWeight: 700,
      fontSize: { xs: '2rem', md: '3rem' },
      mb: 2,
      background: 'linear-gradient(45deg, #1a237e 30%, #0288d1 90%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    subtitle: {
      textAlign: 'center',
      color: '#546e7a',
      mb: 6,
      maxWidth: '800px',
      mx: 'auto',
    },
    galleryCard: {
      position: 'relative',
      borderRadius: '16px',
      overflow: 'hidden',
      cursor: 'pointer',
      '&:hover': {
        '& .MuiCardMedia-root': {
          transform: 'scale(1.05)',
        },
        '& .overlay': {
          opacity: 1,
        },
      },
    },
    galleryImage: {
      height: 280,
      transition: 'transform 0.3s ease',
    },
    overlay: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      bgcolor: 'rgba(0, 0, 0, 0.7)',
      color: 'white',
      p: 2,
      opacity: 0,
      transition: 'opacity 0.3s ease',
    },
    modalImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    navigationButton: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      bgcolor: 'rgba(255, 255, 255, 0.8)',
      '&:hover': {
        bgcolor: 'rgba(255, 255, 255, 0.9)',
      },
    },
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const handlePrevious = () => {
    const currentCategory = galleryItems[categories[currentTab]];
    const currentIndex = currentCategory.findIndex(item => item.id === selectedImage.id);
    const previousIndex = (currentIndex - 1 + currentCategory.length) % currentCategory.length;
    setSelectedImage(currentCategory[previousIndex]);
  };

  const handleNext = () => {
    const currentCategory = galleryItems[categories[currentTab]];
    const currentIndex = currentCategory.findIndex(item => item.id === selectedImage.id);
    const nextIndex = (currentIndex + 1) % currentCategory.length;
    setSelectedImage(currentCategory[nextIndex]);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      {/* Hero Section */}
      <Typography variant="h2" sx={styles.pageTitle}>
        Gallery
      </Typography>
      <Typography variant="h6" sx={styles.subtitle}>
        Explore our projects, installations, and events through images
      </Typography>

      {/* Category Tabs */}
      <Box sx={{ mb: 4 }}>
        <Tabs
          value={currentTab}
          onChange={(e, newValue) => setCurrentTab(newValue)}
          centered
          textColor="primary"
          indicatorColor="primary"
          sx={{
            '& .MuiTab-root': {
              fontSize: '1.1rem',
              fontWeight: 500,
            },
          }}
        >
          {categories.map((category, index) => (
            <Tab key={index} label={category} />
          ))}
        </Tabs>
      </Box>

      {/* Gallery Grid */}
      <Grid container spacing={3}>
        {galleryItems[categories[currentTab]].map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Fade in={true} timeout={500}>
              <Card 
                sx={styles.galleryCard} 
                onClick={() => handleImageClick(item)}
              >
                <CardMedia
                  component="img"
                  sx={styles.galleryImage}
                  image={item.image}
                  alt={item.title}
                />
                <Box className="overlay" sx={styles.overlay}>
                  <Typography variant="h6" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2">
                    {item.description}
                  </Typography>
                </Box>
              </Card>
            </Fade>
          </Grid>
        ))}
      </Grid>

      {/* Image Modal */}
      <Dialog
        fullScreen={fullScreen}
        open={Boolean(selectedImage)}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <Box sx={{ position: 'relative', bgcolor: 'black', height: '80vh' }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
          
          <IconButton
            onClick={handlePrevious}
            sx={{
              ...styles.navigationButton,
              left: 8,
            }}
          >
            <NavigateBefore />
          </IconButton>

          {selectedImage && (
            <Box
              component="img"
              src={selectedImage.image}
              alt={selectedImage.title}
              sx={styles.modalImage}
            />
          )}

          <IconButton
            onClick={handleNext}
            sx={{
              ...styles.navigationButton,
              right: 8,
            }}
          >
            <NavigateNext />
          </IconButton>

          {selectedImage && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                bgcolor: 'rgba(0, 0, 0, 0.7)',
                color: 'white',
                p: 2,
              }}
            >
              <Typography variant="h6" gutterBottom>
                {selectedImage.title}
              </Typography>
              <Typography variant="body2">
                {selectedImage.description}
              </Typography>
            </Box>
          )}
        </Box>
      </Dialog>
    </Container>
  );
};

export default Gallery;
