import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Collapse,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';

const pages = [
  { name: 'Home', path: '/' },
  {
    name: 'Solutions',
    items: [
      { name: 'SCADA System', path: '/solutions/scada' },
      { name: 'IoT Platform', path: '/solutions/iot' },
      { name: 'Cloud Services', path: '/solutions/cloud' },
    ],
  },
  {
    name: 'AI Technologies',
    items: [
      { name: 'AI Features', path: '/technologies/ai' },
      { name: 'Neural Networks', path: '/technologies/neural-networks' },
      { name: 'Smart Monitoring', path: '/technologies/smart-monitoring' },
      { name: 'Predictive Analytics', path: '/technologies/predictive-analytics' },
    ],
  },
  {
    name: 'Treatment',
    items: [
      { name: 'Process Control', path: '/treatment/process-control' },
      { name: 'Water Treatment', path: '/treatment/water' },
      { name: 'Process Control Systems', path: '/treatment/control-systems' },
      { name: 'RO Treatment System', path: '/solutions/ro-treatment' },
    ],
  },
  { name: 'Projects', path: '/projects' },
  { name: 'Products', path: '/products' },
  { name: 'Gallery', path: '/gallery' },
  { name: 'Industries', path: '/industries' },
  { name: 'Careers', path: '/careers' },
  { name: 'Contact', path: '/contact' },
];

const Navbar = () => {
  useEffect(() => {
    // Add Orbitron font
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState({});
  const [dropdownStates, setDropdownStates] = useState({});
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navbarStyles = {
    appBar: {
      backgroundColor: 'white',
      boxShadow: '0 2px 5px rgba(0,0,0,0.05)',
      borderBottom: '1px solid rgba(0,0,0,0.06)'
    },
    logo: {
      color: theme.palette.primary.main,
      fontWeight: 700,
      textDecoration: 'none',
      fontSize: '1.5rem',
    },
    menuButton: {
      color: theme.palette.primary.main,
    },
    navButton: {
      mx: 1,
      color: '#333',
      fontWeight: 500,
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
      },
    },
    dropdown: {
      mt: '45px',
      borderRadius: '8px',
      boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
    },
    menuItem: {
      minWidth: '200px',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
    drawer: {
      width: 240,
      '& .MuiDrawer-paper': {
        width: 240,
        boxSizing: 'border-box',
      },
    },
    listItem: {
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDropdownOpen = (event, pageName) => {
    event.preventDefault();
    setDropdownStates(prev => ({
      ...prev,
      [pageName]: event.currentTarget
    }));
  };

  const handleDropdownClose = (pageName) => {
    setDropdownStates(prev => ({
      ...prev,
      [pageName]: null
    }));
  };

  const handleSubMenuClick = (pageName) => {
    setOpenSubMenus(prev => ({
      ...prev,
      [pageName]: !prev[pageName]
    }));
  };

  const drawer = (
    <List>
      {pages.map((page) => (
        <React.Fragment key={page.name}>
          {page.items ? (
            <>
              <ListItemButton onClick={() => handleSubMenuClick(page.name)} sx={navbarStyles.listItem}>
                <ListItemText primary={page.name} />
                {openSubMenus[page.name] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openSubMenus[page.name]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {page.items.map((item) => (
                    <ListItemButton
                      key={item.name}
                      component={Link}
                      to={item.path}
                      onClick={handleDrawerToggle}
                      sx={{ pl: 4, ...navbarStyles.listItem }}
                    >
                      <ListItemText primary={item.name} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </>
          ) : (
            <ListItemButton
              key={page.name}
              component={Link}
              to={page.path}
              onClick={handleDrawerToggle}
              sx={navbarStyles.listItem}
            >
              <ListItemText primary={page.name} />
            </ListItemButton>
          )}
        </React.Fragment>
      ))}
    </List>
  );

  return (
    <AppBar 
      position="sticky" 
      sx={{ 
        backgroundColor: 'white',
        boxShadow: '0 2px 5px rgba(0,0,0,0.05)',
        borderBottom: '1px solid rgba(0,0,0,0.06)'
      }}
    >
      <Container maxWidth="xl">
        <Toolbar 
          disableGutters 
          sx={{ 
            minHeight: '80px',
            px: { xs: 2, md: 0 }
          }}
        >
          {/* Logo for desktop */}
          <Box 
            sx={{ 
              display: { xs: 'none', md: 'flex' }, 
              mr: 4,
              alignItems: 'center'
            }}
          >
            <Link to="/">
              <img
                src="/images/ani.gif"
                alt="Logo"
                style={{
                  height: '50px',
                  objectFit: 'contain'
                }}
              />
            </Link>
          </Box>

          {/* Mobile menu */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{ 
                color: '#333',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.03)'
                }
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          {/* Logo for mobile */}
          <Box 
            sx={{ 
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Link to="/">
              <img
                src="/images/ani.gif"
                alt="Logo"
                style={{
                  height: '40px',
                  objectFit: 'contain'
                }}
              />
            </Link>
          </Box>

          {/* Desktop menu */}
          <Box 
            sx={{ 
              flexGrow: 1, 
              display: { xs: 'none', md: 'flex' }, 
              justifyContent: 'center',
              gap: 1
            }}
          >
            {pages.map((page) => (
              page.items ? (
                <Box key={page.name} sx={{ position: 'relative' }}>
                  <Button
                    onClick={(e) => handleDropdownOpen(e, page.name)}
                    onMouseEnter={(e) => handleDropdownOpen(e, page.name)}
                    sx={{
                      color: '#2C3E50',
                      px: 2,
                      py: 3.5,
                      fontSize: '0.95rem',
                      fontWeight: 500,
                      textTransform: 'none',
                      transition: 'all 0.2s ease',
                      borderBottom: '3px solid transparent',
                      borderRadius: 0,
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: '#2563EB',
                        borderBottom: '3px solid #2563EB',
                      },
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '3px',
                        backgroundColor: 'transparent',
                        transition: 'all 0.2s ease'
                      }
                    }}
                    endIcon={
                      <KeyboardArrowDownIcon 
                        sx={{ 
                          transition: 'transform 0.2s ease',
                          transform: Boolean(dropdownStates[page.name]) ? 'rotate(180deg)' : 'rotate(0deg)'
                        }} 
                      />
                    }
                  >
                    {page.name}
                  </Button>
                  <Menu
                    anchorEl={dropdownStates[page.name]}
                    open={Boolean(dropdownStates[page.name])}
                    onClose={() => handleDropdownClose(page.name)}
                    MenuListProps={{
                      onMouseLeave: () => handleDropdownClose(page.name),
                      sx: { py: 0 }
                    }}
                    sx={{
                      '& .MuiPaper-root': {
                        mt: 0,
                        minWidth: 220,
                        boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                        borderRadius: '8px',
                        border: '1px solid rgba(0,0,0,0.08)',
                        overflow: 'hidden'
                      }
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    {page.items.map((item, index) => (
                      <MenuItem
                        key={item.name}
                        component={Link}
                        to={item.path}
                        onClick={() => handleDropdownClose(page.name)}
                        sx={{
                          py: 1.8,
                          px: 2.5,
                          borderBottom: index !== page.items.length - 1 ? '1px solid rgba(0,0,0,0.06)' : 'none',
                          fontSize: '0.9rem',
                          color: '#4B5563',
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            backgroundColor: '#F8FAFC',
                            color: '#2563EB',
                            transform: 'translateX(5px)'
                          }
                        }}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              ) : (
                <Button
                  key={page.name}
                  component={Link}
                  to={page.path}
                  sx={{
                    color: '#2C3E50',
                    px: 2,
                    py: 3.5,
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    textTransform: 'none',
                    transition: 'all 0.2s ease',
                    borderBottom: '3px solid transparent',
                    borderRadius: 0,
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: '#2563EB',
                      borderBottom: '3px solid #2563EB'
                    }
                  }}
                >
                  {page.name}
                </Button>
              )
            ))}
          </Box>

          {/* Right side items */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button
              variant="contained"
              component={Link}
              to="/careers"
              sx={{
                bgcolor: '#2563EB',
                color: 'white',
                px: 3,
                py: 1,
                fontSize: '0.9rem',
                fontWeight: 600,
                textTransform: 'none',
                borderRadius: '6px',
                boxShadow: '0 2px 4px rgba(37,99,235,0.2)',
                transition: 'all 0.2s ease',
                '&:hover': {
                  bgcolor: '#1D4ED8',
                  transform: 'translateY(-1px)',
                  boxShadow: '0 4px 8px rgba(37,99,235,0.3)'
                }
              }}
            >
              Careers
            </Button>
          </Box>
        </Toolbar>
      </Container>

      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: 280,
            boxSizing: 'border-box',
            bgcolor: 'white',
            boxShadow: '4px 0 8px rgba(0,0,0,0.05)'
          }
        }}
      >
        <Box sx={{ p: 3 }}>
          <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
            <img
              src="/images/ani.gif"
              alt="Logo"
              style={{
                height: '40px',
                objectFit: 'contain'
              }}
            />
          </Box>
          {pages.map((page) => (
            <Box key={page.name} sx={{ mb: 3 }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: 2,
                  color: '#2C3E50',
                  fontSize: '1rem',
                  fontWeight: 600
                }}
              >
                {page.name}
              </Typography>
              {page.items && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {page.items.map((item) => (
                    <Button
                      key={item.name}
                      component={Link}
                      to={item.path}
                      onClick={handleDrawerToggle}
                      sx={{
                        justifyContent: 'flex-start',
                        color: '#4B5563',
                        textTransform: 'none',
                        fontSize: '0.9rem',
                        py: 0.8,
                        '&:hover': {
                          backgroundColor: 'transparent',
                          color: '#2563EB'
                        }
                      }}
                    >
                      {item.name}
                    </Button>
                  ))}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
