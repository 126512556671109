import React from 'react';
import { Box, Container, Grid, Typography, Card, Button } from '@mui/material';
import { Psychology, Timeline, Speed, Assessment } from '@mui/icons-material';

const demoSections = [
  {
    title: "Water Quality Prediction",
    description: "Real-time prediction of water quality parameters using deep learning",
    demoUrl: "https://demo.waterai.example/quality-prediction",
    icon: <Psychology />,
    metrics: {
      accuracy: "99.9%",
      responseTime: "<100ms",
      parameters: "20+ monitored",
      reliability: "99.99%"
    }
  },
  {
    title: "Process Optimization",
    description: "Neural network-based optimization of treatment processes",
    demoUrl: "https://demo.waterai.example/process-optimization",
    icon: <Speed />,
    metrics: {
      efficiency: "45% improved",
      energySaving: "35%",
      chemicalReduction: "30%",
      uptime: "99.9%"
    }
  },
  {
    title: "Performance Analysis",
    description: "Advanced analytics and performance monitoring",
    demoUrl: "https://demo.waterai.example/performance",
    icon: <Assessment />,
    metrics: {
      coverage: "100%",
      prediction: "95% accurate",
      insights: "Real-time",
      reporting: "Automated"
    }
  },
  {
    title: "Trend Analysis",
    description: "Historical data analysis and trend prediction",
    demoUrl: "https://demo.waterai.example/trends",
    icon: <Timeline />,
    metrics: {
      dataPoints: "1M+/day",
      forecast: "7 days ahead",
      accuracy: "95%",
      updates: "Real-time"
    }
  }
];

const NeuralNetworks = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        {/* Header */}
        <Box sx={{ mb: 8, textAlign: 'center' }}>
          <Psychology sx={{ fontSize: 60, color: '#2196F3', mb: 2 }} />
          <Typography variant="h2" gutterBottom>
            Neural Networks Demo
          </Typography>
          <Typography variant="h5" color="text.secondary" sx={{ mb: 4 }}>
            Experience our advanced AI capabilities in action
          </Typography>
        </Box>

        {/* Demo Sections */}
        <Grid container spacing={4}>
          {demoSections.map((section, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{ 
                p: 3,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: '0 20px 40px rgba(33, 150, 243, 0.15)'
                }
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  {React.cloneElement(section.icon, { 
                    sx: { fontSize: 40, color: '#2196F3', mr: 2 } 
                  })}
                  <Typography variant="h5">{section.title}</Typography>
                </Box>
                <Typography color="text.secondary" sx={{ mb: 3 }}>
                  {section.description}
                </Typography>

                {/* Metrics */}
                <Box sx={{ mb: 3, flex: 1 }}>
                  <Grid container spacing={2}>
                    {Object.entries(section.metrics).map(([key, value], idx) => (
                      <Grid item xs={6} key={idx}>
                        <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
                          {key.replace(/([A-Z])/g, ' $1').trim()}:
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                          {value}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                <Button 
                  variant="contained"
                  href={section.demoUrl}
                  target="_blank"
                  sx={{ 
                    bgcolor: '#2196F3',
                    '&:hover': {
                      bgcolor: '#1976D2'
                    }
                  }}
                >
                  Launch Demo
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default NeuralNetworks;
