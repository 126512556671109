import React from 'react';
import { Box, Container, Grid, Typography, Card, Button } from '@mui/material';
import { SmartToy, Psychology, Biotech, Speed } from '@mui/icons-material';

const demoLinks = [
  {
    title: 'Neural Network Visualization',
    description: 'Interactive visualization of our AI models in action',
    url: 'https://demo.aiwater.example/neural-network',
    icon: <Psychology />,
    color: '#2196F3'
  },
  {
    title: 'Process Optimization Demo',
    description: 'Real-time optimization of water treatment processes',
    url: 'https://demo.aiwater.example/optimization',
    icon: <Speed />,
    color: '#4CAF50'
  },
  {
    title: 'Predictive Analytics',
    description: 'Future performance prediction and analysis',
    url: 'https://demo.aiwater.example/predictive',
    icon: <SmartToy />,
    color: '#FF9800'
  },
  {
    title: 'Smart Monitoring',
    description: 'IoT-enabled real-time monitoring system',
    url: 'https://demo.aiwater.example/monitoring',
    icon: <Biotech />,
    color: '#9C27B0'
  }
];

const performanceMetrics = [
  {
    title: 'Treatment Efficiency',
    value: '99.9%',
    description: 'Consistent high-quality water treatment'
  },
  {
    title: 'Energy Savings',
    value: '45%',
    description: 'Reduced energy consumption through AI optimization'
  },
  {
    title: 'Chemical Reduction',
    value: '35%',
    description: 'Lower chemical usage with smart dosing'
  },
  {
    title: 'Maintenance Prediction',
    value: '95%',
    description: 'Accurate prediction of maintenance needs'
  }
];

const AIFeatures = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        {/* Header */}
        <Box sx={{ mb: 8, textAlign: 'center' }}>
          <SmartToy sx={{ fontSize: 60, color: '#00C6CF', mb: 2 }} />
          <Typography variant="h2" gutterBottom>
            AI Features Demo
          </Typography>
          <Typography variant="h5" color="text.secondary" sx={{ mb: 4 }}>
            Experience our advanced AI capabilities in action
          </Typography>
        </Box>

        {/* Demo Links */}
        <Typography variant="h3" gutterBottom sx={{ mb: 4 }}>
          Interactive Demos
        </Typography>
        <Grid container spacing={4} sx={{ mb: 8 }}>
          {demoLinks.map((demo, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{ 
                p: 3,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: `0 20px 40px ${demo.color}15`
                }
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  {React.cloneElement(demo.icon, { sx: { fontSize: 40, color: demo.color, mr: 2 } })}
                  <Typography variant="h5">{demo.title}</Typography>
                </Box>
                <Typography color="text.secondary" sx={{ mb: 3, flex: 1 }}>
                  {demo.description}
                </Typography>
                <Button 
                  variant="contained" 
                  href={demo.url}
                  target="_blank"
                  sx={{ 
                    bgcolor: demo.color,
                    '&:hover': {
                      bgcolor: demo.color,
                      opacity: 0.9
                    }
                  }}
                >
                  Launch Demo
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Performance Metrics */}
        <Typography variant="h3" gutterBottom sx={{ mb: 4 }}>
          Performance Metrics
        </Typography>
        <Grid container spacing={4}>
          {performanceMetrics.map((metric, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Card sx={{ 
                p: 3,
                height: '100%',
                textAlign: 'center',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'translateY(-8px)'
                }
              }}>
                <Typography variant="h2" color="primary" sx={{ mb: 2 }}>
                  {metric.value}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {metric.title}
                </Typography>
                <Typography color="text.secondary">
                  {metric.description}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default AIFeatures;
