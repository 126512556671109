import React from 'react';
import { Box, Container, Typography, Grid, Button, Stack, Card, CardContent, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import TimelineIcon from '@mui/icons-material/Timeline';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import SensorsIcon from '@mui/icons-material/Sensors';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import BiotechIcon from '@mui/icons-material/Biotech';
import Psychology from '@mui/icons-material/Psychology';
import RemoveRedEye from '@mui/icons-material/RemoveRedEye';
import Build from '@mui/icons-material/Build';
import Speed from '@mui/icons-material/Speed';
import Science from '@mui/icons-material/Science';
import Settings from '@mui/icons-material/Settings';
import Cloud from '@mui/icons-material/Cloud';
import DataUsage from '@mui/icons-material/DataUsage';
import Router from '@mui/icons-material/Router';
import Storage from '@mui/icons-material/Storage';
import TrendingUp from '@mui/icons-material/TrendingUp';
import { Link as RouterLink } from 'react-router-dom';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: '90vh',
  display: 'flex',
  alignItems: 'center',
  background: 'linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%)',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'radial-gradient(circle at 30% 30%, rgba(0, 198, 207, 0.08) 0%, rgba(255,255,255,0) 70%)',
    zIndex: 0,
  },
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(45deg, #00C6CF, #4FACFE)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 700,
}));

const FeatureCard = styled(motion.div)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  borderRadius: '20px',
  padding: theme.spacing(4),
  border: '1px solid rgba(0, 198, 207, 0.1)',
  transition: 'all 0.3s ease',
  height: '100%',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 20px 40px rgba(0, 198, 207, 0.15)',
    border: '1px solid rgba(0, 198, 207, 0.3)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #00C6CF 30%, #4FACFE 90%)',
  color: 'white',
  padding: '12px 32px',
  borderRadius: '30px',
  textTransform: 'none',
  fontSize: '1.1rem',
  fontWeight: 600,
  boxShadow: '0 3px 15px rgba(0, 198, 207, 0.3)',
  '&:hover': {
    background: 'linear-gradient(45deg, #4FACFE 30%, #00C6CF 90%)',
    transform: 'translateY(-2px)',
    boxShadow: '0 5px 20px rgba(0, 198, 207, 0.4)',
  },
}));

const StatCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  borderRadius: '20px',
  border: '1px solid rgba(0, 198, 207, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 15px 30px rgba(0, 198, 207, 0.15)',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  borderRadius: '20px',
  border: '1px solid rgba(0, 198, 207, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 20px 40px rgba(0, 198, 207, 0.15)',
  },
}));

const iconColors = {
  mlModels: '#2196F3', // Blue
  computerVision: '#4CAF50', // Green
  predictive: '#FF9800', // Orange
  process: '#9C27B0', // Purple
  physical: '#00BCD4', // Cyan
  chemical: '#F44336', // Red
  treatment: '#3F51B5', // Indigo
  scada: '#00C6CF', // Teal
  iot: '#E91E63', // Pink
  cloud: '#7C4DFF' // Deep Purple
};

const features = [
  {
    icon: <Psychology sx={{ fontSize: 40, color: '#2196F3' }} />,
    title: 'Neural Networks',
    description: 'Advanced neural networks for water treatment optimization',
    demoPath: '/demos/neural-networks'
  },
  {
    icon: <SensorsIcon sx={{ fontSize: 40, color: '#4CAF50' }} />,
    title: 'Smart Monitoring',
    description: 'IoT-enabled monitoring system with real-time analytics',
    demoPath: '/demos/smart-monitoring'
  },
  {
    icon: <TrendingUp sx={{ fontSize: 40, color: '#FF9800' }} />,
    title: 'Predictive Analytics',
    description: 'AI-powered predictive maintenance and optimization',
    demoPath: '/demos/predictive-analytics'
  }
];

const waterTreatmentTechnologies = [
  {
    title: 'Membrane Filtration AI',
    description: 'AI-optimized membrane filtration with real-time fouling prediction and prevention',
    metrics: [
      'Reduces membrane fouling by 85%',
      'Extends membrane life by 40%',
      'Optimizes backwash cycles',
      'Reduces energy consumption by 30%'
    ]
  },
  {
    title: 'Chemical Dosing Intelligence',
    description: 'Smart chemical dosing system using machine learning for optimal treatment',
    metrics: [
      'Reduces chemical usage by 25%',
      'Improves treatment efficiency by 40%',
      'Real-time pH optimization',
      'Automated coagulant dosing'
    ]
  },
  {
    title: 'Biological Treatment Control',
    description: 'AI-powered biological treatment process control and optimization',
    metrics: [
      'Increases removal efficiency by 35%',
      'Reduces sludge production by 20%',
      'Optimizes aeration control',
      'Maintains optimal microorganism balance'
    ]
  },
  {
    title: 'Advanced Oxidation Control',
    description: 'Intelligent control of advanced oxidation processes using deep learning',
    metrics: [
      'Improves oxidation efficiency by 45%',
      'Reduces energy consumption by 30%',
      'Optimizes oxidant dosage',
      'Real-time process monitoring'
    ]
  }
];

const processParameters = [
  {
    title: 'Real-time Quality Analysis',
    description: 'Continuous monitoring and analysis of water quality parameters',
    parameters: [
      'pH: Real-time adjustment with ±0.1 accuracy',
      'Turbidity: <0.1 NTU maintenance',
      'TDS: Continuous monitoring and control',
      'Conductivity: Precise measurement and adjustment'
    ]
  },
  {
    title: 'Flow Optimization',
    description: 'AI-driven flow control and optimization for maximum efficiency',
    parameters: [
      'Flow rate: Dynamic adjustment',
      'Pressure: Real-time optimization',
      'Temperature: Precise control',
      'Retention time: Optimal management'
    ]
  },
  {
    title: 'Energy Management',
    description: 'Smart energy management system for optimal resource utilization',
    parameters: [
      'Power consumption monitoring',
      'Peak load optimization',
      'Energy efficiency tracking',
      'Carbon footprint reduction'
    ]
  },
  {
    title: 'Resource Optimization',
    description: 'Intelligent resource management and optimization',
    parameters: [
      'Chemical consumption tracking',
      'Water recovery optimization',
      'Waste minimization',
      'Resource efficiency metrics'
    ]
  }
];

const performanceMetrics = [
  {
    title: 'Treatment Efficiency',
    value: '99.9%',
    description: 'Water treatment efficiency with AI optimization',
    icon: <WaterDropIcon />
  },
  {
    title: 'Energy Savings',
    value: '45%',
    description: 'Reduction in energy consumption through smart control',
    icon: <BiotechIcon />
  },
  {
    title: 'Chemical Reduction',
    value: '35%',
    description: 'Decrease in chemical usage with precise dosing',
    icon: <SmartToyIcon />
  },
  {
    title: 'Maintenance Prediction',
    value: '95%',
    description: 'Accuracy in predicting maintenance needs',
    icon: <PrecisionManufacturingIcon />
  }
];

const stats = [
  { number: '99.9%', label: 'Treatment Efficiency', icon: <WaterDropIcon /> },
  { number: '60%', label: 'Energy Savings', icon: <AutoGraphIcon /> },
  { number: '24/7', label: 'AI Monitoring', icon: <MonitorHeartIcon /> },
  { number: '100%', label: 'Quality Compliance', icon: <BiotechIcon /> }
];

const aiFeatures = [
  {
    title: 'Neural Network Control',
    description: 'Deep learning models continuously optimize treatment parameters based on real-time data and historical patterns.',
  },
  {
    title: 'Predictive Analytics',
    description: 'AI algorithms predict maintenance needs, quality issues, and system performance before problems occur.',
  },
  {
    title: 'Computer Vision',
    description: 'Advanced image processing monitors water quality and equipment condition through visual analysis.',
  },
  {
    title: 'Natural Language Processing',
    description: 'AI-powered chatbots and interfaces for intuitive system control and monitoring.',
  }
];

const advancedAnalytics = [
  {
    title: 'Machine Learning Models',
    description: 'State-of-the-art ML models for water treatment optimization',
    capabilities: [
      'Deep Neural Networks for quality prediction',
      'Random Forest for parameter optimization',
      'LSTM networks for time-series analysis',
      'Reinforcement Learning for process control'
    ],
    metrics: [
      'Model Accuracy: 99.8%',
      'Prediction Speed: <100ms',
      'Parameter Optimization: 95% efficiency',
      'Real-time Processing: 1000 data points/sec'
    ]
  },
  {
    title: 'Computer Vision Analysis',
    description: 'Advanced image processing for visual inspection and monitoring',
    capabilities: [
      'Real-time particle detection',
      'Automated visual inspection',
      'Contamination identification',
      'Equipment condition monitoring'
    ],
    metrics: [
      'Detection Accuracy: 99.5%',
      'Processing Speed: 30 FPS',
      'False Positive Rate: <0.1%',
      'Resolution: Up to 4K'
    ]
  },
  {
    title: 'Predictive Maintenance',
    description: 'AI-driven maintenance prediction and scheduling',
    capabilities: [
      'Equipment failure prediction',
      'Maintenance scheduling optimization',
      'Component lifetime analysis',
      'Risk assessment modeling'
    ],
    metrics: [
      'Prediction Accuracy: 95%',
      'Maintenance Cost Reduction: 40%',
      'Downtime Reduction: 60%',
      'Early Warning: 24-48 hours'
    ]
  },
  {
    title: 'Process Optimization',
    description: 'Continuous process optimization using AI algorithms',
    capabilities: [
      'Real-time parameter tuning',
      'Multi-objective optimization',
      'Adaptive control systems',
      'Energy efficiency optimization'
    ],
    metrics: [
      'Efficiency Improvement: 45%',
      'Energy Savings: 35%',
      'Process Stability: 99.9%',
      'Response Time: <1 second'
    ]
  }
];

const waterQualityParameters = [
  {
    category: 'Physical Parameters',
    description: 'Real-time monitoring of physical water characteristics',
    parameters: [
      {
        name: 'Turbidity',
        range: '0.1 - 1.0 NTU',
        accuracy: '±0.02 NTU',
        monitoring: 'Continuous'
      },
      {
        name: 'Total Suspended Solids',
        range: '0 - 1000 mg/L',
        accuracy: '±1%',
        monitoring: 'Every 5 minutes'
      },
      {
        name: 'Temperature',
        range: '0 - 100°C',
        accuracy: '±0.1°C',
        monitoring: 'Real-time'
      },
      {
        name: 'Conductivity',
        range: '0 - 2000 µS/cm',
        accuracy: '±1%',
        monitoring: 'Continuous'
      }
    ]
  },
  {
    category: 'Chemical Parameters',
    description: 'Advanced chemical composition analysis',
    parameters: [
      {
        name: 'pH',
        range: '2 - 12',
        accuracy: '±0.01',
        monitoring: 'Real-time'
      },
      {
        name: 'Dissolved Oxygen',
        range: '0 - 20 mg/L',
        accuracy: '±0.1 mg/L',
        monitoring: 'Continuous'
      },
      {
        name: 'Chlorine Residual',
        range: '0 - 5 mg/L',
        accuracy: '±0.02 mg/L',
        monitoring: 'Every minute'
      },
      {
        name: 'Total Organic Carbon',
        range: '0 - 1000 mg/L',
        accuracy: '±2%',
        monitoring: 'Every 15 minutes'
      }
    ]
  }
];

const treatmentProcesses = [
  {
    stage: 'Pre-treatment',
    description: 'Initial water treatment phase with AI-optimized processes',
    processes: [
      {
        name: 'Coagulation',
        efficiency: '95%',
        controlParams: [
          'Coagulant dosage: AI-optimized',
          'Mixing speed: Variable (20-200 rpm)',
          'Contact time: 1-5 minutes',
          'pH control: Automated'
        ]
      },
      {
        name: 'Flocculation',
        efficiency: '93%',
        controlParams: [
          'Flocculant dosage: ML-controlled',
          'Mixing energy: Adaptive',
          'Floc formation: Vision-monitored',
          'Process duration: AI-optimized'
        ]
      }
    ]
  },
  {
    stage: 'Primary Treatment',
    description: 'Advanced primary treatment with real-time monitoring',
    processes: [
      {
        name: 'Sedimentation',
        efficiency: '90%',
        controlParams: [
          'Settling time: ML-optimized',
          'Sludge removal: Automated',
          'Surface loading: Monitored',
          'Flow distribution: AI-controlled'
        ]
      },
      {
        name: 'Flotation',
        efficiency: '92%',
        controlParams: [
          'Air flow rate: Auto-adjusted',
          'Pressure: Real-time control',
          'Chemical dosing: AI-optimized',
          'Skimming frequency: Adaptive'
        ]
      }
    ]
  },
  {
    stage: 'Secondary Treatment',
    description: 'Biological treatment with AI-enhanced control',
    processes: [
      {
        name: 'Activated Sludge',
        efficiency: '96%',
        controlParams: [
          'MLSS: AI-monitored',
          'Aeration: Smart control',
          'SRT: ML-optimized',
          'F/M ratio: Auto-balanced'
        ]
      },
      {
        name: 'Membrane Bioreactor',
        efficiency: '99%',
        controlParams: [
          'Flux: AI-controlled',
          'TMP: Real-time optimization',
          'Membrane cleaning: Predictive',
          'Permeate quality: Continuous monitoring'
        ]
      }
    ]
  }
];

const systemIntegration = [
  {
    component: 'SCADA Integration',
    description: 'Advanced SCADA system with AI capabilities',
    features: [
      'Real-time process visualization',
      'Predictive control algorithms',
      'Multi-site integration',
      'Custom reporting dashboards'
    ],
    metrics: {
      responseTime: '<100ms',
      reliability: '99.99%',
      dataPoints: '100,000/sec',
      uptime: '99.999%'
    }
  },
  {
    component: 'IoT Network',
    description: 'Comprehensive IoT sensor network',
    features: [
      'Wireless sensor mesh',
      'Edge computing nodes',
      'Real-time data streaming',
      'Secure communication'
    ],
    metrics: {
      sensors: '1000+',
      dataRate: '10,000 samples/sec',
      latency: '<10ms',
      coverage: '100%'
    }
  },
  {
    component: 'Cloud Platform',
    description: 'Scalable cloud infrastructure for data processing',
    features: [
      'Big data analytics',
      'Machine learning pipeline',
      'Automated reporting',
      'Remote access'
    ],
    metrics: {
      storage: 'Petabyte scale',
      processing: '1M events/sec',
      availability: '99.99%',
      backup: 'Real-time'
    }
  }
];

function Home() {
  return (
    <Box>
      <HeroSection>
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
          <Grid container spacing={8} alignItems="center" sx={{ minHeight: '80vh' }}>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <GradientText variant="h1" sx={{ mb: 3, fontSize: { xs: '2.5rem', md: '3.5rem' } }}>
                  AI-Powered Water Treatment Solutions
                </GradientText>
                <Typography
                  variant="h5"
                  sx={{
                    color: '#666',
                    mb: 4,
                    lineHeight: 1.8,
                    fontWeight: 400,
                  }}
                >
                  Revolutionary water treatment technology powered by artificial intelligence for superior performance and efficiency.
                </Typography>
                <Stack direction="row" spacing={3}>
                  <StyledButton>
                    Get Started
                  </StyledButton>
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: '#00C6CF',
                      color: '#00C6CF',
                      padding: '12px 32px',
                      borderRadius: '30px',
                      textTransform: 'none',
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      '&:hover': {
                        borderColor: '#00C6CF',
                        background: 'rgba(0, 198, 207, 0.05)',
                      },
                    }}
                  >
                    Learn More
                  </Button>
                </Stack>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8 }}
              >
                <Box
                  component="img"
                  src="/hero-image.png"
                  alt="AI Water Treatment"
                  sx={{
                    width: '100%',
                    maxWidth: '600px',
                    height: 'auto',
                    borderRadius: '20px',
                    boxShadow: '0 30px 60px rgba(0, 198, 207, 0.15)',
                  }}
                />
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </HeroSection>

      {/* Performance Metrics Section */}
      <Box sx={{ py: 8, background: 'linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%)' }}>
        <Container maxWidth="lg">
          <Typography variant="h2" align="center" sx={{ mb: 6 }}>
            AI-Powered Performance
          </Typography>
          <Grid container spacing={4}>
            {performanceMetrics.map((metric, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card sx={{
                  p: 3,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  background: 'rgba(255, 255, 255, 0.9)',
                  backdropFilter: 'blur(10px)',
                  borderRadius: '20px',
                  border: '1px solid rgba(0, 198, 207, 0.1)',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 20px 40px rgba(0, 198, 207, 0.15)',
                  }
                }}>
                  <Box sx={{ color: '#00C6CF', mb: 2 }}>
                    {metric.icon}
                  </Box>
                  <Typography variant="h3" sx={{ fontWeight: 700, color: '#00C6CF', mb: 1 }}>
                    {metric.value}
                  </Typography>
                  <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                    {metric.title}
                  </Typography>
                  <Typography color="text.secondary" textAlign="center">
                    {metric.description}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Advanced Analytics Section */}
      <Box sx={{ py: 12, background: 'white' }}>
        <Container maxWidth="lg">
          <Typography variant="h2" align="center" sx={{ mb: 6 }}>
            Advanced Analytics & AI
          </Typography>
          <Grid container spacing={4}>
            {advancedAnalytics.map((analytic, index) => {
              let Icon;
              let iconColor;
              switch(index) {
                case 0:
                  Icon = Psychology;
                  iconColor = iconColors.mlModels;
                  break;
                case 1:
                  Icon = RemoveRedEye;
                  iconColor = iconColors.computerVision;
                  break;
                case 2:
                  Icon = Build;
                  iconColor = iconColors.predictive;
                  break;
                case 3:
                  Icon = Speed;
                  iconColor = iconColors.process;
                  break;
                default:
                  Icon = Psychology;
                  iconColor = iconColors.mlModels;
              }
              return (
                <Grid item xs={12} md={6} key={index}>
                  <Card sx={{
                    p: 4,
                    height: '100%',
                    background: 'rgba(255, 255, 255, 0.9)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: '20px',
                    border: `1px solid ${iconColor}20`,
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: `0 20px 40px ${iconColor}15`,
                    }
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Icon sx={{ fontSize: 40, color: iconColor, mr: 2 }} />
                      <Typography variant="h5" gutterBottom sx={{ mb: 0 }}>
                        {analytic.title}
                      </Typography>
                    </Box>
                    <Typography color="text.secondary" sx={{ mb: 3 }}>
                      {analytic.description}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      Capabilities
                    </Typography>
                    <Box component="ul" sx={{ pl: 2, mb: 3 }}>
                      {analytic.capabilities.map((capability, idx) => (
                        <Typography
                          key={idx}
                          component="li"
                          variant="body2"
                          color="text.secondary"
                          sx={{ mb: 1 }}
                        >
                          {capability}
                        </Typography>
                      ))}
                    </Box>
                    <Typography variant="h6" gutterBottom>
                      Performance Metrics
                    </Typography>
                    <Box component="ul" sx={{ pl: 2 }}>
                      {analytic.metrics.map((metric, idx) => (
                        <Typography
                          key={idx}
                          component="li"
                          variant="body2"
                          color="text.secondary"
                          sx={{ mb: 1 }}
                        >
                          {metric}
                        </Typography>
                      ))}
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>

      {/* Water Quality Parameters Section */}
      <Box sx={{ py: 12, background: '#f8f9fa' }}>
        <Container maxWidth="lg">
          <Typography variant="h2" align="center" sx={{ mb: 6 }}>
            Water Quality Monitoring
          </Typography>
          <Grid container spacing={4}>
            {waterQualityParameters.map((category, index) => {
              const Icon = index === 0 ? WaterDropIcon : Science;
              const iconColor = index === 0 ? iconColors.physical : iconColors.chemical;
              return (
                <Grid item xs={12} key={index}>
                  <Card sx={{
                    p: 4,
                    background: 'rgba(255, 255, 255, 0.9)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: '20px',
                    border: `1px solid ${iconColor}20`,
                    mb: 4
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                      <Icon sx={{ fontSize: 40, color: iconColor, mr: 2 }} />
                      <Typography variant="h5" gutterBottom sx={{ mb: 0 }}>
                        {category.category}
                      </Typography>
                    </Box>
                    <Typography color="text.secondary" sx={{ mb: 4 }}>
                      {category.description}
                    </Typography>
                    <Grid container spacing={3}>
                      {category.parameters.map((param, idx) => (
                        <Grid item xs={12} sm={6} md={3} key={idx}>
                          <Card sx={{
                            p: 2,
                            height: '100%',
                            background: 'white',
                            borderRadius: '10px',
                            border: '1px solid rgba(0, 198, 207, 0.1)'
                          }}>
                            <Typography variant="h6" gutterBottom>
                              {param.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Range: {param.range}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Accuracy: {param.accuracy}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Monitoring: {param.monitoring}
                            </Typography>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>

      {/* Treatment Processes Section */}
      <Box sx={{ py: 12, background: 'white' }}>
        <Container maxWidth="lg">
          <Typography variant="h2" align="center" sx={{ mb: 6 }}>
            Treatment Process Control
          </Typography>
          <Grid container spacing={4}>
            {treatmentProcesses.map((stage, index) => (
              <Grid item xs={12} key={index}>
                <Card sx={{
                  p: 4,
                  background: 'rgba(255, 255, 255, 0.9)',
                  backdropFilter: 'blur(10px)',
                  borderRadius: '20px',
                  border: `1px solid ${iconColors.treatment}20`,
                  mb: 4
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <Settings sx={{ fontSize: 40, color: iconColors.treatment, mr: 2 }} />
                    <Typography variant="h5" gutterBottom sx={{ mb: 0 }}>
                      {stage.stage}
                    </Typography>
                  </Box>
                  <Typography color="text.secondary" sx={{ mb: 4 }}>
                    {stage.description}
                  </Typography>
                  <Grid container spacing={3}>
                    {stage.processes.map((process, idx) => (
                      <Grid item xs={12} sm={6} key={idx}>
                        <Card sx={{
                          p: 3,
                          height: '100%',
                          background: 'white',
                          borderRadius: '10px',
                          border: '1px solid rgba(0, 198, 207, 0.1)'
                        }}>
                          <Typography variant="h6" gutterBottom>
                            {process.name}
                          </Typography>
                          <Typography variant="subtitle1" color="primary" gutterBottom>
                            Efficiency: {process.efficiency}
                          </Typography>
                          <Box component="ul" sx={{ pl: 2, mb: 0 }}>
                            {process.controlParams.map((param, paramIdx) => (
                              <Typography
                                key={paramIdx}
                                component="li"
                                variant="body2"
                                color="text.secondary"
                                sx={{ mb: 1 }}
                              >
                                {param}
                              </Typography>
                            ))}
                          </Box>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* System Integration Section */}
      <Box sx={{ py: 12, background: '#f8f9fa' }}>
        <Container maxWidth="lg">
          <Typography variant="h2" align="center" sx={{ mb: 6 }}>
            System Integration
          </Typography>
          <Grid container spacing={4}>
            {systemIntegration.map((system, index) => {
              let Icon;
              let iconColor;
              switch(index) {
                case 0:
                  Icon = DataUsage;
                  iconColor = iconColors.scada;
                  break;
                case 1:
                  Icon = Router;
                  iconColor = iconColors.iot;
                  break;
                case 2:
                  Icon = Storage;
                  iconColor = iconColors.cloud;
                  break;
                default:
                  Icon = Cloud;
                  iconColor = iconColors.cloud;
              }
              return (
                <Grid item xs={12} md={4} key={index}>
                  <Card sx={{
                    p: 4,
                    height: '100%',
                    background: 'rgba(255, 255, 255, 0.9)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: '20px',
                    border: `1px solid ${iconColor}20`,
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: `0 20px 40px ${iconColor}15`,
                    }
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Icon sx={{ fontSize: 40, color: iconColor, mr: 2 }} />
                      <Typography variant="h5" gutterBottom sx={{ mb: 0 }}>
                        {system.component}
                      </Typography>
                    </Box>
                    <Typography color="text.secondary" sx={{ mb: 3 }}>
                      {system.description}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Button 
                        variant="contained"
                        component={RouterLink}
                        to={`/system/${system.component.toLowerCase().split(' ')[0]}`}
                        sx={{ 
                          bgcolor: iconColor,
                          '&:hover': {
                            bgcolor: iconColor,
                            opacity: 0.9
                          }
                        }}
                      >
                        View Details
                      </Button>
                      <Typography variant="body2" color="text.secondary">
                        Live Demo Available
                      </Typography>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                      Key Features
                    </Typography>
                    <Box component="ul" sx={{ pl: 2, mb: 3 }}>
                      {system.features.map((feature, idx) => (
                        <Typography
                          key={idx}
                          component="li"
                          variant="body2"
                          color="text.secondary"
                          sx={{ mb: 1 }}
                        >
                          {feature}
                        </Typography>
                      ))}
                    </Box>
                    <Typography variant="h6" gutterBottom>
                      Performance
                    </Typography>
                    <Grid container spacing={1}>
                      {Object.entries(system.metrics).map(([key, value], idx) => (
                        <Grid item xs={6} key={idx}>
                          <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
                            {key}: {value}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>

      {/* AI Features Section */}
      <Box sx={{ py: 12, background: 'white' }}>
        <Container maxWidth="lg">
          <Typography variant="h2" align="center" sx={{ mb: 6 }}>
            Advanced AI Technologies
          </Typography>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card
                  sx={{
                    p: 4,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    background: 'rgba(255, 255, 255, 0.9)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: '20px',
                    border: '1px solid rgba(0, 198, 207, 0.1)',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: '0 20px 40px rgba(0, 198, 207, 0.15)',
                    }
                  }}
                >
                  {feature.icon}
                  <Typography variant="h5" sx={{ my: 2 }}>
                    {feature.title}
                  </Typography>
                  <Typography color="text.secondary" sx={{ mb: 3 }}>
                    {feature.description}
                  </Typography>
                  <Button
                    component={Link}
                    to={feature.demoPath}
                    variant="contained"
                    sx={{
                      bgcolor: feature.icon.props.sx.color,
                      '&:hover': {
                        bgcolor: feature.icon.props.sx.color,
                        opacity: 0.9
                      }
                    }}
                  >
                    View Demo
                  </Button>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Treatment Technologies Section */}
      <Box sx={{ py: 12, background: 'white' }}>
        <Container maxWidth="lg">
          <Typography variant="h2" align="center" sx={{ mb: 6 }}>
            Smart Treatment Technologies
          </Typography>
          <Grid container spacing={4}>
            {waterTreatmentTechnologies.map((tech, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card sx={{
                  p: 4,
                  height: '100%',
                  background: 'rgba(255, 255, 255, 0.9)',
                  backdropFilter: 'blur(10px)',
                  borderRadius: '20px',
                  border: '1px solid rgba(0, 198, 207, 0.1)',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 20px 40px rgba(0, 198, 207, 0.15)',
                  }
                }}>
                  <Typography variant="h5" gutterBottom>
                    {tech.title}
                  </Typography>
                  <Typography color="text.secondary" sx={{ mb: 3 }}>
                    {tech.description}
                  </Typography>
                  <Box component="ul" sx={{ pl: 2, mb: 0 }}>
                    {tech.metrics.map((metric, idx) => (
                      <Typography
                        key={idx}
                        component="li"
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 1 }}
                      >
                        {metric}
                      </Typography>
                    ))}
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Process Parameters Section */}
      <Box sx={{ py: 8, background: 'linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%)' }}>
        <Container maxWidth="lg">
          <Typography variant="h2" textAlign="center" sx={{ mb: 6 }}>
            Process Parameters
          </Typography>
          <Grid container spacing={4}>
            {processParameters.map((param, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <StyledCard>
                  <Typography variant="h5" gutterBottom>
                    {param.title}
                  </Typography>
                  <Typography color="text.secondary" sx={{ mb: 3 }}>
                    {param.description}
                  </Typography>
                  <Box component="ul" sx={{ pl: 2, mb: 0 }}>
                    {param.parameters.map((parameter, idx) => (
                      <Typography
                        key={idx}
                        component="li"
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 1 }}
                      >
                        {parameter}
                      </Typography>
                    ))}
                  </Box>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default Home;
