import React from 'react';
import { 
  Box, 
  Container, 
  Grid, 
  Paper, 
  Typography, 
  Card, 
  CardContent, 
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import WaterIcon from '@mui/icons-material/Water';
import SpeedIcon from '@mui/icons-material/Speed';
import MonitorIcon from '@mui/icons-material/Monitor';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BoltIcon from '@mui/icons-material/Bolt';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import ScienceIcon from '@mui/icons-material/Science';

const BrightBackground = styled(Box)(() => ({
  background: 'linear-gradient(135deg, #f8f9fa 0%, #ffffff 70%, #f0f7ff 100%)',
  borderRadius: '24px',
  padding: '2rem',
  boxShadow: '0 8px 32px rgba(0, 102, 204, 0.08)',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '6px',
    background: 'linear-gradient(90deg, #0066CC, #00A3FF)',
  }
}));

const CommonCard = styled(Card)(({ theme }) => ({
  height: '100%',
  borderRadius: '16px',
  background: '#ffffff',
  transition: 'all 0.3s ease',
  border: '1px solid rgba(0, 102, 204, 0.08)',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 16px 32px rgba(0, 102, 204, 0.12)',
  }
}));

const ComparisonCard = styled(CommonCard)(({ theme }) => ({
  height: '100%',
  background: 'linear-gradient(165deg, #ffffff 0%, #f0f7ff 100%)',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: 'linear-gradient(90deg, #0066CC, #00A3FF)',
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
  '&:hover::after': {
    opacity: 1,
  }
}));

const GradientText = styled(Typography)(({ theme, color = '90deg, #1976d2, #42a5f5' }) => ({
  background: `linear-gradient(${color})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 600,
  letterSpacing: '0.5px'
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(90deg, #0066CC 0%, #00A3FF 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 800,
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
  marginBottom: '2rem',
  textAlign: 'center'
}));

const HighlightedROImage = () => {
  const [activeHighlight, setActiveHighlight] = React.useState(null);
  
  const highlights = [
    {
      id: 'vessels',
      title: 'Multiport RO Vessels',
      top: '20%',
      left: '70%',
    },
    {
      id: 'flowmeter',
      title: 'Flow Meter for Every Stages',
      top: '45%',
      left: '70%',
    },
    {
      id: 'pressure',
      title: 'Both Pressure Gauge & Pressure Transmitters',
      top: '70%',
      left: '70%',
    },
    {
      id: 'cip',
      title: 'Automatic Flushing & CIP',
      top: '85%',
      left: '30%',
    }
  ];

  return (
    <Box sx={{ position: 'relative', width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
      <img 
        src="/images/ro.png" 
        alt="Reverse Osmosis System" 
        style={{ width: '100%', height: 'auto' }}
      />
      {highlights.map((highlight) => (
        <Box
          key={highlight.id}
          sx={{
            position: 'absolute',
            top: highlight.top,
            left: highlight.left,
            transform: 'translate(-50%, -50%)',
            padding: '8px 16px',
            backgroundColor: activeHighlight === highlight.id ? 'rgba(0, 150, 255, 0.9)' : 'rgba(0, 150, 255, 0.7)',
            color: 'white',
            borderRadius: '4px',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: 'rgba(0, 150, 255, 0.9)',
              transform: 'translate(-50%, -50%) scale(1.05)',
            }
          }}
          onMouseEnter={() => setActiveHighlight(highlight.id)}
          onMouseLeave={() => setActiveHighlight(null)}
        >
          {highlight.title}
        </Box>
      ))}
    </Box>
  );
};

const benefits = [
  { 
    title: 'Higher Recovery Rate', 
    description: 'Achieve 92-93% recovery rate compared to traditional 90%',
    icon: <TrendingUpIcon sx={{ fontSize: 40, color: '#0066CC' }} />
  },
  { 
    title: 'Power Efficiency', 
    description: 'Reduced power consumption from 4.2 kW/m³ to 0.9 kW/m³',
    icon: <BoltIcon sx={{ fontSize: 40, color: '#0099FF' }} />
  },
  { 
    title: 'Automated Operation', 
    description: 'Advanced CIP capabilities with automated cleaning processes',
    icon: <EngineeringIcon sx={{ fontSize: 40, color: '#FF9800' }} />
  },
  { 
    title: 'Compact Design', 
    description: 'Smaller footprint with integrated multi-stage system',
    icon: <SquareFootIcon sx={{ fontSize: 40, color: '#F44336' }} />
  },
];

const RoTreatment = () => {
  const theme = useTheme();

  return (
    <BrightBackground>
      <Container maxWidth="xl" sx={{ py: 10 }}>
        {/* Header Section */}
        <Box sx={{ 
          textAlign: 'center', 
          mb: 10, 
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: '-30px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '150px',
            height: '150px',
            background: 'radial-gradient(circle, rgba(0, 102, 204, 0.1) 0%, rgba(255, 255, 255, 0) 70%)',
            borderRadius: '50%',
            zIndex: 0
          }
        }}>
          <StyledTitle variant="h2" component="h1" sx={{ 
            mb: 3, 
            fontSize: { 
              xs: '2.5rem', 
              md: '3.75rem' 
            },
            textShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
          }}>
            Reverse Osmosis Treatment
          </StyledTitle>
          <Typography 
            variant="h5" 
            sx={{ 
              color: 'text.secondary', 
              maxWidth: '800px', 
              mx: 'auto', 
              fontWeight: 300,
              fontSize: { xs: '1.2rem', md: '1.5rem' },
              lineHeight: 1.6,
              letterSpacing: '0.5px'
            }}
          >
            Advanced Water Purification System with Smart Automation
          </Typography>
        </Box>

        <HighlightedROImage />

        {/* Features Grid */}
        <Box sx={{ my: 8 }}>
          <Grid container spacing={3}>
            {[
              {
                title: 'Multiport RO Vessels',
                description: 'Advanced vessel design with multiple ports for optimal flow distribution',
                icon: <WaterIcon />,
                color: '#0066CC',
                bgGradient: 'linear-gradient(135deg, #E3F2FD 0%, #FFFFFF 100%)'
              },
              {
                title: 'Flow Monitoring',
                description: 'Precise flow monitoring at each treatment stage for accurate control',
                icon: <SpeedIcon />,
                color: '#00796B',
                bgGradient: 'linear-gradient(135deg, #E0F2F1 0%, #FFFFFF 100%)'
              },
              {
                title: 'Pressure Control',
                description: 'Comprehensive pressure monitoring with gauges and transmitters',
                icon: <MonitorIcon />,
                color: '#5E35B1',
                bgGradient: 'linear-gradient(135deg, #EDE7F6 0%, #FFFFFF 100%)'
              },
              {
                title: 'Smart Cleaning',
                description: 'Automated flushing and CIP system for optimal maintenance',
                icon: <AutorenewIcon />,
                color: '#E65100',
                bgGradient: 'linear-gradient(135deg, #FFF3E0 0%, #FFFFFF 100%)'
              }
            ].map((feature, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Paper
                  elevation={0}
                  sx={{
                    height: '100%',
                    p: 3,
                    borderRadius: '16px',
                    background: feature.bgGradient,
                    position: 'relative',
                    transition: 'all 0.3s ease',
                    border: '1px solid rgba(0,0,0,0.06)',
                    overflow: 'hidden',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: `0 16px 32px ${feature.color}15`,
                      '& .feature-icon': {
                        transform: 'scale(1.1) rotate(5deg)',
                        color: '#fff',
                        backgroundColor: feature.color
                      }
                    }
                  }}
                >
                  <Box
                    className="feature-icon"
                    sx={{
                      width: 56,
                      height: 56,
                      borderRadius: '16px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'rgba(255,255,255,0.9)',
                      color: feature.color,
                      transition: 'all 0.3s ease',
                      mb: 2,
                      '& svg': {
                        fontSize: 28
                      }
                    }}
                  >
                    {feature.icon}
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      color: feature.color,
                      mb: 1
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'text.secondary',
                      lineHeight: 1.6
                    }}
                  >
                    {feature.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Advanced RO Features Section */}
        <Box sx={{ 
          py: 12,
          mt: 8,
          background: 'linear-gradient(165deg, #f8f9fa 0%, #ffffff 50%, #f0f7ff 100%)',
          borderRadius: '32px',
          position: 'relative',
          overflow: 'hidden'
        }}>
          <Container maxWidth="xl">
            <Box sx={{ textAlign: 'center', mb: 8 }}>
              <Typography 
                variant="h2" 
                sx={{ 
                  fontWeight: 800,
                  background: 'linear-gradient(135deg, #0066CC 0%, #0099FF 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  mb: 2
                }}
              >
                Advanced RO Features
              </Typography>
              <Typography 
                variant="h5" 
                sx={{ 
                  color: 'text.secondary',
                  maxWidth: '800px',
                  mx: 'auto'
                }}
              >
                State-of-the-art reverse osmosis technology with intelligent monitoring and control
              </Typography>
            </Box>

            <Grid container spacing={4}>
              {/* Key Features */}
              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 4 }}>
                  <Typography variant="h4" sx={{ 
                    fontWeight: 700,
                    mb: 4,
                    color: '#0066CC'
                  }}>
                    Key Features
                  </Typography>
                  
                  <Grid container spacing={3}>
                    {[
                      {
                        title: 'Multiport RO Vessels',
                        description: 'Advanced vessel design with multiple ports for optimal flow distribution',
                        icon: <WaterIcon />,
                        color: '#00BCD4'
                      },
                      {
                        title: 'Flow Monitoring',
                        description: 'Individual flow meters for each stage with real-time monitoring',
                        icon: <SpeedIcon />,
                        color: '#4CAF50'
                      },
                      {
                        title: 'Pressure Control',
                        description: 'Dual pressure monitoring with gauges and digital transmitters',
                        icon: <MonitorIcon />,
                        color: '#FF9800'
                      },
                      {
                        title: 'Automated CIP',
                        description: 'Integrated cleaning system with automated sequences',
                        icon: <AutorenewIcon />,
                        color: '#E91E63'
                      }
                    ].map((feature, index) => (
                      <Grid item xs={12} sm={6} key={index}>
                        <Paper
                          elevation={0}
                          sx={{
                            p: 3,
                            height: '100%',
                            borderRadius: '20px',
                            border: '1px solid rgba(0,0,0,0.08)',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              transform: 'translateY(-8px)',
                              boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
                              '& .feature-icon': {
                                background: feature.color,
                                color: '#ffffff'
                              }
                            }
                          }}
                        >
                          <Box 
                            className="feature-icon"
                            sx={{
                              width: 48,
                              height: 48,
                              borderRadius: '12px',
                              background: `${feature.color}15`,
                              color: feature.color,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              mb: 2,
                              transition: 'all 0.3s ease'
                            }}
                          >
                            {feature.icon}
                          </Box>
                          <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                            {feature.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {feature.description}
                          </Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                {/* Technical Specifications */}
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    borderRadius: '20px',
                    background: 'linear-gradient(135deg, #0066CC, #0099FF)',
                    color: 'white'
                  }}
                >
                  <Typography variant="h5" sx={{ fontWeight: 700, mb: 3 }}>
                    Technical Specifications
                  </Typography>
                  
                  <Grid container spacing={3}>
                    {[
                      { label: 'Recovery Rate', value: 'Up to 97%' },
                      { label: 'Power Consumption', value: '0.9 kW/m³' },
                      { label: 'Operating Pressure', value: '55-60 bar' },
                      { label: 'Membrane Elements', value: '8" x 40"' },
                      { label: 'Feed Water TDS', value: 'Up to 45,000 ppm' },
                      { label: 'System Capacity', value: '10-100 m³/hr' }
                    ].map((spec, index) => (
                      <Grid item xs={12} sm={6} key={index}>
                        <Box sx={{
                          p: 2,
                          borderRadius: '12px',
                          background: 'rgba(255,255,255,0.1)'
                        }}>
                          <Typography variant="body2" sx={{ opacity: 0.8, mb: 1 }}>
                            {spec.label}
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 700 }}>
                            {spec.value}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>

              {/* Control Features */}
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    height: '100%',
                    borderRadius: '20px',
                    background: '#ffffff',
                    border: '1px solid rgba(0,0,0,0.08)'
                  }}
                >
                  <Typography variant="h4" sx={{ 
                    fontWeight: 700,
                    mb: 4,
                    color: '#0066CC'
                  }}>
                    Control & Automation
                  </Typography>

                  {[
                    {
                      title: 'Intelligent Control System',
                      features: [
                        'Advanced PLC control with touchscreen HMI',
                        'Remote monitoring and control capabilities',
                        'Data logging and trend analysis',
                        'Automated system optimization'
                      ]
                    },
                    {
                      title: 'Safety Features',
                      features: [
                        'Multiple pressure safety interlocks',
                        'Emergency shutdown system',
                        'Leak detection system',
                        'High pressure protection'
                      ]
                    },
                    {
                      title: 'Maintenance Features',
                      features: [
                        'Predictive maintenance alerts',
                        'Automated CIP scheduling',
                        'Component life monitoring',
                        'Performance optimization suggestions'
                      ]
                    }
                  ].map((section, index) => (
                    <Box key={index} sx={{ mb: 4 }}>
                      <Typography variant="h6" sx={{ 
                        fontWeight: 600,
                        mb: 2,
                        color: '#0066CC'
                      }}>
                        {section.title}
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {section.features.map((feature, fIndex) => (
                          <Box
                            key={fIndex}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1.5,
                              borderRadius: '8px',
                              background: 'rgba(0,102,204,0.04)',
                              '&:hover': {
                                background: 'rgba(0,102,204,0.08)'
                              }
                            }}
                          >
                            <Box
                              sx={{
                                width: 6,
                                height: 6,
                                borderRadius: '50%',
                                background: '#0066CC',
                                flexShrink: 0
                              }}
                            />
                            <Typography variant="body2">
                              {feature}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* System Transformation Section */}
        <Box sx={{ 
          py: 10,
          background: 'linear-gradient(165deg, #f8f9fa 0%, #ffffff 50%, #f0f7ff 100%)',
          borderRadius: '24px',
          mt: 8
        }}>
          <Container maxWidth="xl">
            <Typography 
              variant="h2" 
              sx={{ 
                textAlign: 'center',
                fontWeight: 800,
                background: 'linear-gradient(135deg, #0066CC, #0099FF)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                mb: 6
              }}
            >
              System Transformation
            </Typography>

            <Grid container spacing={4}>
              {/* Traditional System */}
              <Grid item xs={12} md={5}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    borderRadius: '20px',
                    border: '1px solid rgba(0,0,0,0.08)',
                    background: '#ffffff'
                  }}
                >
                  <Typography variant="h4" sx={{ 
                    mb: 4,
                    fontWeight: 700,
                    color: '#FF4B4B'
                  }}>
                    Traditional System
                  </Typography>

                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h3" sx={{ 
                      fontWeight: 800,
                      color: '#FF4B4B',
                      mb: 1
                    }}>
                      4.2 kW/m³
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                      Total Power Consumption
                    </Typography>
                  </Box>

                  {[
                    { stage: '1st Stage', power: '2.0 kW/m³' },
                    { stage: '2nd Stage', power: '5.0 kW/m³' },
                    { stage: '3rd Stage', power: '6.5 kW/m³' },
                    { stage: '4th Stage', power: '8.3 kW/m³' }
                  ].map((stage, index) => (
                    <Box
                      key={index}
                      sx={{
                        mb: 2,
                        p: 2,
                        borderRadius: '12px',
                        background: 'rgba(255,75,75,0.05)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        {stage.stage}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: '#FF4B4B', fontWeight: 700 }}>
                        {stage.power}
                      </Typography>
                    </Box>
                  ))}
                </Paper>
              </Grid>

              {/* Arrow Section */}
              <Grid item xs={12} md={2}>
                <Box sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <Box sx={{
                    width: 80,
                    height: 80,
                    borderRadius: '50%',
                    background: 'linear-gradient(135deg, #0066CC, #0099FF)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0 10px 30px rgba(0,102,204,0.2)'
                  }}>
                    <AutorenewIcon sx={{ fontSize: 40, color: 'white' }} />
                  </Box>
                </Box>
              </Grid>

              {/* Integrated System */}
              <Grid item xs={12} md={5}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    borderRadius: '20px',
                    background: 'linear-gradient(135deg, #0066CC, #0099FF)',
                    color: 'white'
                  }}
                >
                  <Typography variant="h4" sx={{ 
                    mb: 4,
                    fontWeight: 700
                  }}>
                    Integrated System
                  </Typography>

                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h3" sx={{ 
                      fontWeight: 800,
                      mb: 2
                    }}>
                      0.9 kW/m³
                    </Typography>
                    <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
                      Total Power Consumption
                    </Typography>
                  </Box>

                  {[
                    {
                      label: 'Recovery Rate',
                      value: '92-93%',
                      icon: <TrendingUpIcon />
                    },
                    {
                      label: 'Power Efficiency',
                      value: '78% Better',
                      icon: <BoltIcon />
                    },
                    {
                      label: 'Footprint',
                      value: '40% Smaller',
                      icon: <SquareFootIcon />
                    }
                  ].map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        mb: 2,
                        p: 2,
                        borderRadius: '12px',
                        background: 'rgba(255,255,255,0.1)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2
                      }}
                    >
                      <Box sx={{
                        width: 40,
                        height: 40,
                        borderRadius: '10px',
                        background: 'rgba(255,255,255,0.2)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        {item.icon}
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ opacity: 0.8 }}>
                          {item.label}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                          {item.value}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Maximum Recovery Section */}
        <Box sx={{ my: 8 }}>
          <Typography variant="h3" component="h2" sx={{ 
            textAlign: 'center', 
            mb: 6,
            fontWeight: 700,
            color: '#333'
          }}>
            Achieving Maximum Recovery
          </Typography>
          
          <Grid container spacing={4} justifyContent="center">
            {[
              {
                title: 'Base Recovery',
                value: '+97%',
                description: 'Base Recovery',
                subtext: 'Advanced recovery system'
              },
              {
                title: 'Additional Recovery',
                value: '+1.5%',
                description: 'Additional Recovery',
                subtext: 'Enhanced efficiency'
              },
              {
                title: 'MEE Feed Only',
                value: '+1.5%',
                description: 'MEE Feed Only',
                subtext: 'Optimized process'
              }
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box sx={{ 
                  textAlign: 'center',
                  p: 3,
                  border: '1px solid #e0e0e0',
                  borderRadius: '8px',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.05)'
                  }
                }}>
                  <Typography variant="h3" sx={{ 
                    color: '#0066CC',
                    fontWeight: 700,
                    mb: 1
                  }}>
                    {item.value}
                  </Typography>
                  <Typography variant="h6" sx={{ 
                    color: '#333',
                    fontWeight: 600,
                    mb: 2
                  }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body1" sx={{ 
                    color: '#666',
                    mb: 1
                  }}>
                    {item.subtext}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Revolute Section */}
        <Box sx={{ 
          py: 8,
          background: 'linear-gradient(135deg, #f8f9fa 0%, #ffffff 70%, #f0f7ff 100%)',
          borderRadius: '24px',
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'radial-gradient(circle at 50% 0%, rgba(0,102,204,0.03) 0%, transparent 70%)',
            zIndex: 0
          }
        }}>
          <Container maxWidth="xl">
            <Box sx={{ 
              textAlign: 'center',
              mb: 8,
              position: 'relative'
            }}>
              <Typography 
                variant="h2" 
                sx={{ 
                  fontWeight: 800,
                  background: 'linear-gradient(135deg, #0066CC 0%, #0099FF 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  mb: 2
                }}
              >
                Revolute Technology
              </Typography>
              <Typography 
                variant="h5" 
                sx={{ 
                  color: 'text.secondary',
                  maxWidth: '700px',
                  mx: 'auto',
                  lineHeight: 1.6,
                  mb: 6
                }}
              >
                Advanced membrane technology delivering industry-leading recovery rates with minimal footprint
              </Typography>
            </Box>

            <Grid container spacing={4}>
              {/* Features */}
              <Grid item xs={12} md={7}>
                <Box sx={{ 
                  display: 'grid', 
                  gap: 3,
                  gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' }
                }}>
                  {[
                    {
                      icon: <TrendingUpIcon />,
                      title: 'High Recovery',
                      subtitle: 'in Single Skid RO',
                      description: 'Industry-leading recovery rate exceeding 97% in a compact single skid design.',
                      color: '#00bcd4'
                    },
                    {
                      icon: <BoltIcon />,
                      title: 'Lower CAPEX',
                      subtitle: '15-20% reduction',
                      description: 'Significant cost savings through optimized design and efficient operation.',
                      color: '#4caf50'
                    },
                    {
                      icon: <SquareFootIcon />,
                      title: 'Compact Design',
                      subtitle: 'Minimal footprint',
                      description: 'Space-efficient design requiring minimal installation area.',
                      color: '#ff9800'
                    },
                    {
                      icon: <ScienceIcon />,
                      title: 'Brine Management',
                      subtitle: 'up to 140 GPL',
                      description: 'Advanced system handling high brine concentrations efficiently.',
                      color: '#e91e63'
                    }
                  ].map((item, index) => (
                    <Paper
                      key={index}
                      elevation={0}
                      sx={{
                        p: 3,
                        borderRadius: '16px',
                        background: '#ffffff',
                        border: '1px solid rgba(0,0,0,0.05)',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          transform: 'translateY(-8px)',
                          boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
                          '& .feature-icon': {
                            background: item.color,
                            color: '#ffffff'
                          }
                        }
                      }}
                    >
                      <Box 
                        className="feature-icon"
                        sx={{
                          width: 48,
                          height: 48,
                          borderRadius: '12px',
                          background: `${item.color}15`,
                          color: item.color,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mb: 2,
                          transition: 'all 0.3s ease'
                        }}
                      >
                        {item.icon}
                      </Box>
                      <Typography variant="h6" sx={{ 
                        fontWeight: 600,
                        color: item.color,
                        mb: 1
                      }}>
                        {item.title}
                      </Typography>
                      <Typography variant="subtitle2" sx={{
                        color: 'text.secondary',
                        mb: 1,
                        fontWeight: 500
                      }}>
                        {item.subtitle}
                      </Typography>
                      <Typography variant="body2" sx={{
                        color: 'text.secondary',
                        lineHeight: 1.6
                      }}>
                        {item.description}
                      </Typography>
                    </Paper>
                  ))}
                </Box>
              </Grid>

              {/* CAPEX Highlight */}
              <Grid item xs={12} md={5}>
                <Box sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column', 
                  gap: 4 
                }}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 4,
                      borderRadius: '20px',
                      background: 'linear-gradient(135deg, #0066CC, #0099FF)',
                      color: 'white',
                      textAlign: 'center',
                      position: 'relative',
                      overflow: 'hidden'
                    }}
                  >
                    <Box sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '200%',
                      height: '200%',
                      background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)'
                    }} />
                    <Box sx={{ position: 'relative', zIndex: 1 }}>
                      <Typography variant="h4" sx={{ 
                        fontWeight: 700, 
                        mb: 2
                      }}>
                        Reduce CAPEX by
                      </Typography>
                      <Typography variant="h2" sx={{ 
                        fontWeight: 800,
                        mb: 2
                      }}>
                        15-20%
                      </Typography>
                      <Typography variant="body1" sx={{ opacity: 0.9 }}>
                        Through innovative design and efficient operation
                      </Typography>
                    </Box>
                  </Paper>

                  <Box sx={{ 
                    flex: 1,
                    position: 'relative',
                    borderRadius: '20px',
                    overflow: 'hidden',
                    boxShadow: '0 20px 40px rgba(0,0,0,0.1)'
                  }}>
                    <img 
                      src="/images/revolutesqample.jpg" 
                      alt="Advanced RO System" 
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                    <Box sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)',
                      color: 'white',
                      p: 3
                    }}>
                      <Typography variant="h5" sx={{ fontWeight: 600 }}>
                        Advanced RO System
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Brine Solution Technology Section */}
        <Box sx={{ my: 8 }}>
          {/* BRINEX Logo */}
          <Box sx={{ 
            width: '100%',
            maxWidth: '300px',
            mx: 'auto',
            mb: 6,
            '& img': {
              width: '100%',
              height: 'auto',
              filter: 'drop-shadow(0 4px 20px rgba(0,0,0,0.1))'
            }
          }}>
            <img src="/images/brinex.png" alt="BRINEX Logo" />
          </Box>

          <Typography 
            variant="h3" 
            sx={{ 
              textAlign: 'center',
              mb: 6,
              fontWeight: 700,
              background: 'linear-gradient(135deg, #0066CC, #0099FF)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            Advanced Technology to Recover Brine Solution
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                {[
                  {
                    icon: <WaterIcon />,
                    title: 'Brine Solution Recovery',
                    description: 'Recovers brine solution from the concentrate stream with high efficiency',
                    color: '#0066CC'
                  },
                  {
                    icon: <SpeedIcon />,
                    title: 'High Concentration Capability',
                    description: 'Recovered brine concentration up to 140 GPL',
                    color: '#00bcd4'
                  },
                  {
                    icon: <EngineeringIcon />,
                    title: 'Textile Processing Application',
                    description: 'Suitable as a direct replacement for salt in textile processing',
                    color: '#4caf50'
                  },
                  {
                    icon: <AutorenewIcon />,
                    title: 'Reduced Evaporator Load',
                    description: 'Significantly reduces the load on the evaporator system',
                    color: '#ff9800'
                  }
                ].map((item, index) => (
                  <Paper
                    key={index}
                    elevation={0}
                    sx={{
                      p: 3,
                      borderRadius: 3,
                      background: `linear-gradient(135deg, ${item.color}08, ${item.color}15)`,
                      backdropFilter: 'blur(20px)',
                      border: `1px solid ${item.color}20`,
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: `0 10px 30px ${item.color}20`,
                        background: `linear-gradient(135deg, ${item.color}12, ${item.color}20)`
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                      <Box sx={{
                        bgcolor: item.color,
                        color: 'white',
                        p: 1.5,
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignSelf: 'flex-start'
                      }}>
                        {React.cloneElement(item.icon, { sx: { fontSize: 24 } })}
                      </Box>
                      <Box>
                        <Typography variant="h6" sx={{ 
                          fontWeight: 600,
                          color: item.color,
                          mb: 1
                        }}>
                          {item.title}
                        </Typography>
                        <Typography variant="body1" sx={{
                          color: 'text.secondary',
                          lineHeight: 1.6
                        }}>
                          {item.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                ))}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper
                elevation={0}
                sx={{
                  p: 4,
                  borderRadius: 3,
                  height: '100%',
                  background: 'linear-gradient(135deg, #f5f5f5, #ffffff)',
                  border: '1px solid rgba(0,0,0,0.1)',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3
                }}
              >
                <Typography variant="h5" sx={{ 
                  fontWeight: 600,
                  color: 'text.primary',
                  mb: 2,
                  textAlign: 'center'
                }}>
                  Brine and Recovered Salt Samples
                </Typography>
                
                <Box sx={{ 
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: 4,
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1
                }}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    flex: 1
                  }}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 3,
                        borderRadius: 3,
                        overflow: 'hidden',
                        border: '1px solid rgba(0,0,0,0.1)',
                        width: '100%',
                        maxWidth: '320px',
                        aspectRatio: '1',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          bottom: '-15px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          width: '60px',
                          height: '4px',
                          background: 'linear-gradient(90deg, #0066CC, #00A3FF)',
                          borderRadius: '2px'
                        }
                      }}
                    >
                      <img 
                        src="/images/brinixsample.jpg" 
                        alt="Brine and Recovered Salt Sample"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                          borderRadius: '8px'
                        }}
                      />
                    </Paper>
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="h6" sx={{ color: 'text.primary', fontWeight: 600, mb: 1 }}>
                        Brine and Recovered Salt Sample
                      </Typography>
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        Showing brine solution and recovered salt comparison
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </BrightBackground>
  );
};

export default RoTreatment;
