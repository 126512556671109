import React from 'react';
import { Box, Container, Grid, Typography, Card, Button } from '@mui/material';
import { Sensors, Speed, Assessment, WaterDrop } from '@mui/icons-material';

const monitoringSections = [
  {
    title: "Real-time Sensor Network",
    description: "Live monitoring of distributed sensor network",
    demoUrl: "https://demo.waterai.example/sensor-network",
    icon: <Sensors />,
    metrics: {
      sensors: "1000+",
      updateRate: "1s",
      coverage: "100%",
      accuracy: "99.9%"
    }
  },
  {
    title: "Quality Parameters",
    description: "Continuous water quality monitoring",
    demoUrl: "https://demo.waterai.example/quality-monitoring",
    icon: <WaterDrop />,
    metrics: {
      parameters: "20+",
      sampling: "Real-time",
      alerts: "Instant",
      compliance: "100%"
    }
  },
  {
    title: "Performance Metrics",
    description: "System performance and efficiency monitoring",
    demoUrl: "https://demo.waterai.example/performance-metrics",
    icon: <Speed />,
    metrics: {
      efficiency: "95%+",
      uptime: "99.99%",
      response: "<100ms",
      availability: "24/7"
    }
  },
  {
    title: "Analytics Dashboard",
    description: "Comprehensive analytics and reporting",
    demoUrl: "https://demo.waterai.example/analytics",
    icon: <Assessment />,
    metrics: {
      insights: "Real-time",
      reports: "Automated",
      visualization: "Interactive",
      export: "Multiple formats"
    }
  }
];

const SmartMonitoring = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        {/* Header */}
        <Box sx={{ mb: 8, textAlign: 'center' }}>
          <Sensors sx={{ fontSize: 60, color: '#4CAF50', mb: 2 }} />
          <Typography variant="h2" gutterBottom>
            Smart Monitoring Demo
          </Typography>
          <Typography variant="h5" color="text.secondary" sx={{ mb: 4 }}>
            Advanced IoT-based monitoring system
          </Typography>
        </Box>

        {/* Monitoring Sections */}
        <Grid container spacing={4}>
          {monitoringSections.map((section, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{ 
                p: 3,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: '0 20px 40px rgba(76, 175, 80, 0.15)'
                }
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  {React.cloneElement(section.icon, { 
                    sx: { fontSize: 40, color: '#4CAF50', mr: 2 } 
                  })}
                  <Typography variant="h5">{section.title}</Typography>
                </Box>
                <Typography color="text.secondary" sx={{ mb: 3 }}>
                  {section.description}
                </Typography>

                {/* Metrics */}
                <Box sx={{ mb: 3, flex: 1 }}>
                  <Grid container spacing={2}>
                    {Object.entries(section.metrics).map(([key, value], idx) => (
                      <Grid item xs={6} key={idx}>
                        <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
                          {key}:
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                          {value}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                <Button 
                  variant="contained"
                  href={section.demoUrl}
                  target="_blank"
                  sx={{ 
                    bgcolor: '#4CAF50',
                    '&:hover': {
                      bgcolor: '#388E3C'
                    }
                  }}
                >
                  View Live Demo
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default SmartMonitoring;
