import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Card, Button, useTheme, useMediaQuery, CircularProgress, Divider, Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { DataUsage, Timeline, Assessment, Speed, ArrowForward, Memory, Storage, Cloud, Security, 
  PowerSettingsNew, Settings, Warning, CheckCircle, Build, DeviceHub, WaterDrop, WaterOutlined, Bolt, Science, Opacity, Thermostat } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, AreaChart, Area, BarChart, Bar, PieChart, Pie, Cell, CartesianGrid } from 'recharts';
import { useNavigate } from 'react-router-dom';

const demoLinks = [
  {
    title: 'Real-time Monitoring Dashboard',
    description: 'Interactive dashboard showing live process data',
    url: 'https://demo.scada.example/dashboard',
    icon: <Timeline />,
  },
  {
    title: 'Process Control Simulation',
    description: 'Test and simulate control algorithms',
    url: 'https://demo.scada.example/simulation',
    icon: <Speed />,
  },
  {
    title: 'Analytics Platform',
    description: 'Advanced analytics and reporting tools',
    url: 'https://demo.scada.example/analytics',
    icon: <Assessment />,
  }
];

const technicalSpecs = [
  {
    category: 'System Performance',
    specs: [
      { label: 'Response Time', value: '<100ms' },
      { label: 'Data Processing Rate', value: '100,000 points/sec' },
      { label: 'System Reliability', value: '99.99%' },
      { label: 'Concurrent Users', value: 'Up to 1000' }
    ]
  },
  {
    category: 'Integration Capabilities',
    specs: [
      { label: 'Supported Protocols', value: 'OPC UA, Modbus, BACnet, MQTT' },
      { label: 'API Type', value: 'RESTful, GraphQL' },
      { label: 'Data Formats', value: 'JSON, XML, CSV' },
      { label: 'Security', value: 'SSL/TLS, OAuth 2.0' }
    ]
  }
];

const features = [
  {
    title: 'Real-time Visualization',
    description: 'Dynamic HMI screens with real-time updates and interactive controls',
    details: [
      'Custom widget library',
      'Drag-and-drop designer',
      'Mobile-responsive layouts',
      'Multi-screen support'
    ]
  },
  {
    title: 'Advanced Analytics',
    description: 'Built-in analytics tools for data analysis and reporting',
    details: [
      'Trend analysis',
      'Statistical calculations',
      'Custom report builder',
      'Export capabilities'
    ]
  },
  {
    title: 'Process Control',
    description: 'Comprehensive control system with advanced algorithms',
    details: [
      'PID control loops',
      'Sequence control',
      'Batch processing',
      'Recipe management'
    ]
  }
];

const demoData = Array.from({ length: 7 }, (_, i) => ({
  name: `Day ${i + 1}`,
  value: Math.floor(Math.random() * 100) + 50
}));

// Mock data generators
const generateTimeSeriesData = (points) => {
  return Array.from({ length: points }, (_, i) => ({
    time: `${String(i).padStart(2, '0')}:00`,
    cpu: Math.floor(Math.random() * 30) + 40,
    memory: Math.floor(Math.random() * 20) + 60,
    network: Math.floor(Math.random() * 40) + 30,
  }));
};

const generateSystemMetrics = () => ({
  cpu: Math.floor(Math.random() * 30) + 40,
  memory: Math.floor(Math.random() * 20) + 60,
  storage: Math.floor(Math.random() * 15) + 70,
  network: Math.floor(Math.random() * 40) + 30,
});

const generateAlertData = () => ([
  { name: 'Critical', value: 2, color: '#f44336' },
  { name: 'Warning', value: 5, color: '#ffa726' },
  { name: 'Normal', value: 43, color: '#66bb6a' },
]);

const generateProcessData = () => ([
  { name: 'Process A', value: 85, status: 'running' },
  { name: 'Process B', value: 92, status: 'running' },
  { name: 'Process C', value: 78, status: 'warning' },
  { name: 'Process D', value: 95, status: 'running' },
]);

// Beckhoff PLC Data Generators
const generatePLCData = () => ({
  cpuTemp: Math.floor(Math.random() * 15) + 45,  // 45-60°C
  cycleTime: Math.floor(Math.random() * 5) + 1,  // 1-6ms
  taskStatus: Math.random() > 0.95 ? 'WARNING' : 'RUNNING',
  memoryUsage: Math.floor(Math.random() * 20) + 70, // 70-90%
  etherCATStatus: {
    devices: 12,
    activeDevices: Math.floor(Math.random() * 2) + 10, // 10-12
    errorCount: Math.floor(Math.random() * 3),
    cycleCount: Math.floor(Math.random() * 1000000)
  }
});

const generateIOData = () => ({
  digitalInputs: Array.from({ length: 16 }, () => Math.random() > 0.5),
  digitalOutputs: Array.from({ length: 16 }, () => Math.random() > 0.5),
  analogInputs: Array.from({ length: 8 }, () => Math.floor(Math.random() * 10000)),
  analogOutputs: Array.from({ length: 4 }, () => Math.floor(Math.random() * 10000))
});

const generateTwinCATData = () => ({
  status: 'Config',
  runMode: 'Run',
  realTimeClock: new Date().toLocaleTimeString(),
  systemLatency: Math.floor(Math.random() * 20) + 10, // 10-30µs
  tasks: [
    { name: 'PlcTask', cycleTime: 10, priority: 1, status: 'Running' },
    { name: 'NC-Task', cycleTime: 2, priority: 2, status: 'Running' },
    { name: 'IO-Task', cycleTime: 1, priority: 3, status: 'Running' }
  ]
});

const generateMotionData = () => {
  return Array.from({ length: 4 }, (_, i) => ({
    axis: `Axis ${i + 1}`,
    position: Math.floor(Math.random() * 1000),
    velocity: Math.floor(Math.random() * 100),
    torque: Math.floor(Math.random() * 80),
    status: Math.random() > 0.95 ? 'Warning' : 'Running'
  }));
};

const ScadaDemo = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [timeSeriesData, setTimeSeriesData] = useState(generateTimeSeriesData(24));
  const [systemMetrics, setSystemMetrics] = useState(generateSystemMetrics());
  const [alertData] = useState(generateAlertData());
  const [processData] = useState(generateProcessData());
  const [plcData, setPLCData] = useState(generatePLCData());
  const [ioData, setIOData] = useState(generateIOData());
  const [twinCATData, setTwinCATData] = useState(generateTwinCATData());
  const [motionData, setMotionData] = useState(generateMotionData());

  // Simulate real-time updates
  useEffect(() => {
    const interval = setInterval(() => {
      setPLCData(generatePLCData());
      setIOData(generateIOData());
      setTwinCATData(generateTwinCATData());
      setMotionData(generateMotionData());
      setTimeSeriesData(prev => {
        const newData = [...prev.slice(1), {
          time: new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
          cpu: Math.floor(Math.random() * 30) + 40,
          memory: Math.floor(Math.random() * 20) + 60,
          network: Math.floor(Math.random() * 40) + 30,
        }];
        return newData;
      });
      setSystemMetrics(generateSystemMetrics());
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const StatusIndicator = ({ status }) => (
    <Box sx={{ 
      display: 'inline-flex', 
      alignItems: 'center',
      bgcolor: status === 'RUNNING' ? '#4caf50' : status === 'WARNING' ? '#ff9800' : '#f44336',
      color: 'white',
      px: 1,
      py: 0.5,
      borderRadius: 1,
      fontSize: '0.875rem'
    }}>
      {status === 'RUNNING' ? <CheckCircle sx={{ fontSize: 16, mr: 0.5 }} /> : <Warning sx={{ fontSize: 16, mr: 0.5 }} />}
      {status}
    </Box>
  );

  const MetricCard = ({ title, value, icon, color }) => (
    <Card sx={{ p: 2, height: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        {icon}
        <Typography variant="h6" sx={{ ml: 1 }}>{title}</Typography>
      </Box>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          value={value}
          size={80}
          thickness={8}
          sx={{ color }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h6" component="div" color="text.secondary">
            {value}%
          </Typography>
        </Box>
      </Box>
    </Card>
  );

  return (
    <Box sx={{ overflow: 'hidden', bgcolor: '#f8fafc' }}>
      {/* Hero Section with Animated Background */}
      <Box
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        sx={{
          position: 'relative',
          py: { xs: 10, md: 15 },
          background: 'linear-gradient(135deg, #1a237e 0%, #0097a7 100%)',
          color: 'white',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'url("/circuit-pattern.svg")',
            opacity: 0.1,
            animation: 'float 20s linear infinite',
          }
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.8 }}
              >
                <Typography variant="h2" gutterBottom sx={{ fontWeight: 700 }}>
                  Next-Gen SCADA Solutions
                </Typography>
                <Typography variant="h5" sx={{ mb: 4, opacity: 0.9 }}>
                  Intelligent monitoring and control systems powered by AI
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  endIcon={<ArrowForward />}
                  onClick={() => navigate('/water-treatment-login')}
                  sx={{
                    bgcolor: 'white',
                    color: '#1a237e',
                    '&:hover': {
                      bgcolor: 'rgba(255,255,255,0.9)',
                    }
                  }}
                >
                  Explore Demo
                </Button>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ height: 300, p: 2, bgcolor: 'rgba(255,255,255,0.1)', borderRadius: 2 }}>
                <Typography variant="h6" gutterBottom sx={{ color: 'rgba(255,255,255,0.9)' }}>
                  System Performance
                </Typography>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={timeSeriesData}>
                    <XAxis dataKey="time" stroke="rgba(255,255,255,0.5)" />
                    <YAxis stroke="rgba(255,255,255,0.5)" />
                    <Tooltip 
                      contentStyle={{ 
                        backgroundColor: 'rgba(255,255,255,0.9)',
                        border: 'none',
                        borderRadius: 8
                      }}
                    />
                    <Line
                      type="monotone"
                      dataKey="cpu"
                      stroke="#4fc3f7"
                      strokeWidth={3}
                      dot={{ fill: '#4fc3f7', strokeWidth: 2 }}
                    />
                    <Line
                      type="monotone"
                      dataKey="memory"
                      stroke="#81c784"
                      strokeWidth={3}
                      dot={{ fill: '#81c784', strokeWidth: 2 }}
                    />
                    <Line
                      type="monotone"
                      dataKey="network"
                      stroke="#fff176"
                      strokeWidth={3}
                      dot={{ fill: '#fff176', strokeWidth: 2 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 4 }}>
        {/* TwinCAT Status Section */}
        <Paper 
          elevation={0}
          sx={{ 
            p: 3, 
            mb: 4, 
            borderRadius: 4,
            background: 'linear-gradient(145deg, #ffffff 0%, #f8fafc 100%)',
            border: '1px solid rgba(0,0,0,0.1)',
            boxShadow: '0 4px 20px rgba(0,0,0,0.05)'
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ 
                background: 'linear-gradient(45deg, #1a237e, #0097a7)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 600
              }}>
                TwinCAT Status
              </Typography>
              <Box sx={{ mt: 2 }}>
                <StatusIndicator status={plcData.taskStatus} />
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={3}>
                {[
                  { label: 'System Time', value: twinCATData.realTimeClock },
                  { label: 'Run Mode', value: twinCATData.runMode },
                  { label: 'Latency', value: `${twinCATData.systemLatency}µs` },
                  { label: 'Cycle Time', value: `${plcData.cycleTime}ms` }
                ].map((item, index) => (
                  <Grid item xs={6} md={3} key={index}>
                    <Box sx={{ p: 2, borderRadius: 2, bgcolor: 'rgba(0,0,0,0.02)', transition: 'all 0.3s ease', '&:hover': { bgcolor: 'rgba(0,0,0,0.04)', transform: 'translateY(-2px)' } }}>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        {item.label}
                      </Typography>
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {item.value}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        {/* EtherCAT Network Status */}
        <Paper 
          elevation={0}
          sx={{ 
            p: 3, 
            mb: 4, 
            borderRadius: 4,
            background: 'linear-gradient(145deg, #ffffff 0%, #f8fafc 100%)',
            border: '1px solid rgba(0,0,0,0.1)',
            boxShadow: '0 4px 20px rgba(0,0,0,0.05)'
          }}
        >
          <Typography variant="h6" gutterBottom sx={{
            background: 'linear-gradient(45deg, #1a237e, #0097a7)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontWeight: 600
          }}>
            EtherCAT Network
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Box sx={{
                p: 3,
                borderRadius: 3,
                bgcolor: '#fff',
                boxShadow: '0 4px 15px rgba(0,0,0,0.05)',
              }}>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Active Devices
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 600, color: '#1a237e' }}>
                  {plcData.etherCATStatus.activeDevices}
                  <Typography component="span" variant="h6" color="text.secondary">
                    /{plcData.etherCATStatus.devices}
                  </Typography>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{
                p: 3,
                borderRadius: 3,
                bgcolor: '#fff',
                boxShadow: '0 4px 15px rgba(0,0,0,0.05)',
              }}>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Error Count
                </Typography>
                <Typography 
                  variant="h3" 
                  sx={{ 
                    fontWeight: 600,
                    color: plcData.etherCATStatus.errorCount > 0 ? '#f44336' : '#4caf50'
                  }}
                >
                  {plcData.etherCATStatus.errorCount}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{
                p: 3,
                borderRadius: 3,
                bgcolor: '#fff',
                boxShadow: '0 4px 15px rgba(0,0,0,0.05)',
                height: '100%'
              }}>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Network Status
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1.5 }}>
                  {Array.from({ length: 12 }).map((_, i) => (
                    <Box
                      key={i}
                      sx={{
                        width: 32,
                        height: 32,
                        borderRadius: 2,
                        bgcolor: i < plcData.etherCATStatus.activeDevices ? '#4caf50' : '#ff9800',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        fontWeight: 600,
                        fontSize: '0.875rem',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.1)',
                          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                        }
                      }}
                    >
                      {i + 1}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        {/* Motion Control Status */}
        <Box sx={{ mt: 6 }}>
          <Typography 
            variant="h5" 
            gutterBottom 
            sx={{ 
              color: '#00519E',
              mb: 3,
              fontWeight: 600
            }}
          >
            Motion Control Status
          </Typography>
          <Grid container spacing={3}>
            {[
              {
                axis: 'Axis 1',
                status: 'Running',
                position: 846,
                velocity: 94,
                torque: 56
              },
              {
                axis: 'Axis 2',
                status: 'Running',
                position: 508,
                velocity: 71,
                torque: 28
              },
              {
                axis: 'Axis 3',
                status: 'Running',
                position: 565,
                velocity: 95,
                torque: 11
              },
              {
                axis: 'Axis 4',
                status: 'Running',
                position: 293,
                velocity: 60,
                torque: 0
              }
            ].map((axis, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.2 }}
                >
                  <Paper 
                    elevation={0} 
                    sx={{ 
                      p: 3,
                      bgcolor: 'rgba(0,81,158,0.03)',
                      border: '1px solid rgba(0,81,158,0.1)',
                      borderRadius: 2,
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: '0 8px 24px rgba(0,81,158,0.12)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Typography variant="h6" sx={{ color: '#00519E', fontWeight: 600 }}>
                        {axis.axis}
                      </Typography>
                      <Box 
                        sx={{ 
                          px: 1.5,
                          py: 0.5,
                          borderRadius: 1,
                          bgcolor: '#4caf50',
                          color: 'white',
                          fontSize: '0.75rem',
                          fontWeight: 500
                        }}
                      >
                        {axis.status}
                      </Box>
                    </Box>
                    
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="body2" color="text.secondary" gutterBottom>
                            Position
                          </Typography>
                          <Typography variant="h6" sx={{ color: '#00519E', fontWeight: 500 }}>
                            {axis.position}
                          </Typography>
                        </Box>
                      </Grid>
                      
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          Velocity
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Speed sx={{ color: '#00519E', mr: 1, fontSize: 20 }} />
                          <Typography variant="body1" sx={{ color: '#00519E', fontWeight: 500 }}>
                            {axis.velocity}
                          </Typography>
                        </Box>
                      </Grid>
                      
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          Torque
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Settings sx={{ color: '#00519E', mr: 1, fontSize: 20 }} />
                          <Typography variant="body1" sx={{ color: '#00519E', fontWeight: 500 }}>
                            {axis.torque}%
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* I/O Status */}
        <Paper 
          elevation={0}
          sx={{ 
            p: 3, 
            mb: 4, 
            borderRadius: 4,
            background: 'linear-gradient(145deg, #ffffff 0%, #f8fafc 100%)',
            border: '1px solid rgba(0,0,0,0.1)',
            boxShadow: '0 4px 20px rgba(0,0,0,0.05)'
          }}
        >
          <Typography variant="h6" gutterBottom sx={{
            background: 'linear-gradient(45deg, #1a237e, #0097a7)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontWeight: 600
          }}>
            I/O Status
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box sx={{
                p: 3,
                borderRadius: 3,
                bgcolor: '#fff',
                boxShadow: '0 4px 15px rgba(0,0,0,0.05)',
              }}>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600, color: '#1a237e' }}>
                  Digital I/O
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Inputs
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {ioData.digitalInputs.map((state, i) => (
                        <Box
                          key={i}
                          sx={{
                            width: 36,
                            height: 36,
                            borderRadius: 1.5,
                            bgcolor: state ? '#4caf50' : '#f44336',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            fontWeight: 600,
                            fontSize: '0.875rem',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              transform: 'scale(1.1)',
                              boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                            }
                          }}
                        >
                          {i}
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Outputs
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {ioData.digitalOutputs.map((state, i) => (
                        <Box
                          key={i}
                          sx={{
                            width: 36,
                            height: 36,
                            borderRadius: 1.5,
                            bgcolor: state ? '#2196f3' : '#90a4ae',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            fontWeight: 600,
                            fontSize: '0.875rem',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              transform: 'scale(1.1)',
                              boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                            }
                          }}
                        >
                          {i}
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{
                p: 3,
                borderRadius: 3,
                bgcolor: '#fff',
                boxShadow: '0 4px 15px rgba(0,0,0,0.05)',
                height: '100%'
              }}>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600, color: '#1a237e' }}>
                  Analog I/O
                </Typography>
                <Box sx={{ height: 250 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={[
                      ...ioData.analogInputs.map((value, i) => ({ name: `AI${i}`, value })),
                      ...ioData.analogOutputs.map((value, i) => ({ name: `AO${i}`, value }))
                    ]}>
                      <CartesianGrid strokeDasharray="3 3" stroke="rgba(0,0,0,0.1)" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip 
                        contentStyle={{ 
                          backgroundColor: '#fff',
                          border: 'none',
                          borderRadius: 8,
                          boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                        }} 
                      />
                      <Bar dataKey="value">
                        {ioData.analogInputs.concat(ioData.analogOutputs).map((_, index) => (
                          <Cell 
                            key={`cell-${index}`} 
                            fill={index < ioData.analogInputs.length ? '#1a237e' : '#0097a7'} 
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        {/* Beckhoff Water Treatment Section */}
        <Box 
          sx={{ 
            p: 4, 
            mb: 6, 
            borderRadius: 3,
            background: 'linear-gradient(145deg, #ffffff 0%, #f8fafc 100%)',
            boxShadow: '0 4px 24px rgba(0,81,158,0.08)',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          {/* Header */}
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              mb: 4,
              pb: 3,
              borderBottom: '2px solid rgba(0,81,158,0.1)'
            }}
          >
            <Box>
              <Typography 
                variant="h4" 
                sx={{ 
                  fontWeight: 600,
                  background: 'linear-gradient(90deg, #00519E 0%, #0077CC 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  mb: 1
                }}
              >
                Beckhoff Water Treatment Automation
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                Advanced control and monitoring using TwinCAT 3
              </Typography>
            </Box>
            <Box 
              component="img"
              src="https://img.officer.com/files/base/ebm/automationworld/image/2020/10/beckhoff_logo_red_300dpi.5f84b89a5d83e.png?auto=format%2Ccompress&w=640&width=640"
              alt="Beckhoff"
              sx={{ 
                height: 45,
                objectFit: 'contain'
              }}
            />
          </Box>

          {/* Main Features Grid */}
          <Grid container spacing={4}>
            {/* TwinCAT Runtime */}
            <Grid item xs={12} md={4}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3,
                  height: '100%',
                  bgcolor: 'rgba(0,81,158,0.02)',
                  border: '1px solid rgba(0,81,158,0.1)',
                  borderRadius: 2,
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 8px 24px rgba(0,81,158,0.12)'
                  }
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                  <Box 
                    sx={{ 
                      p: 1.5,
                      borderRadius: 2,
                      bgcolor: 'rgba(0,81,158,0.1)',
                      mr: 2
                    }}
                  >
                    <Memory sx={{ color: '#00519E', fontSize: 28 }} />
                  </Box>
                  <Typography variant="h6" sx={{ color: '#00519E', fontWeight: 600 }}>
                    TwinCAT Runtime
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                  Real-time control and monitoring using TwinCAT 3
                </Typography>
                <List sx={{ '& .MuiListItem-root': { px: 0, py: 0.5 } }}>
                  {['PLC Runtime', 'NC Runtime', 'Safety Runtime', 'Vision Runtime'].map((item, index) => (
                    <ListItem key={index}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircle sx={{ color: '#00519E', fontSize: 20 }} />
                      </ListItemIcon>
                      <ListItemText 
                        primary={item} 
                        primaryTypographyProps={{
                          variant: 'body2',
                          sx: { color: 'text.primary' }
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>

            {/* EtherCAT Network */}
            <Grid item xs={12} md={4}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3,
                  height: '100%',
                  bgcolor: 'rgba(0,81,158,0.02)',
                  border: '1px solid rgba(0,81,158,0.1)',
                  borderRadius: 2,
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 8px 24px rgba(0,81,158,0.12)'
                  }
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                  <Box 
                    sx={{ 
                      p: 1.5,
                      borderRadius: 2,
                      bgcolor: 'rgba(0,81,158,0.1)',
                      mr: 2
                    }}
                  >
                    <Storage sx={{ color: '#00519E', fontSize: 28 }} />
                  </Box>
                  <Typography variant="h6" sx={{ color: '#00519E', fontWeight: 600 }}>
                    EtherCAT Network
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                  High-speed industrial communication
                </Typography>
                <List sx={{ '& .MuiListItem-root': { px: 0, py: 0.5 } }}>
                  {[
                    'Distributed I/O',
                    'Motor Control',
                    'Process Instruments',
                    'Safety Devices'
                  ].map((item, index) => (
                    <ListItem key={index}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircle sx={{ color: '#00519E', fontSize: 20 }} />
                      </ListItemIcon>
                      <ListItemText 
                        primary={item}
                        primaryTypographyProps={{
                          variant: 'body2',
                          sx: { color: 'text.primary' }
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>

            {/* Process Control */}
            <Grid item xs={12} md={4}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3,
                  height: '100%',
                  bgcolor: 'rgba(0,81,158,0.02)',
                  border: '1px solid rgba(0,81,158,0.1)',
                  borderRadius: 2,
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 8px 24px rgba(0,81,158,0.12)'
                  }
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                  <Box 
                    sx={{ 
                      p: 1.5,
                      borderRadius: 2,
                      bgcolor: 'rgba(0,81,158,0.1)',
                      mr: 2
                    }}
                  >
                    <Settings sx={{ color: '#00519E', fontSize: 28 }} />
                  </Box>
                  <Typography variant="h6" sx={{ color: '#00519E', fontWeight: 600 }}>
                    Process Control
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                  Advanced water treatment control algorithms
                </Typography>
                <List sx={{ '& .MuiListItem-root': { px: 0, py: 0.5 } }}>
                  {[
                    'PID Control',
                    'Flow Control',
                    'Level Control',
                    'Chemical Dosing'
                  ].map((item, index) => (
                    <ListItem key={index}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircle sx={{ color: '#00519E', fontSize: 20 }} />
                      </ListItemIcon>
                      <ListItemText 
                        primary={item}
                        primaryTypographyProps={{
                          variant: 'body2',
                          sx: { color: 'text.primary' }
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          </Grid>

          {/* Treatment Process Parameters */}
          <Box sx={{ mt: 6 }}>
            <Typography 
              variant="h5" 
              sx={{ 
                color: '#00519E',
                fontWeight: 600,
                mb: 3,
                display: 'flex',
                alignItems: 'center',
                '&::before': {
                  content: '""',
                  width: 4,
                  height: 24,
                  bgcolor: '#00519E',
                  mr: 2,
                  borderRadius: 1
                }
              }}
            >
              Treatment Process Parameters
            </Typography>
            <Grid container spacing={3}>
              {[
                { label: 'Raw Water Flow', value: '120 m³/h', icon: <WaterDrop /> },
                { label: 'System Pressure', value: '2.5 bar', icon: <Speed /> },
                { label: 'Conductivity', value: '850 µS/cm', icon: <Bolt /> },
                { label: 'pH Level', value: '7.2', icon: <Science /> },
                { label: 'Turbidity', value: '0.8 NTU', icon: <Opacity /> },
                { label: 'Temperature', value: '25°C', icon: <Thermostat /> }
              ].map((param, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
                  <Paper 
                    elevation={0}
                    sx={{
                      p: 2.5,
                      bgcolor: 'rgba(0,81,158,0.02)',
                      border: '1px solid rgba(0,81,158,0.1)',
                      borderRadius: 2,
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: '0 8px 24px rgba(0,81,158,0.12)',
                        bgcolor: 'rgba(0,81,158,0.04)'
                      }
                    }}
                  >
                    <Box 
                      sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1.5
                      }}
                    >
                      <Box 
                        sx={{ 
                          p: 1,
                          borderRadius: 1.5,
                          bgcolor: 'rgba(0,81,158,0.1)',
                          mr: 1.5,
                          display: 'flex'
                        }}
                      >
                        {React.cloneElement(param.icon, { 
                          sx: { 
                            fontSize: 20,
                            color: '#00519E'
                          }
                        })}
                      </Box>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          color: 'text.secondary',
                          fontWeight: 500
                        }}
                      >
                        {param.label}
                      </Typography>
                    </Box>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        color: '#00519E',
                        fontWeight: 600,
                        pl: 0.5
                      }}
                    >
                      {param.value}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        {/* System Metrics Section */}
        <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
          System Metrics
        </Typography>
        <Grid container spacing={3} sx={{ mb: 6 }}>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard
              title="CPU Usage"
              value={systemMetrics.cpu}
              icon={<Memory sx={{ color: '#2196f3' }} />}
              color="#2196f3"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard
              title="Memory"
              value={systemMetrics.memory}
              icon={<Storage sx={{ color: '#4caf50' }} />}
              color="#4caf50"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard
              title="Storage"
              value={systemMetrics.storage}
              icon={<Cloud sx={{ color: '#ff9800' }} />}
              color="#ff9800"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MetricCard
              title="Network"
              value={systemMetrics.network}
              icon={<Security sx={{ color: '#9c27b0' }} />}
              color="#9c27b0"
            />
          </Grid>
        </Grid>

        {/* Charts Section */}
        <Grid container spacing={3} sx={{ mb: 6 }}>
          {/* Time Series Chart */}
          <Grid item xs={12} lg={8}>
            <Card sx={{ p: 3, height: '100%' }}>
              <Typography variant="h6" gutterBottom>System Performance</Typography>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={timeSeriesData}>
                    <XAxis dataKey="time" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="cpu" stroke="#2196f3" strokeWidth={2} />
                    <Line type="monotone" dataKey="memory" stroke="#4caf50" strokeWidth={2} />
                    <Line type="monotone" dataKey="network" stroke="#9c27b0" strokeWidth={2} />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </Card>
          </Grid>

          {/* Alert Distribution */}
          <Grid item xs={12} lg={4}>
            <Card sx={{ p: 3, height: '100%' }}>
              <Typography variant="h6" gutterBottom>Alert Distribution</Typography>
              <Box sx={{ height: 300, display: 'flex', justifyContent: 'center' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={alertData}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {alertData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                {alertData.map((item) => (
                  <Box key={item.name} sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
                    <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: item.color, mr: 1 }} />
                    <Typography variant="body2">{item.name}</Typography>
                  </Box>
                ))}
              </Box>
            </Card>
          </Grid>
        </Grid>

        {/* Interactive Demos Section */}
        <Box sx={{ mb: 8, mt: 6 }}>
          <Box
            sx={{
              mb: 8,
              textAlign: 'center',
              position: 'relative'
            }}
          >
            <Typography
              variant="overline"
              sx={{
                color: '#00519E',
                fontSize: '1.1rem',
                fontWeight: 600,
                letterSpacing: '4px',
                opacity: 0.9,
                mb: 1,
                display: 'block'
              }}
            >
              EXPLORE OUR
            </Typography>
            <Typography 
              variant="h2"
              sx={{ 
                fontWeight: 800,
                background: 'linear-gradient(135deg, #00519E 0%, #0077CC 50%, #00519E 100%)',
                backgroundSize: '200% auto',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                animation: 'gradient 4s linear infinite',
                '@keyframes gradient': {
                  '0%': {
                    backgroundPosition: '0% center'
                  },
                  '100%': {
                    backgroundPosition: '200% center'
                  }
                },
                position: 'relative',
                fontSize: '3.5rem',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -16,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: 120,
                  height: 4,
                  borderRadius: '4px',
                  background: 'linear-gradient(90deg, transparent, #00519E, transparent)'
                }
              }}
            >
              Interactive
            </Typography>
            <Box
              sx={{
                width: '100%',
                height: '1px',
                background: 'linear-gradient(90deg, transparent, rgba(0,81,158,0.2), transparent)',
                mt: 6
              }}
            />
          </Box>
          <Grid container spacing={4}>
            {[
              {
                icon: <Timeline />,
                title: 'Real-time Monitoring Dashboard',
                description: 'Interactive dashboard showing live process data',
                gradient: 'linear-gradient(135deg, #00897B 0%, #009688 100%)',
                shadowColor: 'rgba(0,137,123,0.3)',
                lightColor: 'rgba(0,137,123,0.1)'
              },
              {
                icon: <Settings />,
                title: 'Process Control Simulation',
                description: 'Test and simulate control algorithms',
                gradient: 'linear-gradient(135deg, #1976D2 0%, #2196F3 100%)',
                shadowColor: 'rgba(25,118,210,0.3)',
                lightColor: 'rgba(25,118,210,0.1)'
              },
              {
                icon: <Assessment />,
                title: 'Analytics Platform',
                description: 'Advanced analytics and reporting tools',
                gradient: 'linear-gradient(135deg, #0097A7 0%, #00BCD4 100%)',
                shadowColor: 'rgba(0,151,167,0.3)',
                lightColor: 'rgba(0,151,167,0.1)'
              }
            ].map((demo, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Box
                  sx={{
                    height: '100%',
                    position: 'relative',
                    cursor: 'pointer',
                    '&:hover .demo-card': {
                      transform: 'translateY(-12px)',
                      boxShadow: `0 24px 40px ${demo.shadowColor}`,
                      '& .demo-icon': {
                        transform: 'scale(1.1) rotate(5deg)'
                      }
                    }
                  }}
                >
                  <Paper
                    className="demo-card"
                    elevation={0}
                    sx={{
                      height: '100%',
                      p: 4,
                      borderRadius: 4,
                      background: '#fff',
                      border: '1px solid rgba(0,0,0,0.08)',
                      transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                      position: 'relative',
                      overflow: 'hidden',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: 6,
                        background: demo.gradient
                      },
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: demo.lightColor,
                        opacity: 0,
                        transition: 'opacity 0.4s ease',
                        zIndex: 0
                      },
                      '&:hover::after': {
                        opacity: 1
                      }
                    }}
                  >
                    <Box sx={{ position: 'relative', zIndex: 1 }}>
                      <Box 
                        className="demo-icon"
                        sx={{ 
                          mb: 3,
                          width: 70,
                          height: 70,
                          borderRadius: 3,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: demo.gradient,
                          boxShadow: `0 8px 16px ${demo.shadowColor}`,
                          transition: 'transform 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                          '& svg': {
                            fontSize: 35,
                            color: '#fff'
                          }
                        }}
                      >
                        {demo.icon}
                      </Box>

                      <Typography 
                        variant="h5" 
                        gutterBottom
                        sx={{ 
                          fontWeight: 600,
                          mb: 2,
                          background: demo.gradient,
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          display: 'inline-block'
                        }}
                      >
                        {demo.title}
                      </Typography>

                      <Typography 
                        variant="body1"
                        sx={{ 
                          color: 'text.secondary',
                          lineHeight: 1.7,
                          fontSize: '1.1rem'
                        }}
                      >
                        {demo.description}
                      </Typography>
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Technical Specifications */}
        <Typography variant="h3" gutterBottom sx={{ mb: 4 }}>
          Technical Specifications
        </Typography>
        <Grid container spacing={4} sx={{ mb: 8 }}>
          {technicalSpecs.map((category, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{ p: 3 }}>
                <Typography variant="h5" gutterBottom color="primary">
                  {category.category}
                </Typography>
                <Grid container spacing={2}>
                  {category.specs.map((spec, idx) => (
                    <Grid item xs={12} key={idx}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
                        <Typography variant="body1" color="text.secondary">
                          {spec.label}
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                          {spec.value}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Features Section */}
        <Typography variant="h3" gutterBottom sx={{ mb: 4 }}>
          Key Features
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ p: 3, height: '100%' }}>
                <Typography variant="h5" gutterBottom color="primary">
                  {feature.title}
                </Typography>
                <Typography color="text.secondary" paragraph>
                  {feature.description}
                </Typography>
                <Box component="ul" sx={{ pl: 2 }}>
                  {feature.details.map((detail, idx) => (
                    <Typography component="li" key={idx} sx={{ mb: 1 }}>
                      {detail}
                    </Typography>
                  ))}
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ScadaDemo;
