import React from 'react';
import { Box, Container, Grid, Typography, Card, Button } from '@mui/material';
import { Settings, Timeline, TrendingUp, Speed } from '@mui/icons-material';

const demoLinks = [
  {
    title: 'Real-time Quality Analysis',
    description: 'Live monitoring of water quality parameters',
    url: 'https://demo.aiwater.example/quality-analysis',
    icon: <Timeline />,
    color: '#2196F3',
    parameters: [
      'pH: 6.5-8.5',
      'Turbidity: <1 NTU',
      'Dissolved Oxygen: >6 mg/L',
      'Conductivity: <1000 µS/cm'
    ]
  },
  {
    title: 'Flow Optimization',
    description: 'AI-driven flow rate optimization',
    url: 'https://demo.aiwater.example/flow',
    icon: <Speed />,
    color: '#4CAF50',
    parameters: [
      'Flow Rate: 0-1000 m³/h',
      'Pressure: 0-10 bar',
      'Temperature: 0-50°C',
      'Velocity: 0.3-3 m/s'
    ]
  },
  {
    title: 'Energy Management',
    description: 'Smart energy consumption monitoring',
    url: 'https://demo.aiwater.example/energy',
    icon: <TrendingUp />,
    color: '#FF9800',
    parameters: [
      'Power Usage: 0-1000 kW',
      'Efficiency: 85-95%',
      'Peak Load: <800 kW',
      'Energy Cost: $/kWh'
    ]
  },
  {
    title: 'Resource Optimization',
    description: 'Optimal resource allocation system',
    url: 'https://demo.aiwater.example/resource',
    icon: <Settings />,
    color: '#9C27B0',
    parameters: [
      'Chemical Usage: L/h',
      'Water Recovery: >95%',
      'Waste Generation: <5%',
      'Operating Cost: $/m³'
    ]
  }
];

const ProcessControl = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        {/* Header */}
        <Box sx={{ mb: 8, textAlign: 'center' }}>
          <Settings sx={{ fontSize: 60, color: '#3F51B5', mb: 2 }} />
          <Typography variant="h2" gutterBottom>
            Process Control Demo
          </Typography>
          <Typography variant="h5" color="text.secondary" sx={{ mb: 4 }}>
            Advanced process control and optimization systems
          </Typography>
        </Box>

        {/* Demo Links */}
        <Grid container spacing={4}>
          {demoLinks.map((demo, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{ 
                p: 3,
                height: '100%',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: `0 20px 40px ${demo.color}15`
                }
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  {React.cloneElement(demo.icon, { sx: { fontSize: 40, color: demo.color, mr: 2 } })}
                  <Typography variant="h5">{demo.title}</Typography>
                </Box>
                <Typography color="text.secondary" paragraph>
                  {demo.description}
                </Typography>
                
                {/* Parameters */}
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Control Parameters
                  </Typography>
                  <Grid container spacing={1}>
                    {demo.parameters.map((param, idx) => (
                      <Grid item xs={12} sm={6} key={idx}>
                        <Typography variant="body2" color="text.secondary">
                          • {param}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                <Button 
                  variant="contained" 
                  href={demo.url}
                  target="_blank"
                  sx={{ 
                    bgcolor: demo.color,
                    '&:hover': {
                      bgcolor: demo.color,
                      opacity: 0.9
                    }
                  }}
                >
                  Try Demo
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ProcessControl;
