import React, { useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Chip,
  Stack,
  CircularProgress,
  Alert,
  Snackbar,
} from '@mui/material';
import {
  Work,
  LocationOn,
  Timer,
  School,
  CheckCircle,
  Send,
  ArrowForward,
  BusinessCenter,
  EmojiObjects,
  GroupAdd,
  TrendingUp,
} from '@mui/icons-material';
import { useCareer } from '../context/CareerContext';

const benefits = [
  {
    icon: <BusinessCenter sx={{ fontSize: 40 }} />,
    title: "Professional Growth",
    description: "Continuous learning and development opportunities"
  },
  {
    icon: <EmojiObjects sx={{ fontSize: 40 }} />,
    title: "Innovation Culture",
    description: "Work with cutting-edge water treatment technologies"
  },
  {
    icon: <GroupAdd sx={{ fontSize: 40 }} />,
    title: "Collaborative Environment",
    description: "Be part of a dynamic and supportive team"
  },
  {
    icon: <TrendingUp sx={{ fontSize: 40 }} />,
    title: "Career Advancement",
    description: "Clear path for growth and promotions"
  }
];

const Careers = () => {
  const { jobs, loading, error, addApplication } = useCareer();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [applicationForm, setApplicationForm] = useState({
    name: '',
    email: '',
    phone: '',
    experience: '',
    resume: null,
    coverLetter: ''
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleApply = (job) => {
    setSelectedJob(job);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedJob(null);
    setApplicationForm({
      name: '',
      email: '',
      phone: '',
      experience: '',
      resume: null,
      coverLetter: ''
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApplicationForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addApplication({
        ...applicationForm,
        jobId: selectedJob.id,
        position: selectedJob.title
      });
      setSnackbar({
        open: true,
        message: 'Application submitted successfully! We will contact you soon.',
        severity: 'success'
      });
      handleCloseDialog();
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || 'Failed to submit application. Please try again.',
        severity: 'error'
      });
    }
  };

  if (loading) {
    return (
      <Box sx={{ 
        minHeight: '60vh', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  const activeJobs = jobs.filter(job => job.status === 'Active');

  return (
    <Box sx={{ 
      py: 8,
      background: 'linear-gradient(135deg, #f5f7fa 0%, #ffffff 100%)',
      minHeight: '100vh'
    }}>
      <Container maxWidth="xl">
        {/* Hero Section */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography 
            variant="h2" 
            gutterBottom
            sx={{
              fontWeight: 700,
              background: 'linear-gradient(45deg, #1a237e 30%, #0288d1 90%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            Join Our Team
          </Typography>
          <Typography variant="h5" color="text.secondary" sx={{ maxWidth: 800, mx: 'auto' }}>
            Be part of a team that's revolutionizing water treatment technology and making a positive impact on the environment
          </Typography>
        </Box>

        {/* Benefits Section */}
        <Box sx={{ mb: 8 }}>
          <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', mb: 4 }}>
            Why Work With Us?
          </Typography>
          <Grid container spacing={4}>
            {benefits.map((benefit, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card 
                  sx={{ 
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    p: 3,
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 4,
                      transition: 'all 0.3s ease'
                    }
                  }}
                >
                  <Box sx={{ color: 'primary.main', mb: 2 }}>
                    {benefit.icon}
                  </Box>
                  <Typography variant="h6" gutterBottom>
                    {benefit.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {benefit.description}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Open Positions */}
        <Box sx={{ mb: 8 }}>
          <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', mb: 4 }}>
            Open Positions
          </Typography>
          {activeJobs.length === 0 ? (
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Typography variant="h6" color="text.secondary">
                No open positions at the moment.
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                Please check back later for new opportunities.
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={3} sx={{ mt: 2 }}>
              {activeJobs.map((job) => (
                <Grid item xs={12} key={job.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5" component="h2" gutterBottom>
                        {job.title}
                      </Typography>
                      <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                        <Chip label={job.department} color="primary" size="small" />
                        <Chip label={job.location} size="small" />
                        <Chip label={job.type} size="small" />
                      </Stack>
                      <Typography variant="subtitle2" gutterBottom>
                        Experience: {job.experience}
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom>
                        Education: {job.education}
                      </Typography>

                      <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle1" gutterBottom>
                          Responsibilities:
                        </Typography>
                        <ul>
                          {job.responsibilities.map((resp, index) => (
                            <li key={index}>{resp}</li>
                          ))}
                        </ul>
                      </Box>

                      <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle1" gutterBottom>
                          Requirements:
                        </Typography>
                        <ul>
                          {job.requirements.map((req, index) => (
                            <li key={index}>{req}</li>
                          ))}
                        </ul>
                      </Box>

                      <Box sx={{ mt: 2, textAlign: 'right' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleApply(job)}
                        >
                          Apply Now
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        {/* Application Dialog */}
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
          <DialogTitle>Apply for {selectedJob?.title}</DialogTitle>
          <DialogContent>
            <Box component="form" sx={{ mt: 2 }} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="name"
                    label="Full Name"
                    value={applicationForm.name}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="email"
                    label="Email"
                    type="email"
                    value={applicationForm.email}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="phone"
                    label="Phone"
                    value={applicationForm.phone}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="experience"
                    label="Years of Experience"
                    value={applicationForm.experience}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    name="coverLetter"
                    label="Cover Letter"
                    value={applicationForm.coverLetter}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button 
              onClick={handleSubmit}
              variant="contained"
              disabled={
                !applicationForm.name ||
                !applicationForm.email ||
                !applicationForm.phone ||
                !applicationForm.experience
              }
            >
              Submit Application
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default Careers;
