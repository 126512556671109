import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  IconButton,
  Stack,
  Switch,
  FormControlLabel,
  Link as MuiLink,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CookieIcon from '@mui/icons-material/Cookie';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CookieConsent = () => {
  const [open, setOpen] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [preferences, setPreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false,
    personalization: false,
  });

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent) {
      setShowBanner(false);
      setPreferences(JSON.parse(consent));
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAcceptAll = () => {
    const allAccepted = {
      necessary: true,
      analytics: true,
      marketing: true,
      personalization: true,
    };
    localStorage.setItem('cookieConsent', JSON.stringify(allAccepted));
    setPreferences(allAccepted);
    setShowBanner(false);
    setOpen(false);
  };

  const handleSavePreferences = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(preferences));
    setShowBanner(false);
    setOpen(false);
  };

  const handlePreferenceChange = (name) => (event) => {
    setPreferences({
      ...preferences,
      [name]: event.target.checked,
    });
  };

  if (!showBanner) return null;

  return (
    <>
      {/* Main Cookie Banner */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: 'white',
          boxShadow: '0 -4px 12px rgba(0,0,0,0.1)',
          zIndex: 1300,
          p: 3,
          display: showBanner ? 'block' : 'none',
        }}
      >
        <Box
          sx={{
            maxWidth: 1200,
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 2,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <CookieIcon sx={{ fontSize: 32, color: '#2563EB' }} />
            <Typography variant="body1" sx={{ maxWidth: 600 }}>
              We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.
            </Typography>
          </Box>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              onClick={() => setOpen(true)}
              sx={{
                color: '#2563EB',
                borderColor: '#2563EB',
                '&:hover': {
                  borderColor: '#1D4ED8',
                  bgcolor: 'rgba(37,99,235,0.04)',
                },
              }}
            >
              Cookie Settings
            </Button>
            <Button
              variant="contained"
              onClick={handleAcceptAll}
              sx={{
                bgcolor: '#2563EB',
                color: 'white',
                '&:hover': {
                  bgcolor: '#1D4ED8',
                },
              }}
            >
              Accept All
            </Button>
          </Stack>
        </Box>
      </Box>

      {/* Detailed Cookie Settings Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: '#F8FAFC' }}>
          Cookie Preferences
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" paragraph>
            We use cookies and similar technologies to help personalize content, tailor and measure ads, and provide a better experience.
          </Typography>
          
          <Box sx={{ mt: 3 }}>
            <FormControlLabel
              control={
                <Switch checked={preferences.necessary} disabled />
              }
              label={
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Necessary Cookies
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    These cookies are essential for the website to function properly.
                  </Typography>
                </Box>
              }
              sx={{ mb: 2, display: 'flex', alignItems: 'flex-start' }}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={preferences.analytics}
                  onChange={handlePreferenceChange('analytics')}
                />
              }
              label={
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Analytics Cookies
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Help us understand how visitors interact with our website.
                  </Typography>
                </Box>
              }
              sx={{ mb: 2, display: 'flex', alignItems: 'flex-start' }}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={preferences.marketing}
                  onChange={handlePreferenceChange('marketing')}
                />
              }
              label={
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Marketing Cookies
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Used to deliver relevant ads and marketing campaigns.
                  </Typography>
                </Box>
              }
              sx={{ mb: 2, display: 'flex', alignItems: 'flex-start' }}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={preferences.personalization}
                  onChange={handlePreferenceChange('personalization')}
                />
              }
              label={
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Personalization Cookies
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Help provide a personalized experience on our website.
                  </Typography>
                </Box>
              }
              sx={{ mb: 2, display: 'flex', alignItems: 'flex-start' }}
            />
          </Box>

          <Typography variant="body2" color="text.secondary" sx={{ mt: 3 }}>
            For more information about how we use cookies, please read our{' '}
            <MuiLink
              href="/privacy-policy"
              sx={{
                color: '#2563EB',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Privacy Policy
            </MuiLink>
            .
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 3, bgcolor: '#F8FAFC' }}>
          <Button
            onClick={handleClose}
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSavePreferences}
            variant="contained"
            sx={{
              bgcolor: '#2563EB',
              '&:hover': { bgcolor: '#1D4ED8' },
            }}
          >
            Save Preferences
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CookieConsent;
