import React, { useState, useEffect } from 'react';
import { 
  Box, Container, Grid, Typography, Card, Button, 
  Switch, CircularProgress, Alert, Chip, IconButton,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  LinearProgress, useTheme, useMediaQuery, Stack
} from '@mui/material';
import { 
  Router, Sensors, NetworkCheck, Security, Power, 
  WaterDrop, Speed, Science, Opacity, Thermostat, 
  WaterOutlined, FilterAlt, Memory, Warning, Refresh,
  Settings, PhoneAndroid, CloudSync, Analytics, ArrowForward,
  DeviceHub, DataUsage, CheckCircle, Language, Cloud, Storage
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { keyframes } from '@mui/system';

const pulseAnimation = keyframes`
  0% { transform: scale(1); opacity: 0.8; }
  50% { transform: scale(1.1); opacity: 0.4; }
  100% { transform: scale(1); opacity: 0.8; }
`;

const floatAnimation = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  25% { transform: translateY(-15px) rotate(-2deg); }
  50% { transform: translateY(0px) rotate(0deg); }
  75% { transform: translateY(15px) rotate(2deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`;

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px rgba(79, 195, 247, 0.2); }
  50% { box-shadow: 0 0 30px rgba(79, 195, 247, 0.6); }
  100% { box-shadow: 0 0 5px rgba(79, 195, 247, 0.2); }
`;

const flowAnimation = keyframes`
  0% { stroke-dashoffset: 100; opacity: 0.2; }
  50% { opacity: 1; }
  100% { stroke-dashoffset: 0; opacity: 0.2; }
`;

const circuitAnimation = keyframes`
  0% { transform: translateY(0); }
  100% { transform: translateY(-100%); }
`;

const dataFlowAnimation = keyframes`
  0% { stroke-dashoffset: 1000; }
  100% { stroke-dashoffset: 0; }
`;

const pulseAnimation2 = keyframes`
  0% { transform: scale(1); opacity: 0.8; box-shadow: 0 0 0 0 rgba(79, 195, 247, 0.4); }
  70% { transform: scale(1.1); opacity: 0.6; box-shadow: 0 0 0 10px rgba(79, 195, 247, 0); }
  100% { transform: scale(1); opacity: 0.8; box-shadow: 0 0 0 0 rgba(79, 195, 247, 0); }
`;

const cloudAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

const demoLinks = [
  {
    title: 'Mobile Control',
    description: 'Control your plant from anywhere using our mobile app',
    url: 'https://demo.iot.example/mobile',
    icon: <PhoneAndroid />,
  },
  {
    title: 'Analytics',
    description: 'Live data analysis and performance metrics',
    url: 'https://demo.iot.example/analytics',
    icon: <Analytics />,
  },
  {
    title: 'Cloud Sync',
    description: 'Seamless cloud connectivity and data sync',
    url: 'https://demo.iot.example/cloud',
    icon: <CloudSync />,
  },
  {
    title: 'Network',
    description: 'Interactive map of IoT sensor deployment',
    url: 'https://demo.iot.example/network',
    icon: <NetworkCheck />,
  },
  {
    title: 'Security',
    description: 'Monitor network security and device health',
    url: 'https://demo.iot.example/security',
    icon: <Security />,
  },
  {
    title: 'Remote Control',
    description: 'Control plant systems remotely',
    url: 'https://demo.iot.example/control',
    icon: <Settings />,
  }
];

// Mock data for real-time sensor values
const generateSensorData = () => ({
  temperature: (Math.random() * (35 - 25) + 25).toFixed(1),
  pressure: (Math.random() * (10 - 5) + 5).toFixed(1),
  flow: (Math.random() * (100 - 50) + 50).toFixed(1),
  pH: (Math.random() * (8.5 - 6.5) + 6.5).toFixed(1),
  conductivity: Math.floor(Math.random() * (1200 - 800) + 800),
  turbidity: (Math.random() * (1.0 - 0.1) + 0.1).toFixed(2)
});

const generateChartData = () => {
  return Array.from({ length: 24 }, (_, i) => ({
    time: `${String(i).padStart(2, '0')}:00`,
    networkLoad: Math.floor(Math.random() * 50) + 50,
    deviceLatency: Math.floor(Math.random() * 20) + 10,
    packetLoss: Math.floor(Math.random() * 5) + 1,
    bandwidth: Math.floor(Math.random() * 30) + 70,
    activeDevices: Math.floor(Math.random() * 10) + 20
  }));
};

// IoT network elements
const networkElements = {
  devices: [
    { id: 'sensor1', type: 'sensor', x: 10, y: 30, size: 50, icon: Sensors, label: 'Temperature Sensor' },
    { id: 'sensor2', type: 'sensor', x: 10, y: 70, size: 50, icon: Sensors, label: 'Pressure Sensor' },
    { id: 'plc1', type: 'plc', x: 30, y: 50, size: 60, icon: Memory, label: 'PLC Controller' },
    { id: 'gateway', type: 'gateway', x: 50, y: 50, size: 70, icon: Router, label: 'IoT Gateway' },
    { id: 'cloud', type: 'cloud', x: 80, y: 50, size: 80, icon: Cloud, label: 'Cloud Server' },
    { id: 'vpn', type: 'vpn', x: 65, y: 30, size: 60, icon: Security, label: 'VPN Server' },
    { id: 'mobile', type: 'mobile', x: 90, y: 70, size: 50, icon: PhoneAndroid, label: 'Mobile Control' },
  ],
  connections: [
    { from: 'sensor1', to: 'plc1', type: 'lan' },
    { from: 'sensor2', to: 'plc1', type: 'lan' },
    { from: 'plc1', to: 'gateway', type: 'lan' },
    { from: 'gateway', to: 'vpn', type: 'wan' },
    { from: 'vpn', to: 'cloud', type: 'vpn' },
    { from: 'cloud', to: 'mobile', type: 'wireless' },
  ]
};

const networkTopology = {
  nodes: [
    { id: 'cloud', type: 'cloud', x: 50, y: 20, label: 'Cloud Platform' },
    { id: 'gateway1', type: 'gateway', x: 20, y: 50, label: 'Gateway 1' },
    { id: 'gateway2', type: 'gateway', x: 80, y: 50, label: 'Gateway 2' },
    { id: 'sensor1', type: 'sensor', x: 10, y: 80, label: 'Sensor Group 1' },
    { id: 'sensor2', type: 'sensor', x: 30, y: 80, label: 'Sensor Group 2' },
    { id: 'sensor3', type: 'sensor', x: 70, y: 80, label: 'Sensor Group 3' },
    { id: 'sensor4', type: 'sensor', x: 90, y: 80, label: 'Sensor Group 4' },
  ],
  links: [
    { source: 'gateway1', target: 'cloud', value: 90 },
    { source: 'gateway2', target: 'cloud', value: 85 },
    { source: 'sensor1', target: 'gateway1', value: 95 },
    { source: 'sensor2', target: 'gateway1', value: 88 },
    { source: 'sensor3', target: 'gateway2', value: 92 },
    { source: 'sensor4', target: 'gateway2', value: 87 },
  ]
};

const heroNetwork = {
  nodes: [
    { id: 'cloud', type: 'cloud', x: 50, y: 15, size: 80, icon: CloudSync, label: 'Cloud Platform' },
    { id: 'gateway1', type: 'gateway', x: 25, y: 40, size: 60, icon: Router, label: 'Edge Gateway' },
    { id: 'gateway2', type: 'gateway', x: 75, y: 40, size: 60, icon: Router, label: 'Edge Gateway' },
    { id: 'vpn', type: 'vpn', x: 50, y: 65, size: 60, icon: Security, label: 'VPN Tunnel' },
    { id: 'device1', type: 'device', x: 15, y: 85, size: 50, icon: Sensors, label: 'IoT Sensors' },
    { id: 'device2', type: 'device', x: 35, y: 85, size: 50, icon: Memory, label: 'PLC' },
    { id: 'device3', type: 'device', x: 65, y: 85, size: 50, icon: Speed, label: 'RTU' },
    { id: 'device4', type: 'device', x: 85, y: 85, size: 50, icon: DeviceHub, label: 'Smart Devices' },
    { id: 'mobile1', type: 'mobile', x: 20, y: 25, size: 50, icon: PhoneAndroid, label: 'Mobile Control' },
    { id: 'mobile2', type: 'mobile', x: 80, y: 25, size: 50, icon: Settings, label: 'Remote Access' },
  ],
  connections: [
    { from: 'cloud', to: 'gateway1', type: 'wan' },
    { from: 'cloud', to: 'gateway2', type: 'wan' },
    { from: 'gateway1', to: 'vpn', type: 'vpn' },
    { from: 'gateway2', to: 'vpn', type: 'vpn' },
    { from: 'vpn', to: 'device1', type: 'lan' },
    { from: 'vpn', to: 'device2', type: 'lan' },
    { from: 'vpn', to: 'device3', type: 'lan' },
    { from: 'vpn', to: 'device4', type: 'lan' },
    { from: 'cloud', to: 'mobile1', type: 'wireless' },
    { from: 'cloud', to: 'mobile2', type: 'wireless' },
  ]
};

const IoTDemo = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sensorData, setSensorData] = useState(generateSensorData());
  const [systemStatus, setSystemStatus] = useState({
    pumpStatus: true,
    filterStatus: true,
    aerationStatus: true
  });
  const [alerts, setAlerts] = useState([]);
  const [chartData, setChartData] = useState(generateChartData());

  useEffect(() => {
    const interval = setInterval(() => {
      const newData = generateSensorData();
      setSensorData(newData);
      
      // Update chart data
      setChartData(prev => {
        const now = new Date();
        const newPoint = {
          time: now.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
          networkLoad: Math.floor(Math.random() * 50) + 50,
          deviceLatency: Math.floor(Math.random() * 20) + 10,
          packetLoss: Math.floor(Math.random() * 5) + 1,
          bandwidth: Math.floor(Math.random() * 30) + 70,
          activeDevices: Math.floor(Math.random() * 10) + 20
        };
        return [...prev.slice(1), newPoint];
      });
      
      const newAlerts = [];
      if (parseFloat(newData.temperature) > 32) {
        newAlerts.push({ severity: 'warning', message: 'High temperature detected' });
      }
      if (parseFloat(newData.pressure) > 9) {
        newAlerts.push({ severity: 'error', message: 'Pressure exceeding safe limits' });
      }
      if (parseFloat(newData.pH) < 6.8 || parseFloat(newData.pH) > 8.2) {
        newAlerts.push({ severity: 'warning', message: 'pH out of optimal range' });
      }
      setAlerts(newAlerts);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleSystemToggle = (system) => {
    setSystemStatus(prev => ({
      ...prev,
      [system]: !prev[system]
    }));
  };

  const StatusIndicator = ({ status }) => (
    <Box sx={{ 
      display: 'inline-flex', 
      alignItems: 'center',
      bgcolor: status ? '#4caf50' : '#f44336',
      color: 'white',
      px: 1,
      py: 0.5,
      borderRadius: 1,
      fontSize: '0.875rem'
    }}>
      {status ? <CheckCircle sx={{ fontSize: 16, mr: 0.5 }} /> : <Warning sx={{ fontSize: 16, mr: 0.5 }} />}
      {status ? 'RUNNING' : 'STOPPED'}
    </Box>
  );

  return (
    <Box sx={{ overflow: 'hidden', bgcolor: '#f8fafc' }}>
      {/* Hero Section */}
      <Box
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        sx={{
          position: 'relative',
          height: { xs: '100vh', md: '80vh' },
          background: 'linear-gradient(135deg, #0A1929 0%, #001E3C 100%)',
          color: 'white',
          overflow: 'hidden',
          pt: { xs: 8, md: 12 },
          pb: { xs: 8, md: 12 },
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'url("/circuit-pattern.svg")',
            backgroundSize: '50% auto',
            opacity: 0.05,
            animation: `${circuitAnimation} 20s linear infinite`,
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'radial-gradient(circle at 50% 50%, rgba(79, 195, 247, 0.1) 0%, rgba(2, 30, 54, 0) 70%)',
            animation: `${pulseAnimation} 4s ease-in-out infinite`,
          }
        }}
      >
        {/* Network Visualization */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.3,
          }}
        >
          {/* SVG Network Lines */}
          <svg width="100%" height="100%" style={{ position: 'absolute' }}>
            <defs>
              {['wanGradient', 'vpnGradient', 'lanGradient', 'wirelessGradient'].map((id, index) => (
                <linearGradient key={id} id={id} x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stopColor={['#4FC3F7', '#81D4FA', '#B3E5FC', '#E1F5FE'][index]} stopOpacity="0.8" />
                  <stop offset="100%" stopColor={['#4FC3F7', '#81D4FA', '#B3E5FC', '#E1F5FE'][index]} stopOpacity="0.2" />
                </linearGradient>
              ))}
            </defs>
            {networkElements.connections.map((conn, index) => {
              const from = networkElements.devices.find(n => n.id === conn.from);
              const to = networkElements.devices.find(n => n.id === conn.to);
              return (
                <g key={`conn-${index}`}>
                  <line
                    x1={`${from.x}%`}
                    y1={`${from.y}%`}
                    x2={`${to.x}%`}
                    y2={`${to.y}%`}
                    stroke={`url(#${conn.type}Gradient)`}
                    strokeWidth={conn.type === 'wireless' ? 1 : 2}
                    strokeDasharray={conn.type === 'wireless' ? '3,3' : '10,10'}
                    style={{
                      animation: `${flowAnimation} ${3 + index * 0.5}s linear infinite`,
                    }}
                  />
                </g>
              );
            })}
          </svg>

          {/* Network Nodes */}
          {networkElements.devices.map((node, index) => (
            <Box
              key={node.id}
              component={motion.div}
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ 
                delay: index * 0.1,
                duration: 0.5,
                ease: "easeOut"
              }}
              sx={{
                position: 'absolute',
                left: `${node.x}%`,
                top: `${node.y}%`,
                transform: 'translate(-50%, -50%)',
                width: node.size,
                height: node.size,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Box
                component={motion.div}
                animate={{
                  y: node.type === 'cloud' ? [-10, 10] : 0,
                  rotate: node.type === 'cloud' ? [-5, 5] : 0,
                  scale: [1, 1.1, 1],
                }}
                transition={{
                  duration: node.type === 'cloud' ? 4 : 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
                sx={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  bgcolor: 'rgba(79, 195, 247, 0.1)',
                  border: '2px solid rgba(79, 195, 247, 0.3)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backdropFilter: 'blur(4px)',
                  boxShadow: '0 0 20px rgba(79, 195, 247, 0.2)',
                  '&:hover': {
                    bgcolor: 'rgba(79, 195, 247, 0.2)',
                    border: '2px solid rgba(79, 195, 247, 0.5)',
                    boxShadow: '0 0 30px rgba(79, 195, 247, 0.4)',
                    transform: 'scale(1.1)',
                  },
                  transition: 'all 0.3s ease',
                }}
              >
                <node.icon sx={{ 
                  fontSize: node.size * 0.4,
                  color: '#4FC3F7',
                  filter: 'drop-shadow(0 0 10px rgba(79, 195, 247, 0.5))'
                }} />
              </Box>
              <Typography
                sx={{
                  color: '#B2EBF2',
                  fontSize: '0.75rem',
                  fontWeight: 500,
                  textAlign: 'center',
                  textShadow: '0 2px 4px rgba(0,0,0,0.5)',
                  opacity: 0.8,
                  display: { xs: 'none', md: 'block' }
                }}
              >
                {node.label}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* Content */}
        <Container 
          maxWidth="lg" 
          sx={{ 
            position: 'relative',
            zIndex: 1,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ 
                  delay: 0.5,
                  duration: 0.8,
                  type: "spring",
                  stiffness: 100
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: '2rem', md: '2.75rem', lg: '3.25rem' },
                    fontWeight: 800,
                    letterSpacing: '-0.5px',
                    lineHeight: 1.2,
                    mb: 2,
                    background: 'linear-gradient(45deg, #4FC3F7 30%, #81D4FA 90%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textShadow: '0 2px 10px rgba(79, 195, 247, 0.2)',
                  }}
                >
                  Secure Plant Control Anywhere with IoT
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: { xs: '1rem', md: '1.25rem' },
                    fontWeight: 400,
                    color: '#B2EBF2',
                    opacity: 0.9,
                    mb: 4,
                    maxWidth: 500,
                    lineHeight: 1.6,
                  }}
                >
                  Connect, monitor, and control your industrial processes securely from anywhere with our advanced IoT platform.
                </Typography>
                <Stack 
                  direction={{ xs: 'column', sm: 'row' }} 
                  spacing={2}
                  component={motion.div}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.8 }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    endIcon={<NetworkCheck />}
                    sx={{
                      bgcolor: '#4FC3F7',
                      px: 3,
                      py: 1.5,
                      borderRadius: 2,
                      fontWeight: 600,
                      fontSize: '1rem',
                      letterSpacing: 0.5,
                      boxShadow: '0 8px 16px rgba(79, 195, 247, 0.3)',
                      '&:hover': {
                        bgcolor: '#29B6F6',
                        transform: 'translateY(-2px)',
                        boxShadow: '0 12px 20px rgba(79, 195, 247, 0.4)',
                      },
                      transition: 'all 0.3s ease',
                    }}
                  >
                    Start Monitoring
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    endIcon={<Language />}
                    sx={{
                      color: '#4FC3F7',
                      borderColor: '#4FC3F7',
                      px: 3,
                      py: 1.5,
                      borderRadius: 2,
                      fontWeight: 600,
                      fontSize: '1rem',
                      letterSpacing: 0.5,
                      '&:hover': {
                        borderColor: '#29B6F6',
                        bgcolor: 'rgba(79, 195, 247, 0.1)',
                        transform: 'translateY(-2px)',
                      },
                      transition: 'all 0.3s ease',
                    }}
                  >
                    Live Demo
                  </Button>
                </Stack>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ bgcolor: '#f8fafc', py: 6 }}>
        <Container maxWidth="lg">
          {/* Alerts Section */}
          {alerts.length > 0 && (
            <Box sx={{ mb: 4 }}>
              {alerts.map((alert, index) => (
                <Alert 
                  severity={alert.severity} 
                  sx={{ 
                    mb: 1,
                    boxShadow: 1,
                    '& .MuiAlert-icon': { fontSize: 24 }
                  }} 
                  key={index}
                >
                  {alert.message}
                </Alert>
              ))}
            </Box>
          )}

          {/* Control Panel */}
          <Typography variant="h4" gutterBottom sx={{ mb: 3, fontWeight: 600 }}>
            Control Panel
          </Typography>
          <Grid container spacing={3} sx={{ mb: 6 }}>
            <Grid item xs={12} md={4}>
              <Card sx={{ 
                p: 3,
                boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
                borderRadius: 2,
                transition: 'all 0.3s ease',
                background: 'linear-gradient(135deg, #ffffff 0%, #f8fafc 100%)',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0 12px 48px rgba(0,0,0,0.15)'
                }
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6">Main Pump</Typography>
                  <Switch
                    checked={systemStatus.pumpStatus}
                    onChange={() => handleSystemToggle('pumpStatus')}
                    color="primary"
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <StatusIndicator status={systemStatus.pumpStatus} />
                </Box>
                <LinearProgress 
                  variant="determinate" 
                  value={systemStatus.pumpStatus ? 75 : 0} 
                  sx={{ height: 8, borderRadius: 4 }}
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ 
                p: 3,
                boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
                borderRadius: 2,
                transition: 'all 0.3s ease',
                background: 'linear-gradient(135deg, #ffffff 0%, #f8fafc 100%)',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0 12px 48px rgba(0,0,0,0.15)'
                }
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6">Filtration</Typography>
                  <Switch
                    checked={systemStatus.filterStatus}
                    onChange={() => handleSystemToggle('filterStatus')}
                    color="primary"
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <StatusIndicator status={systemStatus.filterStatus} />
                </Box>
                <LinearProgress 
                  variant="determinate" 
                  value={systemStatus.filterStatus ? 75 : 0} 
                  sx={{ height: 8, borderRadius: 4 }}
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ 
                p: 3,
                boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
                borderRadius: 2,
                transition: 'all 0.3s ease',
                background: 'linear-gradient(135deg, #ffffff 0%, #f8fafc 100%)',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0 12px 48px rgba(0,0,0,0.15)'
                }
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6">Aeration</Typography>
                  <Switch
                    checked={systemStatus.aerationStatus}
                    onChange={() => handleSystemToggle('aerationStatus')}
                    color="primary"
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <StatusIndicator status={systemStatus.aerationStatus} />
                </Box>
                <LinearProgress 
                  variant="determinate" 
                  value={systemStatus.aerationStatus ? 75 : 0} 
                  sx={{ height: 8, borderRadius: 4 }}
                />
              </Card>
            </Grid>
          </Grid>

          {/* Sensor Readings */}
          <Typography variant="h4" gutterBottom sx={{ 
            mb: 4, 
            fontWeight: 700,
            fontSize: { xs: '1.8rem', md: '2.2rem' },
            background: 'linear-gradient(45deg, #1a237e 30%, #0d47a1 90%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textAlign: 'center',
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-10px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '60px',
              height: '4px',
              background: 'linear-gradient(45deg, #1a237e 30%, #0d47a1 90%)',
              borderRadius: '2px',
            }
          }}>
            Real-time Sensor Data
          </Typography>
          <Card sx={{ 
            mb: 6, 
            boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
            borderRadius: 2,
            overflow: 'hidden',
            background: 'linear-gradient(135deg, #ffffff 0%, #f8fafc 100%)'
          }}>
            <TableContainer>
              <Table>
                <TableHead sx={{ 
                  background: 'linear-gradient(45deg, #1a237e 30%, #0d47a1 90%)',
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '1px',
                    background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.3), transparent)',
                  }
                }}>
                  <TableRow>
                    <TableCell sx={{ color: 'white', fontWeight: 600 }}>Parameter</TableCell>
                    <TableCell align="right" sx={{ color: 'white', fontWeight: 600 }}>Value</TableCell>
                    <TableCell align="right" sx={{ color: 'white', fontWeight: 600 }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Temperature</TableCell>
                    <TableCell align="right">{sensorData.temperature}°C</TableCell>
                    <TableCell align="right">
                      <Chip 
                        label={parseFloat(sensorData.temperature) > 32 ? 'Warning' : 'Normal'}
                        color={parseFloat(sensorData.temperature) > 32 ? 'warning' : 'success'}
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Pressure</TableCell>
                    <TableCell align="right">{sensorData.pressure} bar</TableCell>
                    <TableCell align="right">
                      <Chip 
                        label={parseFloat(sensorData.pressure) > 9 ? 'Critical' : 'Normal'}
                        color={parseFloat(sensorData.pressure) > 9 ? 'error' : 'success'}
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Flow Rate</TableCell>
                    <TableCell align="right">{sensorData.flow} m³/h</TableCell>
                    <TableCell align="right">
                      <Chip label="Normal" color="success" size="small" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>pH</TableCell>
                    <TableCell align="right">{sensorData.pH}</TableCell>
                    <TableCell align="right">
                      <Chip 
                        label={sensorData.pH < 6.8 || sensorData.pH > 8.2 ? 'Warning' : 'Normal'}
                        color={sensorData.pH < 6.8 || sensorData.pH > 8.2 ? 'warning' : 'success'}
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Conductivity</TableCell>
                    <TableCell align="right">{sensorData.conductivity} µS/cm</TableCell>
                    <TableCell align="right">
                      <Chip label="Normal" color="success" size="small" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Turbidity</TableCell>
                    <TableCell align="right">{sensorData.turbidity} NTU</TableCell>
                    <TableCell align="right">
                      <Chip label="Normal" color="success" size="small" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>

          {/* Features Grid */}
          <Typography variant="h4" gutterBottom sx={{ mb: 3, fontWeight: 600 }}>
            Available Features
          </Typography>
          <Grid container spacing={3}>
            {demoLinks.map((demo, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Card sx={{ 
                    p: 4, 
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 10px 40px rgba(0,0,0,0.1)',
                    borderRadius: 3,
                    transition: 'all 0.4s ease',
                    background: 'linear-gradient(135deg, #ffffff 0%, #f8fafc 100%)',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      transform: 'translateY(-8px) scale(1.02)',
                      boxShadow: '0 20px 60px rgba(0,0,0,0.15)',
                      '& .icon-wrapper': {
                        transform: 'scale(1.1)',
                      },
                      '&::after': {
                        transform: 'rotate(45deg) translate(100%, -100%)',
                      }
                    },
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '200%',
                      height: '200%',
                      background: 'linear-gradient(45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%)',
                      transform: 'rotate(45deg) translate(-100%, 100%)',
                      transition: 'transform 0.6s ease',
                    }
                  }}>
                    <Box 
                      className="icon-wrapper"
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        mb: 3,
                        transition: 'transform 0.4s ease',
                      }}
                    >
                      {React.cloneElement(demo.icon, { 
                        sx: { 
                          fontSize: 48, 
                          color: 'primary.main',
                          mr: 2,
                          filter: 'drop-shadow(0 2px 5px rgba(0,0,0,0.2))'
                        } 
                      })}
                      <Typography 
                        variant="h5" 
                        sx={{ 
                          fontWeight: 700,
                          background: 'linear-gradient(45deg, #1a237e 30%, #0d47a1 90%)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                        }}
                      >
                        {demo.title}
                      </Typography>
                    </Box>
                    <Typography 
                      color="text.secondary" 
                      sx={{ 
                        mb: 4, 
                        flex: 1, 
                        lineHeight: 1.8,
                        fontSize: '1rem',
                      }}
                    >
                      {demo.description}
                    </Typography>
                    <Button 
                      variant="contained" 
                      href={demo.url}
                      target="_blank"
                      endIcon={<ArrowForward />}
                      sx={{ 
                        bgcolor: 'primary.main',
                        px: 4,
                        py: 1.5,
                        borderRadius: 3,
                        fontWeight: 600,
                        letterSpacing: '0.5px',
                        boxShadow: '0 8px 16px rgba(26, 35, 126, 0.2)',
                        animation: `${glowAnimation} 2s ease-in-out infinite`,
                        '&:hover': {
                          bgcolor: 'primary.dark',
                          transform: 'translateY(-2px)',
                          boxShadow: '0 12px 20px rgba(26, 35, 126, 0.3)',
                        },
                        transition: 'all 0.3s ease'
                      }}
                    >
                      Open Dashboard
                    </Button>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box sx={{ bgcolor: '#f8fafc', py: 6 }}>
        <Container maxWidth="lg">
          {/* Network Topology Visualization */}
          <Box 
            sx={{ 
              mt: 4,
              p: 4,
              height: 300,
              bgcolor: 'rgba(2, 30, 54, 0.95)',
              borderRadius: 4,
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(79, 195, 247, 0.2)',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Typography variant="h6" sx={{ 
              mb: 3, 
              color: '#4FC3F7',
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}>
              <DeviceHub sx={{ fontSize: 24 }} />
              Network Topology
            </Typography>
            <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
              <svg width="100%" height="100%" style={{ position: 'absolute' }}>
                {networkTopology.links.map((link, index) => {
                  const source = networkTopology.nodes.find(n => n.id === link.source);
                  const target = networkTopology.nodes.find(n => n.id === link.target);
                  return (
                    <g key={`link-${index}`}>
                      <line
                        x1={`${source.x}%`}
                        y1={`${source.y}%`}
                        x2={`${target.x}%`}
                        y2={`${target.y}%`}
                        stroke="url(#linkGradient)"
                        strokeWidth={2}
                        strokeDasharray="4,4"
                        style={{
                          animation: `${dataFlowAnimation} ${2 + index}s linear infinite`,
                        }}
                      />
                      <text
                        x={`${(source.x + target.x) / 2}%`}
                        y={`${(source.y + target.y) / 2 - 2}%`}
                        fill="#4FC3F7"
                        fontSize="12"
                        textAnchor="middle"
                      >
                        {`${link.value}%`}
                      </text>
                    </g>
                  );
                })}
              </svg>
              {networkTopology.nodes.map((node, index) => (
                <Box
                  key={node.id}
                  sx={{
                    position: 'absolute',
                    left: `${node.x}%`,
                    top: `${node.y}%`,
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Box
                    component={motion.div}
                    sx={{
                      width: node.type === 'cloud' ? 60 : 40,
                      height: node.type === 'cloud' ? 60 : 40,
                      borderRadius: '50%',
                      bgcolor: 'rgba(79, 195, 247, 0.2)',
                      border: '2px solid rgba(79, 195, 247, 0.4)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backdropFilter: 'blur(4px)',
                    }}
                    animate={{
                      scale: [1, 1.1, 1],
                      opacity: [0.8, 1, 0.8],
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                  >
                    {node.type === 'cloud' && <CloudSync sx={{ color: '#4FC3F7', fontSize: 30 }} />}
                    {node.type === 'gateway' && <Router sx={{ color: '#4FC3F7', fontSize: 24 }} />}
                    {node.type === 'sensor' && <Sensors sx={{ color: '#4FC3F7', fontSize: 24 }} />}
                  </Box>
                  <Typography
                    sx={{
                      color: '#B2EBF2',
                      fontSize: '0.75rem',
                      textAlign: 'center',
                    }}
                  >
                    {node.label}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default IoTDemo;
