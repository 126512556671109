import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Snackbar,
  Alert,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
} from '@mui/material';
import {
  Phone,
  Email,
  LocationOn,
  AccessTime,
  Facebook,
  Twitter,
  LinkedIn,
  Instagram,
  Send,
} from '@mui/icons-material';

const Contact = () => {
  const theme = useTheme();
  const [map, setMap] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    message: '',
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    const initMap = () => {
      try {
        const wttLocation = { lat: 11.1132169, lng: 77.3290232 }; // Updated coordinates
        const mapOptions = {
          center: { lat: wttLocation.lat + 0.002, lng: wttLocation.lng - 0.002 },
          zoom: 19,
          mapTypeId: 'hybrid',
          mapTypeControl: false,
          fullscreenControl: true,
          streetViewControl: true,
          zoomControl: true,
          tilt: 45,
          heading: 0,
          styles: [
            {
              featureType: 'all',
              elementType: 'geometry',
              stylers: [{ saturation: 30 }]
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [{ saturation: 50 }, { lightness: 20 }]
            }
          ]
        };

        const mapInstance = new window.google.maps.Map(
          document.getElementById('map'),
          mapOptions
        );

        // Pulse animation for marker
        const pulseMarker = {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillColor: '#1976d2',
          fillOpacity: 0.9,
          scale: 12,
          strokeColor: '#ffffff',
          strokeWeight: 3
        };

        const marker = new window.google.maps.Marker({
          position: wttLocation,
          map: mapInstance,
          icon: pulseMarker,
          title: 'WTT International Pvt Ltd'
        });

        // Custom info window with company photo
        const infoWindow = new window.google.maps.InfoWindow({
          content: `
            <div style="padding: 15px; max-width: 400px; font-family: Arial, sans-serif;">
              <div style="position: relative; width: 100%; height: 250px; margin-bottom: 15px; overflow: hidden; border-radius: 8px; background-color: #f5f5f5; display: flex; align-items: center; justify-content: center;">
                <img src="https://lh5.googleusercontent.com/p/AF1QipMqdpn42miVTyiwwTQAnKx46vCHKDMHaC3KJXQ=w2806-h2059-k-no" 
                     style="width: 100%; height: 100%; object-fit: cover;" 
                     alt="WTT International Building" />
              </div>
              <h3 style="margin: 0 0 10px 0; color: #1976d2; font-size: 18px; font-weight: 600;">WTT International Pvt Ltd</h3>
              <p style="margin: 0 0 8px 0; line-height: 1.6; color: #333;">
                <strong style="color: #555;">Address:</strong> 4/58, Avinashi Main Road, Thekkalur, Tiruppur, Tamil Nadu 641654
              </p>
              <p style="margin: 0; line-height: 1.6; color: #333;">
                <strong style="color: #555;">Phone:</strong> <a href="tel:+914212240900" style="color: #1976d2; text-decoration: none;">+91 421 2240900</a>
              </p>
            </div>
          `,
          maxWidth: 400,
          pixelOffset: new window.google.maps.Size(0, -5)
        });

        // Create a backup image URL
        const img = new Image();
        img.onload = () => {
          marker.setIcon({
            ...pulseMarker,
            scale: 12
          });
        };
        img.onerror = () => {
          console.log('Company image failed to load');
        };
        img.src = '/images/wtt-building.jpg';

        // Complex animation sequence
        const animateMap = async () => {
          // Start with a wide view
          mapInstance.setZoom(14);
          mapInstance.setMapTypeId('roadmap');
          mapInstance.setCenter({ lat: wttLocation.lat + 0.01, lng: wttLocation.lng - 0.01 });
          
          await new Promise(resolve => setTimeout(resolve, 1000));
          
          // Smooth fly to location
          const flyTo = (fromLat, fromLng, toLat, toLng, fromZoom, toZoom) => {
            return new Promise(resolve => {
              const steps = 100;
              let currentStep = 0;
              
              const animate = () => {
                currentStep++;
                
                const progress = currentStep / steps;
                const easedProgress = 1 - Math.pow(1 - progress, 3); // Cubic easing
                
                const lat = fromLat + (toLat - fromLat) * easedProgress;
                const lng = fromLng + (toLng - fromLng) * easedProgress;
                const zoom = fromZoom + (toZoom - fromZoom) * easedProgress;
                
                mapInstance.setCenter({ lat, lng });
                mapInstance.setZoom(zoom);
                
                if (currentStep < steps) {
                  requestAnimationFrame(animate);
                } else {
                  resolve();
                }
              };
              
              animate();
            });
          };
          
          // Fly to location
          await flyTo(
            wttLocation.lat + 0.01,
            wttLocation.lng - 0.01,
            wttLocation.lat,
            wttLocation.lng,
            14,
            16
          );
          
          // Switch to satellite view with tilt
          await new Promise(resolve => setTimeout(resolve, 500));
          mapInstance.setMapTypeId('hybrid');
          
          // Rotate and tilt
          let currentHeading = 0;
          let currentTilt = 0;
          
          const rotateAndTilt = () => {
            return new Promise(resolve => {
              const animate = () => {
                if (currentHeading < 360) {
                  currentHeading += 2;
                  currentTilt = Math.sin(currentHeading * Math.PI / 180) * 45;
                  
                  mapInstance.setHeading(currentHeading);
                  mapInstance.setTilt(Math.max(0, currentTilt));
                  
                  requestAnimationFrame(animate);
                } else {
                  resolve();
                }
              };
              
              animate();
            });
          };
          
          await rotateAndTilt();
          
          // Final zoom in with bounce effect
          const bounceZoom = () => {
            return new Promise(resolve => {
              let progress = 0;
              const animate = () => {
                progress += 0.02;
                
                if (progress <= 1) {
                  const bounce = Math.sin(progress * Math.PI * 2) * Math.exp(-progress * 3);
                  const zoom = 16 + bounce * 1.5;
                  
                  mapInstance.setZoom(zoom);
                  requestAnimationFrame(animate);
                } else {
                  mapInstance.setZoom(17);
                  resolve();
                }
              };
              
              animate();
            });
          };
          
          await bounceZoom();
          
          // Reset to road view and show info
          mapInstance.setMapTypeId('roadmap');
          mapInstance.setHeading(0);
          mapInstance.setTilt(0);
          
          // Bounce marker and show info
          marker.setAnimation(window.google.maps.Animation.BOUNCE);
          await new Promise(resolve => setTimeout(resolve, 1500));
          marker.setAnimation(null);
          infoWindow.open(mapInstance, marker);
          
          // Start subtle pulse animation for marker
          let scale = 12;
          let growing = true;
          
          setInterval(() => {
            if (growing) {
              scale += 0.1;
              if (scale >= 14) growing = false;
            } else {
              scale -= 0.1;
              if (scale <= 12) growing = true;
            }
            
            marker.setIcon({
              ...pulseMarker,
              scale: scale
            });
          }, 50);
        };

        // Start animation sequence
        animateMap().catch(console.error);

        setMap(mapInstance);

        // Click handler for marker
        marker.addListener('click', () => {
          infoWindow.open(mapInstance, marker);
        });

      } catch (error) {
        console.error('Error initializing map:', error);
      }
    };

    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCR0-OgI7FUf9UjNjTA1gjTXu2gcJg5gXE&callback=initMap`;
      script.async = true;
      script.defer = true;
      script.onerror = () => console.error('Error loading Google Maps API');
      window.initMap = initMap;
      document.head.appendChild(script);

      return () => {
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
        delete window.initMap;
      };
    } else {
      initMap();
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setSnackbar({
      open: true,
      message: 'Message sent successfully! We will contact you soon.',
      severity: 'success',
    });
    setFormData({
      name: '',
      email: '',
      phone: '',
      company: '',
      message: '',
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const contactInfo = [
    {
      icon: <LocationOn />,
      primary: 'Our Office',
      secondary: 'WTT International Pvt Ltd, 123/45, Tiruppur, Tamil Nadu, India - 641603',
    },
    {
      icon: <Phone />,
      primary: 'Phone Number',
      secondary: '+91 421 2240900',
    },
    {
      icon: <Email />,
      primary: 'Email Address',
      secondary: 'info@wttint.com',
    },
    {
      icon: <AccessTime />,
      primary: 'Working Hours',
      secondary: 'Monday - Saturday: 9:00 AM - 6:00 PM',
    },
  ];

  const socialLinks = [
    { icon: <Facebook />, link: 'https://facebook.com' },
    { icon: <Twitter />, link: 'https://twitter.com' },
    { icon: <LinkedIn />, link: 'https://linkedin.com' },
    { icon: <Instagram />, link: 'https://instagram.com' },
  ];

  return (
    <Box sx={{ bgcolor: '#f8f9fa', minHeight: '100vh', pt: 8, pb: 12 }}>
      <Container maxWidth="lg">
        {/* Header */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography
            variant="h2"
            component="h1"
            sx={{
              fontWeight: 700,
              color: theme.palette.primary.main,
              mb: 2,
            }}
          >
            Contact Us
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.text.secondary,
              maxWidth: 600,
              mx: 'auto',
              lineHeight: 1.6,
            }}
          >
            Get in touch with us for any questions about our water treatment solutions
          </Typography>
        </Box>

        {/* Main Content */}
        <Grid container spacing={4}>
          {/* Contact Form */}
          <Grid item xs={12} md={8}>
            <Paper
              elevation={0}
              sx={{
                p: 4,
                borderRadius: 2,
                bgcolor: 'white',
                border: '1px solid',
                borderColor: 'divider',
              }}
            >
              <Typography variant="h5" gutterBottom sx={{ mb: 4, fontWeight: 600 }}>
                Send us a Message
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Full Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 1,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Email Address"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 1,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 1,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Company Name"
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 1,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Your Message"
                      name="message"
                      multiline
                      rows={4}
                      value={formData.message}
                      onChange={handleChange}
                      required
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 1,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      endIcon={<Send />}
                      sx={{
                        py: 1.5,
                        px: 4,
                        borderRadius: 1,
                        textTransform: 'none',
                        fontSize: '1rem',
                      }}
                    >
                      Send Message
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12} md={4}>
            <Card
              elevation={0}
              sx={{
                height: '100%',
                borderRadius: 2,
                bgcolor: 'white',
                border: '1px solid',
                borderColor: 'divider',
              }}
            >
              <CardContent sx={{ p: 4 }}>
                <Typography variant="h5" gutterBottom sx={{ mb: 4, fontWeight: 600 }}>
                  Contact Information
                </Typography>
                <List sx={{ mb: 4 }}>
                  {contactInfo.map((item, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        px: 0,
                        py: 2,
                        '&:not(:last-child)': {
                          borderBottom: '1px solid',
                          borderColor: 'divider',
                        },
                      }}
                    >
                      <ListItemIcon sx={{ color: theme.palette.primary.main, minWidth: 40 }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 0.5 }}>
                            {item.primary}
                          </Typography>
                        }
                        secondary={item.secondary}
                      />
                    </ListItem>
                  ))}
                </List>

                <Box>
                  <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                    Follow Us
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    {socialLinks.map((social, index) => (
                      <IconButton
                        key={index}
                        href={social.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: theme.palette.primary.main,
                          '&:hover': {
                            bgcolor: theme.palette.primary.main,
                            color: 'white',
                          },
                        }}
                      >
                        {social.icon}
                      </IconButton>
                    ))}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Map */}
        <Box sx={{ mt: 6 }}>
          <Paper
            elevation={0}
            sx={{
              borderRadius: 2,
              overflow: 'hidden',
              border: '1px solid',
              borderColor: 'divider',
              position: 'relative',
            }}
          >
            <Box
              id="map"
              sx={{
                height: 600,
                width: '100%',
              }}
            />
          </Paper>
        </Box>
      </Container>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Contact;
