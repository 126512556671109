import React, { createContext, useState, useContext, useEffect } from 'react';

const CareerContext = createContext();

export const useCareer = () => useContext(CareerContext);

export const CareerProvider = ({ children }) => {
  const [jobs, setJobs] = useState([]);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Load initial data from localStorage
  useEffect(() => {
    try {
      const savedJobs = localStorage.getItem('jobs');
      const savedApplications = localStorage.getItem('applications');

      if (savedJobs) {
        setJobs(JSON.parse(savedJobs));
      } else {
        // Set default sample job if no jobs exist
        const defaultJob = {
          id: 1,
          title: 'Water Treatment Engineer',
          department: 'Engineering',
          location: 'Tiruppur, Tamil Nadu',
          type: 'Full-time',
          experience: '3-5 years',
          education: 'B.E/B.Tech in Chemical/Environmental Engineering',
          status: 'Active',
          postedDate: new Date().toISOString().split('T')[0],
          responsibilities: [
            'Design and implement water treatment solutions',
            'Conduct water quality assessments and analysis',
            'Optimize treatment processes for efficiency',
            'Provide technical support to clients',
            'Prepare technical documentation and reports'
          ],
          requirements: [
            'Experience in industrial water treatment',
            'Knowledge of water chemistry and treatment processes',
            'Strong analytical and problem-solving skills',
            'Excellent communication abilities',
            'Project management experience'
          ]
        };
        setJobs([defaultJob]);
        localStorage.setItem('jobs', JSON.stringify([defaultJob]));
      }

      if (savedApplications) {
        setApplications(JSON.parse(savedApplications));
      } else {
        localStorage.setItem('applications', JSON.stringify([]));
      }

      setError(null);
    } catch (err) {
      console.error('Error loading data:', err);
      setError('Failed to load data');
    } finally {
      setLoading(false);
    }
  }, []);

  // Save jobs to localStorage whenever they change
  useEffect(() => {
    try {
      if (jobs.length > 0) {
        localStorage.setItem('jobs', JSON.stringify(jobs));
      }
    } catch (err) {
      console.error('Error saving jobs:', err);
      setError('Failed to save jobs');
    }
  }, [jobs]);

  // Save applications to localStorage whenever they change
  useEffect(() => {
    try {
      if (applications.length > 0) {
        localStorage.setItem('applications', JSON.stringify(applications));
      }
    } catch (err) {
      console.error('Error saving applications:', err);
      setError('Failed to save applications');
    }
  }, [applications]);

  const addJob = async (newJob) => {
    try {
      const job = {
        id: Date.now(),
        ...newJob,
        status: 'Active',
        postedDate: new Date().toISOString().split('T')[0]
      };
      const updatedJobs = [...jobs, job];
      setJobs(updatedJobs);
      localStorage.setItem('jobs', JSON.stringify(updatedJobs));
      return job.id;
    } catch (err) {
      console.error('Error in addJob:', err);
      throw new Error('Failed to add job');
    }
  };

  const updateJob = async (updatedJob) => {
    try {
      const updatedJobs = jobs.map(job =>
        job.id === updatedJob.id ? { ...job, ...updatedJob } : job
      );
      setJobs(updatedJobs);
      localStorage.setItem('jobs', JSON.stringify(updatedJobs));
    } catch (err) {
      console.error('Error in updateJob:', err);
      throw new Error('Failed to update job');
    }
  };

  const deleteJob = async (jobId) => {
    try {
      const updatedJobs = jobs.map(job =>
        job.id === jobId ? { ...job, status: 'Inactive' } : job
      );
      setJobs(updatedJobs);
      localStorage.setItem('jobs', JSON.stringify(updatedJobs));
    } catch (err) {
      console.error('Error in deleteJob:', err);
      throw new Error('Failed to delete job');
    }
  };

  const addApplication = async (newApplication) => {
    try {
      const application = {
        id: Date.now(),
        ...newApplication,
        status: 'New',
        appliedDate: new Date().toISOString().split('T')[0]
      };
      const updatedApplications = [...applications, application];
      setApplications(updatedApplications);
      localStorage.setItem('applications', JSON.stringify(updatedApplications));
      return application.id;
    } catch (err) {
      console.error('Error in addApplication:', err);
      throw new Error('Failed to add application');
    }
  };

  const updateApplicationStatus = async (applicationId, newStatus) => {
    try {
      const updatedApplications = applications.map(app =>
        app.id === applicationId ? { ...app, status: newStatus } : app
      );
      setApplications(updatedApplications);
      localStorage.setItem('applications', JSON.stringify(updatedApplications));
    } catch (err) {
      console.error('Error in updateApplicationStatus:', err);
      throw new Error('Failed to update application status');
    }
  };

  return (
    <CareerContext.Provider value={{
      jobs,
      applications,
      loading,
      error,
      addJob,
      updateJob,
      deleteJob,
      addApplication,
      updateApplicationStatus
    }}>
      {children}
    </CareerContext.Provider>
  );
};
