import React from 'react';
import { Box, Container, Grid, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';

function Footer() {
  const navigate = useNavigate();

  const sections = {
    PRODUCTS: [
      { name: 'Water Treatment', path: '/products/water-treatment' },
      { name: 'Waste Management', path: '/products/waste-management' },
      { name: 'Air Quality', path: '/products/air-quality' },
      { name: 'Automation', path: '/products/automation' },
    ],
    COMPANY: [
      { name: 'About Us', path: '/about' },
      { name: 'Projects', path: '/projects' },
      { name: 'Careers', path: '/careers' },
      { name: 'Contact', path: '/contact' },
    ],
  };

  return (
    <Box 
      sx={{ 
        bgcolor: '#0a0a0a',
        color: 'white',
        py: 6,
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `
            radial-gradient(circle at 20% 35%, rgba(0, 255, 255, 0.05) 0%, transparent 50%),
            radial-gradient(circle at 75% 65%, rgba(0, 255, 255, 0.05) 0%, transparent 50%)
          `,
          animation: 'pulse 8s infinite alternate'
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `
            linear-gradient(90deg, transparent 0%, rgba(0, 255, 255, 0.03) 1%, transparent 2%),
            linear-gradient(180deg, transparent 0%, rgba(0, 255, 255, 0.03) 1%, transparent 2%)
          `,
          backgroundSize: '50px 50px',
          animation: 'grid 15s linear infinite'
        },
        '@keyframes pulse': {
          '0%': { opacity: 0.5 },
          '50%': { opacity: 1 },
          '100%': { opacity: 0.5 }
        },
        '@keyframes grid': {
          '0%': { backgroundPosition: '0 0' },
          '100%': { backgroundPosition: '50px 50px' }
        }
      }}
    >
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Grid container spacing={6}>
          {/* Company Info */}
          <Grid item xs={12} md={4}>
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 2,
                fontWeight: 500,
                letterSpacing: '0.5px',
                color: '#fff',
                position: 'relative',
                display: 'inline-block',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: -10,
                  left: -20,
                  right: -20,
                  bottom: -10,
                  background: 'rgba(0, 255, 255, 0.1)',
                  filter: 'blur(15px)',
                  borderRadius: '50%',
                  animation: 'titleGlow 3s infinite alternate'
                },
                '@keyframes titleGlow': {
                  '0%': { opacity: 0.3 },
                  '100%': { opacity: 0.8 }
                }
              }}
            >
              WTT International
            </Typography>
            <Typography 
              sx={{ 
                color: '#999',
                fontSize: '0.95rem',
                lineHeight: 1.6,
                maxWidth: '90%',
                position: 'relative'
              }}
            >
              Leading provider of innovative water treatment solutions. We specialize in industrial water treatment, 
              waste management, and environmental sustainability through cutting-edge technology and expertise.
            </Typography>
          </Grid>

          {/* Links */}
          <Grid item xs={12} md={4}>
            <Grid container spacing={3}>
              {Object.entries(sections).map(([title, items]) => (
                <Grid item xs={6} key={title}>
                  <Typography
                    sx={{
                      color: '#fff',
                      mb: 2,
                      fontWeight: 500,
                      letterSpacing: '0.5px',
                      fontSize: '0.9rem',
                      position: 'relative',
                      display: 'inline-block'
                    }}
                  >
                    {title}
                  </Typography>
                  <Box component="ul" sx={{ p: 0, m: 0, listStyle: 'none' }}>
                    {items.map((item) => (
                      <Box component="li" key={item.name} sx={{ mb: 1.5 }}>
                        <Link
                          component="button"
                          onClick={() => navigate(item.path)}
                          sx={{
                            color: '#999',
                            textDecoration: 'none',
                            fontSize: '0.9rem',
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            textAlign: 'left',
                            p: 0,
                            position: 'relative',
                            transition: 'all 0.3s',
                            '&:hover': {
                              color: '#fff',
                              pl: 1,
                              '&::before': {
                                width: '100%',
                                opacity: 1
                              }
                            },
                            '&::before': {
                              content: '""',
                              position: 'absolute',
                              bottom: -2,
                              left: 0,
                              width: '0%',
                              height: '1px',
                              background: 'rgba(0, 255, 255, 0.5)',
                              opacity: 0,
                              transition: 'all 0.3s'
                            }
                          }}
                        >
                          {item.name}
                        </Link>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Newsletter */}
          <Grid item xs={12} md={4}>
            <Typography
              sx={{
                color: '#fff',
                mb: 2,
                fontWeight: 500,
                letterSpacing: '0.5px',
                fontSize: '0.9rem',
                position: 'relative',
                display: 'inline-block'
              }}
            >
              NEWSLETTER
            </Typography>
            <Typography
              sx={{
                color: '#999',
                mb: 3,
                fontSize: '0.9rem',
                maxWidth: '90%'
              }}
            >
              Stay updated with our latest innovations in water treatment technology and environmental solutions.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -2,
                  left: 0,
                  right: 0,
                  height: '1px',
                  background: 'linear-gradient(90deg, transparent, rgba(0, 255, 255, 0.3), transparent)',
                  animation: 'scanline 2s linear infinite',
                },
                '@keyframes scanline': {
                  '0%': { transform: 'translateX(-100%)' },
                  '100%': { transform: 'translateX(100%)' }
                }
              }}
            >
              <Box
                component="input"
                placeholder="info@wttint.com"
                sx={{
                  flex: 1,
                  bgcolor: 'rgba(0, 255, 255, 0.03)',
                  border: '1px solid #333',
                  color: '#999',
                  p: '10px 15px',
                  fontSize: '0.9rem',
                  outline: 'none',
                  transition: 'all 0.3s',
                  '&:focus': {
                    borderColor: 'rgba(0, 255, 255, 0.3)',
                    bgcolor: 'rgba(0, 255, 255, 0.05)',
                  }
                }}
              />
              <Box
                component="button"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '45px',
                  bgcolor: 'rgba(0, 255, 255, 0.03)',
                  border: '1px solid #333',
                  color: '#999',
                  cursor: 'pointer',
                  transition: 'all 0.3s',
                  position: 'relative',
                  overflow: 'hidden',
                  '&:hover': {
                    borderColor: 'rgba(0, 255, 255, 0.3)',
                    color: '#fff',
                    '&::before': {
                      transform: 'translateY(0)'
                    }
                  },
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(0, 255, 255, 0.1)',
                    transform: 'translateY(100%)',
                    transition: 'transform 0.3s'
                  }
                }}
              >
                <EmailIcon fontSize="small" sx={{ position: 'relative', zIndex: 1 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Copyright */}
        <Box
          sx={{
            mt: 5,
            pt: 3,
            borderTop: '1px solid rgba(0, 255, 255, 0.1)',
            textAlign: 'center',
            color: '#666',
            fontSize: '0.85rem',
            position: 'relative'
          }}
        >
          Copyright {new Date().getFullYear()} WTT International. All rights reserved.
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
