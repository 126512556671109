import React from 'react';
import { Box, Container, Grid, Typography, Card, Button } from '@mui/material';
import { Timeline, TrendingUp, Build, Biotech } from '@mui/icons-material';

const analyticsSections = [
  {
    title: "Maintenance Prediction",
    description: "AI-powered predictive maintenance system",
    demoUrl: "https://demo.waterai.example/predictive-maintenance",
    icon: <Build />,
    metrics: {
      accuracy: "95%",
      prediction: "30 days ahead",
      coverage: "All equipment",
      savings: "40% maintenance cost"
    }
  },
  {
    title: "Performance Forecasting",
    description: "System performance prediction and optimization",
    demoUrl: "https://demo.waterai.example/performance-forecast",
    icon: <TrendingUp />,
    metrics: {
      accuracy: "93%",
      horizon: "7 days",
      parameters: "50+",
      updates: "Real-time"
    }
  },
  {
    title: "Process Analysis",
    description: "Deep analysis of treatment processes",
    demoUrl: "https://demo.waterai.example/process-analysis",
    icon: <Biotech />,
    metrics: {
      efficiency: "35% improved",
      optimization: "Real-time",
      monitoring: "24/7",
      insights: "Actionable"
    }
  },
  {
    title: "Trend Analysis",
    description: "Historical data analysis and pattern recognition",
    demoUrl: "https://demo.waterai.example/trend-analysis",
    icon: <Timeline />,
    metrics: {
      dataPoints: "1M+",
      patterns: "Auto-detected",
      analysis: "Real-time",
      export: "Custom reports"
    }
  }
];

const PredictiveAnalytics = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        {/* Header */}
        <Box sx={{ mb: 8, textAlign: 'center' }}>
          <TrendingUp sx={{ fontSize: 60, color: '#FF9800', mb: 2 }} />
          <Typography variant="h2" gutterBottom>
            Predictive Analytics Demo
          </Typography>
          <Typography variant="h5" color="text.secondary" sx={{ mb: 4 }}>
            Advanced analytics and prediction capabilities
          </Typography>
        </Box>

        {/* Analytics Sections */}
        <Grid container spacing={4}>
          {analyticsSections.map((section, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{ 
                p: 3,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: '0 20px 40px rgba(255, 152, 0, 0.15)'
                }
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  {React.cloneElement(section.icon, { 
                    sx: { fontSize: 40, color: '#FF9800', mr: 2 } 
                  })}
                  <Typography variant="h5">{section.title}</Typography>
                </Box>
                <Typography color="text.secondary" sx={{ mb: 3 }}>
                  {section.description}
                </Typography>

                {/* Metrics */}
                <Box sx={{ mb: 3, flex: 1 }}>
                  <Grid container spacing={2}>
                    {Object.entries(section.metrics).map(([key, value], idx) => (
                      <Grid item xs={6} key={idx}>
                        <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
                          {key}:
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                          {value}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                <Button 
                  variant="contained"
                  href={section.demoUrl}
                  target="_blank"
                  sx={{ 
                    bgcolor: '#FF9800',
                    '&:hover': {
                      bgcolor: '#F57C00'
                    }
                  }}
                >
                  Try Demo
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default PredictiveAnalytics;
